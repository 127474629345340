import * as React from "react"

function ThisYear(props) {
  return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          fill="#fff"
          width="100px"
        height="100px"
          {...props}
        >
          <path d="M11.5 4A3.514 3.514 0 008 7.5C8 9.422 9.578 11 11.5 11S15 9.422 15 7.5 13.422 4 11.5 4zm0 2c.84 0 1.5.66 1.5 1.5S12.34 9 11.5 9 10 8.34 10 7.5 10.66 6 11.5 6zM22 7c-1.645 0-3 1.355-3 3s1.355 3 3 3 3-1.355 3-3-1.355-3-3-3zm0 2c.563 0 1 .438 1 1 0 .563-.438 1-1 1-.563 0-1-.438-1-1 0-.563.438-1 1-1zM7.437 11.031c-.906-.011-1.765.598-2 1.5l-1.062 4.125a2 2 0 001.344 2.407l1.812.562c.059.273.176.52.344.75L13.406 28h4.781l1.563-2.156.156-.594-1.5-2.219-.5 1.938-2.125 2.937L9.5 19.188l1.188-5.438.437.094.781 2.656a2.01 2.01 0 001.219 1.313l2.156.812c-.242.996.336 2.016 1.313 2.344l2.625.906L23.687 28h2.47L20.5 20.187l1-4.5.625.157.781 2.656a2.01 2.01 0 001.219 1.313l3.531 1.343.688-1.875-3.532-1.312v-.032l-.75-2.656a2 2 0 00-1.468-1.375L18.78 13a2.02 2.02 0 00-2.406 1.438l-.594 2.25-1.969-.75-.75-2.626a2.029 2.029 0 00-1.5-1.406l-3.75-.844a1.737 1.737 0 00-.375-.03zm-.062 2l1.344.281-.938 4.313-1.468-.469c-.008-.004-.036.012-.032 0zm10.938 1.906l1.218.282-.937 4.344-1.375-.47zM7.405 22.032l-.5 1.938L4 28h2.469l2.281-3.156.156-.594z" />
        </svg>
    
  )
}

export default ThisYear
