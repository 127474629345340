import * as React from "react";

function MissionSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path
            d="M239.29 199.75a52.16 52.16 0 00-28.35-8.52h-.16a74.82 74.82 0 1054.14 126.44 85.82 85.82 0 00-25.63-117.92z"
            fill="none"
            stroke="#263238"
            strokeMiterlimit={10}
            strokeWidth={1.077}
          />
        </clipPath>
        <clipPath id="prefix__b">
          <path
            d="M139.84 215.4c14-13.44 33.54-19.3 52.76-16.77 2.83.37 5.83.88 9 1.57 18.76 4.13 29.64 23.64 31.14 24.77s-2.25 12-2.25 12 15 43.9 13.13 50.66-25.14 14.26-25.14 14.26l3.38 13.88-71.29 13.88s-6-27-13.89-37.89-13.93-23.15-12-45.78c1.08-13.23 7.54-23.24 15.16-30.58z"
            fill="#fff"
            stroke="#263238"
            strokeMiterlimit={10}
            strokeWidth={1.077}
          />
        </clipPath>
      </defs>
      <path
        d="M391.64 102.69c-29.36-19.51-64.85-26.82-103.06-10.29-37.77 16.34-57.15 54.93-89.81 77.83-34.74 24.35-80 27-117.68 45.84-26.62 13.34-44.58 38-48.43 67.59C25.13 341.6 57 400.21 110.92 423.48c33.63 14.51 72.5 13.69 105.93-1 25.66-11.27 47.85-29.35 73.41-41.08 44.38-20.4 96.35-19.4 138.64-45.33 44.76-27.42 49.77-76.26 38.67-121.33-10.09-40.96-37.86-86.74-75.93-112.05z"
        fill="#202b37"
      />
      <path
        d="M391.64 102.69c-29.36-19.51-64.85-26.82-103.06-10.29-37.77 16.34-57.15 54.93-89.81 77.83-34.74 24.35-80 27-117.68 45.84-26.62 13.34-44.58 38-48.43 67.59C25.13 341.6 57 400.21 110.92 423.48c33.63 14.51 72.5 13.69 105.93-1 25.66-11.27 47.85-29.35 73.41-41.08 44.38-20.4 96.35-19.4 138.64-45.33 44.76-27.42 49.77-76.26 38.67-121.33-10.09-40.96-37.86-86.74-75.93-112.05z"
        fill="#fff"
        opacity={0.7}
      />
      <path
        d="M232.19 433.08c-28.87 0-55.38-4.94-78.8-14.67-40.13-16.69-74.5-49.57-91.93-88-1.2-2.63-2.37-5.45-3.48-8.36-11.7-30.51-14.71-65.6-8.7-101.49 8.8-52.58 35.49-98.21 73.2-125.17 29-20.73 65.38-32.14 102.48-32.14a180.37 180.37 0 0133.69 3.17c31.86 6.07 58.79 22.33 75.84 45.77 3 4.19 5.93 8.47 8.73 12.62 6.21 9.19 12.07 17.88 19.69 26 12.19 13 26 24.5 36.69 33.07s21.68 18.27 31.38 32.42a134.81 134.81 0 0118.09 39.83C468.44 326.12 415.77 386 353.73 409c-29.57 11-74.67 24.05-121.53 24.05z"
        fill="#263238"
      />
      <path
        d="M216 97.52a2.94 2.94 0 11-2.94-2.94 2.94 2.94 0 012.94 2.94zM395.89 212.14a2.94 2.94 0 11-2.89-2.94 2.93 2.93 0 012.89 2.94z"
        fill="#fff"
      />
      <circle cx={81.85} cy={231.66} r={2.94} fill="#fff" />
      <circle cx={130.01} cy={135.34} r={2.94} fill="#fff" />
      <path
        d="M249.47 85.48A1.47 1.47 0 11248 84a1.46 1.46 0 011.47 1.48zM294.89 134.9a1.47 1.47 0 11-1.46-1.47 1.46 1.46 0 011.46 1.47z"
        fill="#fff"
      />
      <circle cx={94.43} cy={169.18} r={1.47} fill="#fff" />
      <path
        d="M144.33 130.56a1.47 1.47 0 11-1.47-1.46 1.47 1.47 0 011.47 1.46z"
        fill="#fff"
      />
      <circle cx={212.02} cy={81.26} r={1.47} fill="#fff" />
      <circle cx={250.04} cy={162.24} r={1.47} fill="#fff" />
      <circle cx={61.89} cy={289.97} r={1.47} fill="#fff" />
      <circle cx={86.35} cy={311.93} r={1.47} fill="#fff" />
      <circle cx={76.64} cy={320.88} r={1.47} fill="#fff" />
      <circle cx={176.44} cy={86.74} r={1.47} fill="#fff" />
      <circle cx={202.47} cy={157.03} r={1.47} fill="#fff" />
      <circle
        cx={223.12}
        cy={146.79}
        r={7.12}
        transform="rotate(-9.2 223.051 146.792)"
        fill="#fff"
      />
      <circle cx={71} cy={201.29} r={7.12} fill="#fff" />
      <circle cx={119.94} cy={173.43} r={4.43} fill="#fff" />
      <circle cx={287} cy={210.75} r={4.43} fill="#fff" />
      <path
        d="M293.68 96.29c-3.55-.49-3.94-.88-4.43-4.43-.48 3.55-.87 3.94-4.42 4.43 3.55.48 3.94.87 4.42 4.42.49-3.55.88-3.94 4.43-4.42z"
        fill="#fff"
      />
      <g>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
          d="M324.71 253.65h-20.9l5.69-45.71v-8.19h9.52v8.19l5.69 45.71zM361.43 253.65h-19.79l5.14-45.51v-8.39h9.51v8.19l5.14 45.71z"
        />
        <path
          d="M315.49 101.43a1.19 1.19 0 00-2.24 0l-1.58 4.57a46.59 46.59 0 00-2.58 15.3V205h10.56v-83.7a46.59 46.59 0 00-2.58-15.3z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          fill="#263238"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
          d="M307.47 204.32h13.81v6.51h-13.81z"
        />
        <path
          d="M352.82 101.43a1.18 1.18 0 00-2.23 0L349 106a46.59 46.59 0 00-2.58 15.3V205H357v-83.7a46.85 46.85 0 00-2.57-15.3z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          d="M334.19 78.06a1.19 1.19 0 00-2 0l-3.47 5.83a42.46 42.46 0 00-6 21.69v79.9h20.82v-79.9a42.46 42.46 0 00-6-21.69zM344.8 204.32h13.81v6.51H344.8z"
          fill="#263238"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          d="M326.78 128.48s-5.61 28.29-16.57 39.25a75.66 75.66 0 00-9.68 11.27 10.83 10.83 0 00-1.79 6v10.81h30.33v-70.38z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
          d="M299 191.43h27.27"
        />
        <path
          d="M339.64 128.87s5.61 28.28 16.57 39.24a75.66 75.66 0 019.68 11.25 10.86 10.86 0 011.79 6v10.81h-30.33v-70.36z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
          d="M340.03 191.43h27.26"
        />
        <path
          d="M333.86 108.39a5.53 5.53 0 00-1.15 0 5.14 5.14 0 00-4.43 4.51v.25c-1 4.9-2.24 11.26-2.24 16.82v67.43h14.6V130c0-5.56-1.24-11.92-2.25-16.82v-.25a5.14 5.14 0 00-4.53-4.54z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          d="M328 183.53h.74a3.31 3.31 0 013.31 3.31v10h0-7.37 0v-10a3.31 3.31 0 013.32-3.31z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
          d="M323.5 196.17h9.65v6.51h-9.65z"
        />
        <path
          d="M337.89 183.53h.74a3.31 3.31 0 013.31 3.31v10h0-7.37 0v-10a3.31 3.31 0 013.32-3.31z"
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
          d="M333.44 196.17h9.65v6.51h-9.65zM333.86 108.39a5.53 5.53 0 00-1.15 0 5.1 5.1 0 00-4.33 4h9.81a5.09 5.09 0 00-4.33-4z"
        />
        <path
          d="M330.34 120.47A7.65 7.65 0 00327 127v.51a7.58 7.58 0 013.29-6zM335.69 120.07a5.49 5.49 0 00-4.58 0v1a5.6 5.6 0 014.58 0zM336.46 120.47v1a7.55 7.55 0 013.29 6v-.51a7.65 7.65 0 00-3.29-6.49z"
          fill="#263238"
        />
        <path
          d="M337.88 111.47a5 5 0 00-4-3.08 5.53 5.53 0 00-1.15 0 5 5 0 00-4 3"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.539}
        />
      </g>
      <g>
        <path
          d="M447.63 256.58c-.29-1-.59-2.07-.9-3.1a32.78 32.78 0 00-20.32 18.35c-.08.18-.14.36-.22.54a12.66 12.66 0 00-2.17-.51 20.27 20.27 0 00-38.41-6.51 9.74 9.74 0 00-8.12-3.26 15.13 15.13 0 00-22.36-15.58 10.53 10.53 0 00-17.35-.68 10.47 10.47 0 00-2.08 6 10.48 10.48 0 00-3.86-1.09 10.24 10.24 0 00-2.58-5.61 10.49 10.49 0 00-16.76 1.36 15.13 15.13 0 00-22.37 15.58 9.7 9.7 0 00-8.11 3.26 20.27 20.27 0 00-38.42 6.51 13.11 13.11 0 00-2.17.51l-.21-.54a32.89 32.89 0 00-62.7 7.95 35.29 35.29 0 00-16 20.33q-1.08-.06-2.16-.06a45.39 45.39 0 00-41.27 26.49 41.71 41.71 0 00-38.19 33.55 176 176 0 0014.46 16.85h246c32.91 0 64.83-12.18 88.76-34.77 6.86-6.47 12.37-13.24 14.95-20.28a105.44 105.44 0 006.5-33.73 108.22 108.22 0 00-3.94-31.56z"
          fill="#fff"
        />
        <path
          d="M448.53 288a33.79 33.79 0 00-17.53 4.9 22 22 0 00-30.05-6.08A28.75 28.75 0 00376 272.45c-.93 0-1.86.05-2.77.14a11.89 11.89 0 00-16.18-3.53v-.75a11.91 11.91 0 00-19.27-9.37 10.6 10.6 0 00-16.9 4.53 10.61 10.61 0 00-16.6 7.5 14.23 14.23 0 00-19.5 10.53 11.8 11.8 0 00-11-1.26h-.39a18.61 18.61 0 00-4.44.54 10.45 10.45 0 00-5.12-1.34 10.6 10.6 0 00-10 7.09 10.64 10.64 0 00-9.94-1.17 34.16 34.16 0 00-38.66 18 18.89 18.89 0 00-16 10.89 34.19 34.19 0 00-52.49 39.31 34 34 0 00-19.42-4.71A34.34 34.34 0 0088.12 369 178.59 178.59 0 00154 417c23.23 9.66 49.55 14.56 78.22 14.56 46.64 0 91.55-13 121-24 51.8-19.21 97-64.35 98.4-119.48-1.03-.03-2.05-.08-3.09-.08z"
          fill="#263238"
          opacity={0.5}
        />
        <path
          d="M435.54 323.65a22 22 0 00-30.05-6.08 28.72 28.72 0 00-24.89-14.37c-.94 0-1.86.05-2.77.14a11.9 11.9 0 00-16.18-3.54v-.75a11.91 11.91 0 00-19.27-9.36 10.6 10.6 0 00-16.9 4.53 10.61 10.61 0 00-16.6 7.5 14.24 14.24 0 00-19.75 12.91 18.86 18.86 0 00-28.28 7.28 34.16 34.16 0 00-53.4 18.34 14.26 14.26 0 00-11.14 13.67 18.85 18.85 0 00-28.27 7.28 34.18 34.18 0 00-54.63 27.4v.56h-.15v3.9A174.61 174.61 0 00154 417c23.23 9.66 49.55 14.56 78.22 14.56 46.64 0 91.55-13 121-24 41.56-15.42 78.88-47.53 92.72-88.09a33.61 33.61 0 00-10.4 4.18z"
          fill="#263238"
        />
      </g>
      <g>
        <path
          d="M239.29 199.75a52.16 52.16 0 00-28.35-8.52h-.16a74.82 74.82 0 1054.14 126.44 85.82 85.82 0 00-25.63-117.92z"
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={1.077}
          opacity={0.5}
        />
        <path
          d="M232.9 199.75c-.13-.09-.26-.16-.38-.25 19.67 17.39 32.29 44.15 32.29 74.17a102 102 0 01-13 50.2 77.22 77.22 0 006.71-6.2 85.82 85.82 0 00-25.62-117.92z"
          fill="#fff"
        />
        <path
          d="M264.92 317.67a74.81 74.81 0 11-54.14-126.44h.16a52.16 52.16 0 0128.35 8.52 86.11 86.11 0 0133.1 40.6 90.48 90.48 0 105.9 32.18v-.51a85.43 85.43 0 01-13.37 45.65z"
          fill="#202b37"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={1.077}
        />
        <path
          d="M111 234.48a90.53 90.53 0 0014.2 97.83M122.26 216.24a91.06 91.06 0 00-6.48 9.28"
          fill="#202b37"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.155}
        />
        <g clipPath="url(#prefix__a)">
          <path
            d="M139.84 215.4c14-13.44 33.54-19.3 52.76-16.77 2.83.37 5.83.88 9 1.57 18.76 4.13 29.64 23.64 31.14 24.77s-2.25 12-2.25 12 15 43.9 13.13 50.66-25.14 14.26-25.14 14.26l3.38 13.88-71.29 13.88s-6-27-13.89-37.89-13.93-23.15-12-45.78c1.08-13.23 7.54-23.24 15.16-30.58z"
            fill="#fff"
          />
          <g clipPath="url(#prefix__b)">
            <path
              d="M220.4 298.57c-11.31.68-32.89-10.28-41.11-27.41a53 53 0 00-23.63-24 81.33 81.33 0 00-30.86-2.66c-.06.48-.12 1-.16 1.45-1.93 22.63 4.12 34.89 12 45.78s13.89 37.89 13.89 37.89l71.29-13.88-3.38-13.88a115.51 115.51 0 0017.44-7.36c-4.74 1.94-10.42 3.76-15.48 4.07z"
              opacity={0.5}
            />
          </g>
          <path
            d="M139.84 215.4c14-13.44 33.54-19.3 52.76-16.77 2.83.37 5.83.88 9 1.57 18.76 4.13 29.64 23.64 31.14 24.77s-2.25 12-2.25 12 15 43.9 13.13 50.66-25.14 14.26-25.14 14.26l3.38 13.88-71.29 13.88s-6-27-13.89-37.89-13.93-23.15-12-45.78c1.08-13.23 7.54-23.24 15.16-30.58z"
            fill="none"
            stroke="#263238"
            strokeMiterlimit={10}
            strokeWidth={1.077}
          />
          <path
            d="M212.81 219.71s.75.75-6 .75-18.39-1.12-22.14 2.26-9.76 13.13-7.5 13.13 5.25-3.38 7.5-3.38a26.61 26.61 0 005.25-.75s-9.38 5.63-6 6 18.39-1.87 22.51-4.5 7.13-4.5 7.51-6.75 1.5-3.75-1.13-6.76zM228.51 219.46c-.29.59-1.77 3.21-4.44 2.88a7.17 7.17 0 01-4.5-2.25 15.16 15.16 0 01.37 4.13c0 3 .38 5.25 2.25 7.5s8.26 5.25 8.26 5.25 2.25-6.75 2.25-12c-.48-.36-1.89-2.55-4.19-5.51z"
            fill="#263238"
            stroke="#263238"
            strokeMiterlimit={10}
            strokeWidth={1.077}
          />
          <path
            d="M218.44 230.6s15 7.12 15.76 9.38-3.75 10.88-3.75 10.88"
            fill="#fff"
            stroke="#263238"
            strokeMiterlimit={10}
            strokeWidth={1.077}
          />
          <path
            d="M234.2 240s-22.51 13.88-19.51 15.75 11.25-1.12 13.51-3 6.37-7.9 6-12.75zM231.11 259.9s-8.13 2.16-10.77 2.44-7.65 3.84-5.4 3.92 10.65-3.72 12.9-3.64 6 .18 6.24-.78c.48-1.84-2.97-1.94-2.97-1.94z"
            fill="#263238"
            stroke="#263238"
            strokeMiterlimit={10}
            strokeWidth={1.077}
          />
          <path
            d="M218.76 303.19c-.91.08-1.74.14-2.47.17-8.22.35-19-.27-29.46-5.48a44.31 44.31 0 01-12.48-10.17 136.11 136.11 0 0134.65-13.1 5.65 5.65 0 10-2.59-11 145.71 145.71 0 00-38.92 15c-8.24-13.24-12.89-30.9-5-47.48 10.89-22.91 33.85-28.73 42-30.1-.95-.3-1.92-.57-2.91-.79-3.13-.69-6.13-1.2-9-1.57-19.22-2.53-38.79 3.33-52.76 16.77-7.62 7.34-14.08 17.35-15.2 30.58-1.93 22.63 4.12 34.89 12 45.78s13.89 37.89 13.89 37.89l71.29-13.88z"
            fill="#263238"
          />
        </g>
        <path
          d="M239.29 199.75a52.16 52.16 0 00-28.35-8.52h-.16a74.82 74.82 0 1054.14 126.44 85.82 85.82 0 00-25.63-117.92z"
          fill="none"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={1.077}
        />
        <ellipse
          cx={126.2}
          cy={269.45}
          rx={13.36}
          ry={19.01}
          transform="rotate(-1.75 126.212 269.459)"
          fill="#263238"
        />
        <path
          d="M286 353.72s-7.71-4.63-8.22-12.33.51-10.79.51-10.79-48.81-31.35-150.55 3.59c0 0-12.33 1.54-10.79 7.2s4.11 8.22 4.11 12.84-9.76 10.77-13.88 11.82c-1.49.37-7.52 3.46-15 7.49A178.08 178.08 0 00154 417c23.23 9.66 49.55 14.56 78.22 14.56 35.33 0 69.66-7.48 97-15.85l-.6-46.6z"
          fill="#202b37"
        />
        <path
          d="M138.37 409.55c-11.92-25-27.29-47.29-41.46-47.61-1.91 0-6 1.4-11.55 3.79a180.14 180.14 0 0053.01 43.82z"
          fill="#202b37"
        />
        <path
          d="M127.74 334.19s-12.33 1.54-10.79 7.2 4.11 8.22 4.11 12.84a5.22 5.22 0 01-.9 2.66c24.45-7.37 97.91-26.73 157.84-13.89a11.93 11.93 0 01-.22-1.62c-.52-7.71.51-10.79.51-10.79s-48.81-31.34-150.55 3.6z"
          fill="#263238"
          stroke="#263238"
          strokeLinejoin="round"
          strokeWidth={1.077}
        />
        <path
          d="M116.37 350a8.22 8.22 0 01-4.82-14.89c14.24-10.29 46.17-19 83.32-22.73 36.68-3.68 69.51-1.59 85.67 5.47a8.22 8.22 0 01-6.54 15.02c-11.08-4.84-39.32-8-77.44-4.18-38.58 3.88-65.64 12.69-75.35 19.7a8.18 8.18 0 01-4.84 1.61z"
          fill="#202b37"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={1.077}
        />
        <path
          d="M179.55 326.93c3-.28 6.11-.48 9.22-.56M113.86 346.53a190.51 190.51 0 0157-18.54"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.155}
        />
        <path
          d="M117.32 360.11a30.08 30.08 0 01-9.75 5.82c10.9 7.54 21.83 24.83 30.8 43.62A167.6 167.6 0 00154 417c.32.14.65.26 1 .39-13.38-32.68-30.15-50.46-37.68-57.28zM305.16 360.86a1.55 1.55 0 00-.05-.23l-13.53-4.89A634.65 634.65 0 01306.53 422c1.72-.42 3.42-.84 5.11-1.28l-7.53-55.84a5.32 5.32 0 011.05-4.02z"
          fill="#263238"
        />
        <path
          d="M364.94 386c-6.47-4.81-24.36-16.84-53.67-27a5.43 5.43 0 00-7.16 5.84l7.53 55.84a428.24 428.24 0 0041.57-13.07q5.78-2.13 11.41-4.7l2.38-11.46a5.42 5.42 0 00-2.06-5.45z"
          fill="#202b37"
        />
        <path
          fill="#202b37"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
          d="M256.19 366.56l9.77 45.22 47.84 2.76-5.19-40.27-52.42-7.71z"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.077}
          d="M256.19 366.56l-6.16 9.25 9.25 45.73 6.68-9.76-9.77-45.22z"
        />
        <path
          d="M266 411.78l-6.7 10.29 43.12.89q3.08-.7 6.07-1.46l5.81-6.64z"
          fill="#263238"
        />
        <ellipse cx={170.88} cy={389.39} rx={12.33} ry={10.96} fill="#263238" />
        <ellipse cx={201.02} cy={385.28} rx={12.33} ry={10.96} fill="#263238" />
      </g>
    </svg>
  );
}

export default MissionSVG;
