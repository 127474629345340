import * as React from "react"

function PassedIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      fill="#fff"
      width="100px"
      height="100px"
      {...props}
    >
      <path d="M6 1c-1.277 0-2.719.36-3.906 1.25C.906 3.14.03 4.64.03 6.594.031 10.012 2.406 12 2.406 12c.04.035.082.066.125.094l4.063 2.469c-.246 4.402-5.2 4.324-6.438 4.374 0 0 2.156 1.247 4.094 1.157C3.484 21.492 1 23 1 23c2.742 0 8.855-.578 8.906-5.75l.406.375 5.938 5.469c.148.133.332.222.531.25.004.004.028-.004.032 0 .214.207.363.531.593.687 1.27.852 2.582 1 3.282 1 .68 0 1.882-.11 3.062-.812C24.93 23.516 26 22.086 26 20c0-1.543-.605-2.707-1.469-3.313C23.668 16.082 22.7 16 22 16c-.879 0-1.79.195-2.563.656-.773.461-1.437 1.317-1.437 2.375 0 1.387.824 2.364 1.594 2.719.77.355 1.468.281 1.468.281.551-.035.973-.511.938-1.062a1.004 1.004 0 00-1.063-.938s-.3.012-.53-.093c-.231-.106-.407-.141-.407-.907 0-.285.102-.437.469-.656.367-.219.972-.375 1.531-.375.488 0 1.035.105 1.375.344.34.238.625.574.625 1.656 0 1.477-.57 2.078-1.281 2.5-.711.422-1.649.531-2.032.531-.363 0-1.367-.125-2.156-.656-.789-.531-1.5-1.379-1.5-3.344 0-1.832 1.383-3.031 2.969-3.875 1.586-.844 3.188-1.187 3.188-1.187a1 1 0 00.281-1.844l-10.75-5.5c-.27-.195-.528-.414-.813-.656-.062-.051-.125-.07-.187-.125L6.656 1.25A.988.988 0 006 1zm-.281 2.094l3.906 3.5C7.16 7.93 6.562 10.348 6.469 12.156L3.687 10.47l-.03-.031C3.601 10.39 2.03 9.062 2.03 6.594c0-1.399.531-2.188 1.281-2.75.672-.504 1.579-.7 2.407-.75zm7.093 5.812l7.72 3.938c-.622.215-.727.136-1.47.531-1.894 1.008-4.03 2.828-4.03 5.656 0 .114.058.172.062.282l-5.281-4.844c-.024-3.114 1.554-4.739 3-5.563z" />
    </svg>
  )
}

export default PassedIcon
