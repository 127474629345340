import * as React from "react"

function TeacherIcon(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 80"
    fill="#fff"
    width="100px"
    height="100px"
    {...props}
  >
    <path d="M26 10c-4.406 0-8 3.594-8 8 0 4.406 3.594 8 8 8 4.406 0 8-3.594 8-8 0-4.406-3.594-8-8-8zm0 2c3.326 0 6 2.674 6 6s-2.674 6-6 6-6-2.674-6-6 2.674-6 6-6zm8.65 1c.364.628.669 1.295.891 2H68v39H38v2h32.5c.84 0 1.5.66 1.5 1.5s-.66 1.5-1.5 1.5H38v2h32.5c1.567 0 2.855-1.07 3.295-2.5H74v-1c0-1.921-1.579-3.5-3.5-3.5H70V13H34.65zM17 29c-3.854 0-7 3.146-7 7v21c0 2.197 1.803 4 4 4 .743 0 1.402-.262 2-.62V68a1 1 0 102 0V57h-2c0 1.117-.883 2-2 2s-2-.883-2-2V36c0-2.774 2.226-5 5-5h3.52c.458.777 1.908 3 4.98 3 3.072 0 4.522-2.223 4.98-3H35c.56 0 1.057.227 1.422.596l.002.002 7.574 7.576 6.588-6.588A1.98 1.98 0 0152 32c1.117 0 2 .883 2 2a1.98 1.98 0 01-.586 1.414l-8 8-.002.002A1.976 1.976 0 0144 44c-.557 0-1.05-.223-1.414-.586l-.707-.705L34 34.83V68a1 1 0 102 0V39.658l5.172 5.172.707.707.139-.139c.594.348 1.248.602 1.982.602.912 0 1.72-.362 2.396-.88h.141l.293-.294 7.998-7.998A3.995 3.995 0 0056 34c0-2.197-1.803-4-4-4a4 4 0 00-2.828 1.172L44 36.344l-6.156-6.155-.004-.003A4.002 4.002 0 0035 29h-5.61l-.279.541S27.77 32 25.5 32c-2.27 0-3.611-2.459-3.611-2.459l-.28-.541H17zm0 8a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm0 4a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm0 4a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm0 4a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm0 4a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zm7 9.1a1 1 0 00-1 1V68a1 1 0 102 0v-3.9h2V68a1 1 0 102 0v-4.9a1 1 0 00-1-1h-4z" />
  </svg>
  )
}

export default TeacherIcon
