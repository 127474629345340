import * as React from "react";

function EleSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <path
        d="M75.49 311.74s1.32-17.42-2.27-34.12-12.31-29.56-23-34.58-23.5 1.82-14.27 13.25 28.37 31.81 28.26 61.6z"
        fill="#e0e0e0"
      />
      <path
        d="M70 311.13h.13a.64.64 0 00.62-.65c-1-36.48-13.16-54.75-27.2-64a.63.63 0 00-.87.18.62.62 0 00.17.87c13.85 9.17 25.64 27.06 26.63 63a.63.63 0 00.52.6z"
        fill="#fff"
      />
      <path
        d="M21.16 279c.2 2 1.83 3.56 3.39 4.87s3.25 2.71 3.67 4.69c.86 4-3.89 7.7-2.86 11.66a6.32 6.32 0 003.34 3.73 40 40 0 003.93 1.68 18.2 18.2 0 002 .48 9.17 9.17 0 015.37 2.37c2.18 2.37 1.91 6 2.68 9.16.94 3.75 3.17 6 8.28 7.74l17.5-9.92c4.36-4.44 5.9-12 1.88-17.21-2.41-3.1-6.92-3.49-9.2-6.6-2-2.78-.86-6.8-2.11-10s-4.58-4.32-7.72-4.87-6.48.75-9.54-.37-5.91-2.93-9.09-3.72a10.56 10.56 0 00-9.16 1.54 5.59 5.59 0 00-2.36 4.77z"
        fill="#ebebeb"
      />
      <path
        d="M53.78 294.75a.49.49 0 00-.14-.18c-8.22-11.66-21.12-17.2-25.47-18.17a.46.46 0 10-.2.89c4.13.92 16.1 6 24.16 16.74a25.16 25.16 0 00-20.07 1.74.46.46 0 00-.14.63.49.49 0 00.37.21.42.42 0 00.26-.08 24.33 24.33 0 0120.5-1.23 38.21 38.21 0 016.85 23.52.45.45 0 00.4.47.46.46 0 00.47-.44 39.08 39.08 0 00-6.99-24.1z"
        fill="#fff"
      />
      <path
        d="M434.59 323.47c-.17-13 .07-25 6.67-43.46 5.53-15.51 19.75-31.15 29.35-30.49 8.95.62 10.93 11.61 2.88 21.39s-25.58 28.49-26.66 42l-3.43 15.33z"
        fill="#e0e0e0"
      />
      <path
        d="M438.56 320.19a.59.59 0 00.44-.54c1.4-30.24 18.29-55.44 32.38-63.19a.57.57 0 00-.56-1c-14.32 7.87-31.55 33.48-33 64.14a.57.57 0 00.54.6z"
        fill="#fff"
      />
      <path
        d="M446.7 330.07c.55-4.56 4.73-12.61 12.39-19.94 8.48-8.1 20.06-13.05 22-18.62 2.36-6.68-3.55-10.25-13.32-8.16-11.75 2.51-24.77 15.95-27 43.41z"
        fill="#ebebeb"
      />
      <path
        d="M444.07 325.8a.57.57 0 00.43-.45c4.49-24 20.53-34.73 28.39-37.06a.57.57 0 00.38-.71.56.56 0 00-.71-.39c-8.68 2.57-24.6 13.45-29.19 37.94a.57.57 0 00.46.67.49.49 0 00.24 0z"
        fill="#fff"
      />
      <g>
        <path
          d="M93.71 456.78c-85-49.07-85-128.64 0-177.72s222.82-49.07 307.82 0 85 128.65 0 177.72-222.82 49.08-307.82 0z"
          fill="#fafafa"
        />
      </g>
      <g fill="#e0e0e0">
        <path d="M261 420.58l23.27-13.44a.76.76 0 000-1.44l-5.7-3.29a2.75 2.75 0 00-2.5 0l-23.27 13.44a.76.76 0 000 1.44l2.85 1.64-29.22 16.87c-.69.4-.4.53-.08.72s.56.35 1.25 0l29.21-16.86 1.68 1a2.75 2.75 0 002.51-.08zM252.21 394.79a10.51 10.51 0 01-5.21-1.26l-.47-.3-.86-.61 7.33-1.07.66-1.38-2-1.16-7 1 .23-.66a4.54 4.54 0 012.15-2.3 10.48 10.48 0 015.12-1.22 12.16 12.16 0 012.35.22l23.88-13.78a2.87 2.87 0 01-.2-1.93 4.29 4.29 0 012.22-2.56 10.6 10.6 0 015.13-1.22 10.41 10.41 0 015.21 1.27 5.4 5.4 0 01.47.3l.86.6-7.33 1.07-.66 1.39 2 1.16 7-1-.23.65a4.56 4.56 0 01-2.15 2.31 10.59 10.59 0 01-5.12 1.22 12.16 12.16 0 01-2.35-.23l-23.88 13.79a2.85 2.85 0 01.2 1.92 4.27 4.27 0 01-2.22 2.56 10.49 10.49 0 01-5.13 1.22z" />
        <ellipse cx={151.58} cy={402.77} rx={76.29} ry={44.05} />
        <path d="M276.43 405.3l101.24-58.45 56.56 32.65-101.24 58.45-56.56-32.65zM250.31 371.24l-39.49-22.8c-1.43-.82-1.43-2.17 0-3l63.86-36.86a5.74 5.74 0 015.2 0l39.49 22.79c1.43.83 1.43 2.18 0 3l-63.86 36.87a5.74 5.74 0 01-5.2 0z" />
      </g>
      <g>
        <path
          fill="#263238"
          d="M252.91 367.44v-32.83l-40.62-23.46 4.06 35.18 36.56 21.11z"
        />
        <path
          fill="#37474f"
          d="M252.91 367.44l60.93-35.18 4.06-35.18-64.99 37.53v32.83z"
        />
        <path
          d="M209.75 304.12a1.83 1.83 0 011.07-1.5l63.86-36.87a5.74 5.74 0 015.2 0l39.49 22.8a1.82 1.82 0 011.07 1.5v9.38a1.82 1.82 0 01-1.07 1.51l-63.86 36.86a5.74 5.74 0 01-5.2 0L210.82 315a1.84 1.84 0 01-1.07-1.5z"
          fill="#ba68c8"
        />
        <path
          d="M210.82 302.62l42.09-24.3v60.11a5.27 5.27 0 01-2.6-.63L210.82 315a1.84 1.84 0 01-1.07-1.5v-9.39a1.83 1.83 0 011.07-1.49z"
          opacity={0.2}
        />
        <path
          d="M252.91 278.32l21.77-12.57a5.74 5.74 0 015.2 0l39.49 22.8a1.82 1.82 0 011.07 1.5v9.38a1.82 1.82 0 01-1.07 1.51l-63.86 36.86a5.27 5.27 0 01-2.6.63z"
          opacity={0.1}
        />
        <path
          d="M250.31 328.42l-39.49-22.8c-1.43-.83-1.43-2.17 0-3l63.86-36.87a5.74 5.74 0 015.2 0l39.49 22.8c1.43.83 1.43 2.17 0 3l-63.86 36.87a5.74 5.74 0 01-5.2 0z"
          fill="#ba68c8"
        />
        <path
          d="M307.75 291.22c1.12.65 1.06 1.73-.14 2.42l-44.41 25.65a4.64 4.64 0 01-4.2.07c-1.12-.64-1.06-1.73.14-2.42l44.41-25.64a4.64 4.64 0 014.2-.08z"
          opacity={0.2}
        />
        <path
          d="M263.2 319.29a4.64 4.64 0 01-4.2.07l-.22-.15a2.53 2.53 0 01.36-.25l44.41-25.64a4.64 4.64 0 014.2-.08l.22.16a2.5 2.5 0 01-.36.24zM275.25 272.46c1.13.64 1.07 1.73-.13 2.42l-44.42 25.64a4.62 4.62 0 01-4.19.08c-1.12-.65-1.06-1.73.13-2.42l44.42-25.65a4.62 4.62 0 014.19-.07z"
          opacity={0.2}
        />
        <path
          d="M230.7 300.52a4.62 4.62 0 01-4.19.08 1.25 1.25 0 01-.22-.16 3.07 3.07 0 01.35-.24l44.42-25.64a4.59 4.59 0 014.19-.08 2.1 2.1 0 01.22.15 2.41 2.41 0 01-.35.25zM234.62 303c-1.12.65-1.06 1.73.14 2.42l16 9.22a4.62 4.62 0 004.19.08c1.12-.65 1.06-1.73-.13-2.42l-16-9.22a4.64 4.64 0 00-4.2-.08z"
          opacity={0.2}
        />
        <path
          d="M250.75 314.59a4.62 4.62 0 004.19.08 2.1 2.1 0 00.22-.15 2.41 2.41 0 00-.35-.25l-16-9.22a4.64 4.64 0 00-4.2-.08 1.66 1.66 0 00-.21.16 2 2 0 00.35.24zM226.51 307.64c-1.12.64-1.06 1.73.13 2.42l16 9.22a4.64 4.64 0 004.2.08c1.12-.65 1.06-1.74-.14-2.43l-16-9.22a4.62 4.62 0 00-4.19-.07z"
          opacity={0.2}
        />
        <path
          d="M242.63 319.28a4.64 4.64 0 004.2.08c.08-.05.14-.11.21-.16a2.41 2.41 0 00-.35-.25l-16-9.22a4.62 4.62 0 00-4.19-.07 1.21 1.21 0 00-.22.15 2.41 2.41 0 00.35.25zM283.37 274.81c-1.12.65-1.07 1.73.13 2.42l16 9.22a4.62 4.62 0 004.19.08c1.13-.65 1.07-1.73-.13-2.42l-16-9.22a4.62 4.62 0 00-4.19-.08z"
          opacity={0.2}
        />
        <path
          d="M299.49 286.45a4.64 4.64 0 004.2.08 1.66 1.66 0 00.21-.16 2.38 2.38 0 00-.35-.24l-16-9.22a4.62 4.62 0 00-4.19-.08 2.1 2.1 0 00-.22.15 2.41 2.41 0 00.35.25zM275.25 279.49c-1.12.65-1.06 1.74.13 2.43l16 9.22a4.68 4.68 0 004.2.08c1.12-.65 1.06-1.74-.14-2.43l-16-9.22a4.68 4.68 0 00-4.19-.08z"
          opacity={0.2}
        />
        <path
          d="M291.37 291.14a4.68 4.68 0 004.2.08l.22-.16a2.53 2.53 0 00-.36-.25l-16-9.22a4.68 4.68 0 00-4.2-.08l-.22.16a3.12 3.12 0 00.35.25z"
          opacity={0.2}
        />
        <path
          d="M248.85 295.05v9.07l4.06 2.35 4.06-2.35v-6.88a4.8 4.8 0 012.16-3.75l10-5.79 1.9-1.09c1.19-.69 2.16-.13 2.16 1.25v6.88l4.06-2.35v-9.07a4.63 4.63 0 000-.54 2.52 2.52 0 00-.06-.41 3.09 3.09 0 00-.12-.46 3.65 3.65 0 00-.14-.35 2.59 2.59 0 00-.19-.33 2.21 2.21 0 00-.22-.29 1.88 1.88 0 00-.25-.24 1.67 1.67 0 00-.28-.18l-.13-.07-3.91-2.26a3 3 0 00-3.05.3l-15.71 9.07a9.55 9.55 0 00-4.34 7.49z"
          fill="#455a64"
        />
        <path
          d="M269.15 287.7v4.68l4.06 2.35v-6.88c0-1.38-1-1.94-2.16-1.25z"
          fill="#263238"
        />
        <path
          d="M252.91 297.39v9.08l4.06-2.35v-6.88a4.8 4.8 0 012.16-3.75l10-5.78 1.9-1.09c1.19-.69 2.16-.13 2.16 1.25v6.88l4.06-2.34v-9.08a4.63 4.63 0 000-.54 2.52 2.52 0 00-.06-.41 3.09 3.09 0 00-.12-.46 3.65 3.65 0 00-.14-.35 2.59 2.59 0 00-.19-.33 2.21 2.21 0 00-.22-.29 1.88 1.88 0 00-.25-.24 1.67 1.67 0 00-.28-.18l-.13-.07a3.12 3.12 0 00-2.9.38l-15.71 9.07a9.59 9.59 0 00-4.34 7.48z"
          fill="#37474f"
        />
        <path
          fill="#455a64"
          d="M273.22 336.95l-1.5-.86-4.07 2.34 1.51.87 4.06-2.35z"
        />
        <path fill="#263238" d="M269.16 339.3l-1.5 19.63-.01-20.5 1.51.87z" />
        <path fill="#37474f" d="M273.22 336.95l-1.5 19.63.49-.23 1.01-19.4z" />
        <path
          fill="#455a64"
          d="M239.22 340.78l1.5-.87 4.06 2.34-1.5.87-4.06-2.34z"
        />
        <path d="M239.22 340.78l1.5 19.62-.49-.23-1.01-19.39z" />
        <path
          fill="#455a64"
          d="M222.97 331.39l1.51-.86 4.06 2.34-1.51.87-4.06-2.35z"
        />
        <path
          fill="#37474f"
          d="M244.78 362.75l-1.5-19.63 1.5-.87v20.5zM228.54 332.87v20.5l-1.51-19.63 1.51-.87z"
        />
        <path d="M222.97 331.39l1.5 19.63-.49-.23-1.01-19.4z" />
        <path
          fill="#455a64"
          d="M301.65 320.54l-1.5-.87-4.06 2.34 1.5.87 4.06-2.34z"
        />
        <path fill="#263238" d="M297.59 322.88l-1.5 19.63v-20.5l1.5.87z" />
        <path
          fill="#37474f"
          d="M301.65 320.54l-1.5 19.63.5-.24 1-19.39zM265.47 333.62l.01-4.69 8.12-4.69 1.36.78-.01 4.69-8.13 4.69-1.35-.78z"
        />
        <path
          fill="#455a64"
          d="M274.96 325.02l-.01 4.69-8.13 4.69.02-4.69 8.12-4.69z"
        />
        <path
          fill="#263238"
          d="M266.84 329.71l-1.36-.78-.01 4.69 1.35.78.02-4.69z"
        />
        <path
          fill="#37474f"
          d="M294.12 317.09l.02-4.69 8.12-4.69 1.36.78-.02 4.69-8.12 4.69-1.36-.78z"
        />
        <path
          fill="#455a64"
          d="M303.62 308.49l-.02 4.69-8.12 4.69.01-4.69 8.13-4.69z"
        />
        <path
          fill="#263238"
          d="M295.49 313.18l-1.35-.78-.02 4.69 1.36.78.01-4.69z"
        />
      </g>
      <g>
        <path
          d="M247.39 386.76c-.55.31-1.58.3-1.91.67v1.35l6.28-.91 2.37 1.39-.9 1.89-6.34-.43v1.35a4.38 4.38 0 00.42.28 10.72 10.72 0 009.68 0 3.41 3.41 0 002-2.81v-1.35l-.37.06a.25.25 0 010-.07l23.76-13.72a10.7 10.7 0 007.15-.9 4 4 0 001.91-2v-1.36l-6.28 2.27-2.37-1.39.9-1.89 6.34-.92v-1.36s-7.41-.49-10.11 1a4.36 4.36 0 00-1.67 1.55l-.36-.13v1.34a2.56 2.56 0 00.41 1.41l-23.76 13.72a10.7 10.7 0 00-7.15.96z"
          fill="#37474f"
        />
        <path
          fill="#37474f"
          d="M282.38 374.5v-1.35l-23.76 13.72v1.35l23.76-13.72z"
        />
        <path
          d="M278.3 370.79l-23.76 13.72a10.75 10.75 0 00-7.15.89 4 4 0 00-1.91 2l6.28-.91 2.37 1.39-.9 1.88-6.34.93a4.38 4.38 0 00.42.28 10.72 10.72 0 009.68 0c2-1.12 2.52-2.76 1.63-4.17l23.76-13.72a10.76 10.76 0 007.15-.9 4 4 0 001.91-2l-6.28.92-2.37-1.39.9-1.89 6.34-.93-.42-.27a10.72 10.72 0 00-9.68 0c-1.93 1.12-2.52 2.76-1.63 4.17z"
          fill="#455a64"
        />
        <path
          fill="#263238"
          d="M283.29 370.04l-.5-.29.9-1.89 6.34-.93v1.36l-6.34.92-.4.83zM253.63 388.96l.5-1.05-2.37-1.4-6.28.92v1.36l6.28-.92 1.87 1.09z"
        />
        <g>
          <path
            d="M261.93 406.72l5 8.6 16.76-9.67a2.4 2.4 0 001.12-2.32 7.76 7.76 0 00-3.52-6.09 2.45 2.45 0 00-2.48-.24z"
            fill="#ba68c8"
          />
          <path
            d="M260.93 408.5c-.07 1.31-1.92 1.59-1.92 1.59l3.51 6.09s1.17-1.44 2.34-.87c1.73.75 3.08-.18 3.08-2.25a7.76 7.76 0 00-3.52-6.08c-1.8-1.05-3.28-.35-3.49 1.52z"
            fill="#ba68c8"
          />
          <path
            d="M260.93 408.5c-.07 1.31-1.92 1.59-1.92 1.59l3.51 6.09s1.17-1.44 2.34-.87c1.73.75 3.08-.18 3.08-2.25a7.76 7.76 0 00-3.52-6.08c-1.8-1.05-3.28-.35-3.49 1.52z"
            opacity={0.05}
          />
          <path
            d="M259.84 409.83a3.54 3.54 0 01-.84.26l3.51 6.09a3.92 3.92 0 01.65-.59 7.88 7.88 0 00-3.32-5.76z"
            opacity={0.05}
          />
          <path
            d="M255.34 410.52l.29.51a4 4 0 00-.15 1.09 7.78 7.78 0 003.52 6.09 3.57 3.57 0 001 .41l.29.51 1.17-.67a2.47 2.47 0 001-2.28 7.77 7.77 0 00-3.51-6.09 2.47 2.47 0 00-2.49-.24z"
            fill="#ba68c8"
          />
          <path
            d="M261.34 416.86a7.77 7.77 0 00-3.51-6.09c-1.94-1.12-3.52-.21-3.52 2a7.8 7.8 0 003.52 6.09c1.94 1.14 3.51.24 3.51-2z"
            fill="#ba68c8"
          />
          <path
            d="M261.34 416.86a7.77 7.77 0 00-3.51-6.09c-1.94-1.12-3.52-.21-3.52 2a7.8 7.8 0 003.52 6.09c1.94 1.14 3.51.24 3.51-2z"
            opacity={0.15}
          />
          <path
            d="M256.46 417.77a5.29 5.29 0 00.87.78l.82-2.34-1-.73z"
            opacity={0.15}
          />
          <path
            d="M281.91 400.92a1 1 0 01-.36 1.32l-12 6.91a1 1 0 01-1.33-.35 1 1 0 01.36-1.32l12-6.91a1 1 0 011.33.35zM279.75 398.35a.56.56 0 010 1l-12.2 7a1.33 1.33 0 01-1.41-.16.56.56 0 010-1l12.19-7a1.33 1.33 0 011.42.16zM283.06 404.08a.57.57 0 00-.88-.46l-12.2 7a1.34 1.34 0 00-.57 1.3.57.57 0 00.88.46l12.2-7a1.35 1.35 0 00.57-1.3z"
            opacity={0.2}
          />
          <path
            d="M227 431.05v2.34l5.86-1.9a9.05 9.05 0 012.34-2.83l22.26-12.85.68-1.95-.68-.39-22.26 12.85a2.34 2.34 0 01-2.34-.13z"
            fill="#455a64"
          />
          <path
            d="M227.69 431.45v2.34l5.86-1.9a8.94 8.94 0 012.34-2.83l22.26-12.85v-2.35l-22.26 12.85a2.33 2.33 0 01-2.34-.12z"
            fill="#37474f"
          />
          <path fill="#263238" d="M227.69 433.79l-.68-.4v-2.34l.68.4v2.34z" />
          <path
            fill="#455a64"
            d="M227.01 431.05l.68.4 5.86-4.86-.68-.4-5.86 4.86z"
          />
        </g>
      </g>
      <g>
        <path
          d="M310.43 129.94h-51.54a.75.75 0 010-1.5h51.54a.75.75 0 010 1.5z"
          fill="#37474f"
        />
        <path
          d="M192 163.27c-4.95-9.45-12.94-29.87-12.94-29.87l.17-27.41c-.2 0 3.7 1.18 4.89 1.46 7.72 2.85 9.85 4.21 13.16 11 2.63 5.41 11.49 30.08 14.64 38.43l18.85-13.41c4.75-4 5.86-9.7 8.76-13.25s6.19-4.53 9.47-7.54 4.52-5.25 5.78-2.84-2.32 6.84-2.94 7.91c-.43.76-1.52 1.32-1.77 2.17-.41 1.46 1.76 1.23 2.69 1 4.6-1 8.78-4.88 11.62-8.43 1.11-1.39 5.16-6.79 6.19-2.43.84 3.55-2.08 5.82-2.29 9.09a13.11 13.11 0 01-1.4 5.2c-.69 1.48-.37 3.2-.86 4.75-1.51 4.82-5.34 6.47-9.79 8s-9.26 2.58-12.95 5.42c-4.48 3.46-15.31 15.12-21.9 22.13-8.37 8.91-13.89 11-18.79 5.75s-8.72-13.63-10.59-17.13z"
          fill="#ffa8a7"
        />
        <path
          d="M270.6 120.11c-1-4.36-5.08 1-6.19 2.43-2.84 3.55-7 7.43-11.62 8.43-.93.2-3.1.43-2.69-1 .25-.85 1.34-1.41 1.77-2.17.62-1.07 4.2-5.51 2.94-7.91s-2.49-.18-5.78 2.84-6.57 4-9.47 7.54-4 9.26-8.76 13.25l-1.29.92c.78 5.27 4 10.43 8.67 12.8 2.06-2 3.86-3.67 5.13-4.66 3.69-2.84 8.62-4 12.95-5.42s8.28-3.16 9.79-8c.49-1.55.17-3.27.86-4.75a13.11 13.11 0 001.4-5.2c.21-3.28 3.13-5.55 2.29-9.1z"
          fill="#455a64"
        />
        <path
          d="M231.77 142.6c-.09 1.74.88 6.38 3.09 8.85a16 16 0 005.33 3.72l.8-.62s.76.08-1.28 2.13-2.52 2.81-3.66 2.64-4.36-2-6.54-5.39a16.58 16.58 0 01-2.73-8c.06-1 1.46-1.72 2.73-2.64s2.59-2.45 3.3-1.84z"
          fill="#ba68c8"
        />
        <path
          d="M231.77 142.6c-.09 1.74.88 6.38 3.09 8.85a16 16 0 005.33 3.72l.8-.62s.76.08-1.28 2.13-2.52 2.81-3.66 2.64-4.36-2-6.54-5.39a16.58 16.58 0 01-2.73-8c.06-1 1.46-1.72 2.73-2.64s2.59-2.45 3.3-1.84z"
          opacity={0.2}
        />
        <path
          d="M181.66 106.44c5.05 1.17 9 3.08 11.4 5.12 3.7 3.2 5.39 8.19 7.91 15s9 23.4 9 23.4a32.75 32.75 0 00-18 14.11l-17.16-35.71z"
          fill="#e0e0e0"
        />
        <path
          d="M212 156.9a17.29 17.29 0 00-7.69 7.72s0-5.51 7-9.63z"
          fill="#f28f8f"
        />
        <path
          d="M123.62 109.32s-5.87 5.16-7.37 10.74c-.73 2.74-1.41 5 .91 20.24 2.21 14.53 3.78 14.53 4.91 25.61l-1.62 27.73c8.69 10.18 49.27 15.36 69.57 1.65 0 0 .88-42.1-.55-59.44-1-11.79-5.95-23.88-7.81-29.41-5.52-1-10.83-1.6-10.83-1.6l-20.3-1.18z"
          fill="#fafafa"
        />
        <path
          d="M180.39 67.76c2.66-6.12.05-14.24-3.14-18.05-4.61-5.51-11.78-7.89-22.6-7.22-8.22.51-16.31 3.93-17.85 12.37-6.6 2-5.85 12.82-4.21 17.61 3.34 9.72 9.9 18.17 10 23.14z"
          fill="#37474f"
        />
        <path
          d="M180.38 90.61c-1.3 4.69-4 8.13-8.35 8.81a36.51 36.51 0 01-6.85 0l.17 6c9 11.39-12.06 13.7-22.46 1.22l-.62-20.17s-2.65 3-7 .16a9.55 9.55 0 01-3-11.69c2.17-4.57 6.51-2.68 7.85-1.29s2.89 4 4 3.64a4.05 4.05 0 002.69-2.75 24.63 24.63 0 001.41-8.87 11.72 11.72 0 003.4-2.62 11 11 0 003-6.79c9.37-2.93 20.15-1.55 24 1.47 2.24 3.95 2.33 13.13 2.7 18.42.41 5.75.15 10.54-.94 14.46z"
          fill="#ffa8a7"
        />
        <path
          d="M165.18 99.45c-4.8-.27-14.45-2.51-17-5.18s-3.66-8.16-3.66-8.16.38 6.92 2.37 10 7 4.39 10 5.15a76.62 76.62 0 008.35 1.26z"
          fill="#f28f8f"
        />
        <path
          d="M162.14 73.09a2 2 0 01-1.93 2 2 2 0 111.93-2zM159.43 66.89l-3.93 2.65a2.49 2.49 0 01.67-3.38 2.31 2.31 0 013.26.73zM178.5 68.13l-4.31-2.06a2.29 2.29 0 013.11-1.18 2.52 2.52 0 011.2 3.24zM177 72.4a1.94 1.94 0 11-1.94-2 2 2 0 011.94 2z"
          fill="#263238"
        />
        <path fill="#f28f8f" d="M166.6 68.92l1.4 13.94 7.49-2.26-8.89-11.68z" />
        <path
          d="M162.13 86.11l8 1.78a4 4 0 01-4.82 3.26 4.32 4.32 0 01-3.18-5.04z"
          fill="#b16668"
        />
        <path
          d="M165.34 91.15a4.53 4.53 0 00.5.06 3.72 3.72 0 00-3.73-3.64 4.25 4.25 0 003.23 3.58z"
          fill="#f28f8f"
        />
        <path
          d="M131 66.89a25.5 25.5 0 001.78 7.11c.53-.67 1.14-1.33 1.76-2A43 43 0 01147 63.49c10.69-5 23.78-6.68 34.26-3.77a24.1 24.1 0 00-1.95-6A25.38 25.38 0 00131 66.89z"
          fill="#ba68c8"
        />
        <path
          d="M142 41.63c1.27-.62 7.66-2.53 8.18-2.69 3.31-1 8.64-.94 14.11 3.56a25.77 25.77 0 018.56 13.83l-6.8 2.13-2.17.21c-.8-5-7.39-15.77-16.65-16.17a9.43 9.43 0 00-1.3 0 12.21 12.21 0 00-3.59.73 35 35 0 00-3.59 2.88 5.67 5.67 0 013.25-4.48z"
          fill="#ba68c8"
        />
        <path
          d="M142 41.63c1.27-.62 7.66-2.53 8.18-2.69 3.31-1 8.64-.94 14.11 3.56a25.77 25.77 0 018.56 13.83l-6.8 2.13-2.17.21c-.8-5-7.39-15.77-16.65-16.17a9.43 9.43 0 00-1.3 0 12.21 12.21 0 00-3.59.73 35 35 0 00-3.59 2.88 5.67 5.67 0 013.25-4.48z"
          fill="#fff"
          opacity={0.45}
        />
        <path
          d="M163.9 58.68c-1-5.82-9.82-19.59-21.54-15.44a35.08 35.08 0 00-3.6 2.88 5.7 5.7 0 013.24-4.49c3.05-1.47 9.17-2.19 15.5 3a25.74 25.74 0 018.55 13.82z"
          fill="#ba68c8"
        />
        <path
          d="M163.9 58.68c-1-5.82-9.82-19.59-21.54-15.44a35.08 35.08 0 00-3.6 2.88 5.7 5.7 0 013.24-4.49c3.05-1.47 9.17-2.19 15.5 3a25.74 25.74 0 018.55 13.82z"
          opacity={0.3}
        />
        <path
          d="M146.81 61.49c.49-.43 2.86-5.53 11.3-10 5.21-2.78 15.89-2.92 20.68-2.84 8.39.14 10.39 1.92 10.5 3.16 0 .29.14 1.65.18 1.95a1.17 1.17 0 01-.18.8c-1.25 1.9-8 5.2-8 5.2-10.48-2.91-23.57-1.23-34.26 3.77z"
          fill="#ba68c8"
        />
        <path
          d="M146.81 61.49c.49-.43 2.86-5.53 11.3-10 5.21-2.78 15.89-2.92 20.68-2.84 8.39.14 10.39 1.92 10.5 3.16 0 .29.14 1.65.18 1.95a1.17 1.17 0 01-.18.8c-1.25 1.9-8 5.2-8 5.2-10.48-2.91-23.57-1.23-34.26 3.77z"
          opacity={0.15}
        />
        <path
          d="M147 63.49c10.69-5 23.78-6.68 34.26-3.77 0 0 6.78-3.3 8-5.2.68-1.05.07-3.74-10.3-3.91-4.8-.08-15.48.06-20.69 2.84-8.41 4.55-10.78 9.61-11.27 10.04z"
          fill="#ba68c8"
        />
        <path
          d="M147 63.49c10.69-5 23.78-6.68 34.26-3.77 0 0 6.78-3.3 8-5.2.68-1.05.07-3.74-10.3-3.91-4.8-.08-15.48.06-20.69 2.84-8.41 4.55-10.78 9.61-11.27 10.04z"
          opacity={0.3}
        />
        <g>
          <path
            d="M179.29 369.94c.32-5.31 3.17-58.8 3.17-58.8l-26 18.33c4.53 30.58 5.53 39.8 5.72 44.9a4.28 4.28 0 00-.12 1.31c.14 6.06 7.4 9.22 13.09 13.18a105.67 105.67 0 0015.36 9.2c4.11 1.89 12.39 1.95 13.37-.56-6.92-4.86-14.93-10.16-20.47-15.74-2.61-2.67-4.51-5.46-4.12-11.82z"
            fill="#ffa8a7"
          />
          <path
            d="M215.68 403.86c.35 0 .95 3.65-.93 5.24-2.16 1.83-8.55 4.88-17.08 3.95s-13.19-3.36-16.65-6-8.31-6.42-12.18-7.17c-3.59-.7-7.5-2.19-8.67-3.22s-1.09-5.72-1.09-5.72z"
            fill="#263238"
          />
          <path
            d="M189.08 383.77c-.56-.52-1.1-1-1.63-1.58a20 20 0 01-4.07-6.71 19.07 19.07 0 01-1.28-3.7c-.27-1.47-.44-3-.69-4.43-.09-.55 0-1.76-.68-2a4 4 0 00-2.3.43c-1.73.74-3.54 1.31-5.32 1.94-1 .35-3.05.89-3.35 2s.2 2.49 0 3.69c-.09.47-.29 1-.84.94-1 0-1.25-1.44-1.51-2.14-.56-1.47-1-2.16-2.59-2.59-.83-.23-2.45-.34-3-1.08l-.21-2a3 3 0 00-2.11 1.64c-.69 1.43-.23 3.42 0 4.94a22.61 22.61 0 01.38 4.58 28.79 28.79 0 01-.63 3.9 36.08 36.08 0 00-.63 6.8 16.7 16.7 0 00.24 3.16 3.47 3.47 0 001.34 2.16 11.62 11.62 0 003.65 2.18c1.83.73 3.72 1.29 5.58 1.94 3.83 1.32 8.29 4.1 12.21 7.07S194.15 411 199.7 411c8.48 0 14.4-2.8 16-5.72.15-2 .27-4-.64-5.42-3-4.58-9.57-6.06-14.86-8.58a59.91 59.91 0 01-6.66-3.92 34.24 34.24 0 01-4.46-3.59z"
            fill="#37474f"
          />
          <path
            d="M203.48 392.66c-10.39 2.94-14 9.95-15.53 15.62A34.44 34.44 0 00199.7 411c8.48 0 14.4-2.8 16-5.72.15-2 .27-4-.64-5.42-2.35-3.7-7.06-5.38-11.58-7.2zM169.4 397.8l.44.17c-.41-8-3.71-13.3-10.94-14.37a35.9 35.9 0 00-.31 4.76 16.7 16.7 0 00.24 3.16 3.47 3.47 0 001.34 2.16 11.62 11.62 0 003.65 2.18c1.83.73 3.72 1.29 5.58 1.94z"
            fill="#455a64"
          />
          <path
            d="M136.45 326.89l-27.1-5.89c-.71 16.77-.28 39.46-.85 55.2-.11 3-1.64 23-.79 29.14 1.28 9.25 13.67 8.65 14.69 3.21s1.92-28.7 2.13-31.11c.57-10.08 6.33-27 10.36-45 .2-.83 1.37-4.69 1.56-5.55z"
            fill="#ffa8a7"
          />
          <path
            d="M126.78 412.63c-.1 5.39-.89 9-2.91 12.22s-6.05 4.38-10.22 3.61-9.58-3-11-7.64-1.3-7.78-.59-12.8z"
            fill="#263238"
          />
          <path
            d="M108.72 366.93c-1.69.18-2 14.81-2.68 22.07-.7 7.78-3.17 11.08-3.82 17.47-.74 7.3-.16 10.52 2.92 14.8s13.49 8.21 18.23 1.74c3.86-5.28 3.77-11 3.07-18.2-.73-7.47-.76-19.45-.67-25.15.08-5.44 1.73-11.19.22-11.74-.32 1.46-.52 2.71-.69 3.49-.37 1.72-.78 3.8-1.3 3.41 0 0 .15-1.53.17-2.23s.17-1.16-.3-1.61-2.51-.73-3.21-.82c-1.69-.24-3.38-.43-5.09-.51h-2.56a8.05 8.05 0 00-2.14.35 1.45 1.45 0 00-.89.78 2.1 2.1 0 000 .83 5.35 5.35 0 01-.14 1.49c-.06.22-.21.49-.46.45s-.4-.18-.47-.67a23.05 23.05 0 01-.16-3v-1.59c0-.47-.04-.9-.03-1.36z"
            fill="#37474f"
          />
          <path
            d="M101.94 410.69c-.09 4.52.35 7.59 2.76 10.94 3.08 4.28 14.31 8.19 19 1.72 2.36-3.22 2.86-6.94 3-10.72-3.16-4.73-20.19-6.24-24.76-1.94zM191.12 147.78s-20.57 4.63-51.41 2.51c0 20.38 2.15 43.76-7.61 43.63l-11.65-.28c-.65 30.52-5.81 94.15-5.81 94.15-.46 3.75-5.89 19.69-6.09 33.25-.31 21.27-.76 37.39-.76 37.39s11.88 4.7 20.61 1.44c0 0 11.35-45.21 14.54-59.32 3.79-16.81 11.62-47.87 11.62-47.87l.95 44.23a121.71 121.71 0 00-.36 26.86c1 9 4.65 33.91 4.65 33.91s10.93 2.2 20.41-.61c0 0 5.71-53.57 6.18-61.82.51-9 3.35-91.67 3.61-99.38 2.6-38.87 1.12-48.09 1.12-48.09z"
            fill="#455a64"
          />
          <path
            d="M139.71 150.29c-1.44-18.46-1.4-33.63-8.32-42.6l8.84-1.86s7.65 16.94 7.65 45.09zM183 149.81c0-20.11-4.43-37.35-11.45-44.88l10.11 1.51s9.46 15.22 9.46 43.37z"
            fill="#455a64"
          />
          <path
            d="M150.82 154.51s16.25 1 29.9-1.59l.62 13.83-13.62 8.54-16.9-5.52z"
            fill="#37474f"
          />
          <path
            d="M124.65 194.65c7.83 3.25 26.84 5.2 42.1 5s23.25-3.79 23.25-3.79l-.25 9.3s-27.08 11.36-66.26-2z"
            fill="#ba68c8"
          />
          <path
            d="M124.65 194.65c7.83 3.25 26.84 5.2 42.1 5s23.25-3.79 23.25-3.79l-.25 9.3s-27.08 11.36-66.26-2z"
            opacity={0.3}
          />
          <path
            d="M154.56 252.68l7.26-29.34s10.88.14 18-6.51c0 0-1 7.49-13.93 11.49l-6.72 23.31-3.81 44.45z"
            fill="#37474f"
          />
          <path
            d="M143 104.58l.06 2a73.48 73.48 0 013.39 13.67c2.1 12.67 2.46 29.28 2.46 48v51.58s-23.45 1.5-30.9-16.4l3-37.52s-4.3-19.4-5.47-30.49-3.26-18.86 7.11-27.34z"
            fill="#ba68c8"
          />
          <path
            d="M118.85 155.42a497.64 497.64 0 002.18 10.49l-.11 1.47c7.25 4.74 18 7.78 28 7.32v-10.48c-10.92.5-22.92-3.22-30.07-8.8zM148.9 195.52V185c-10.28.47-21.49-2.8-28.74-7.83l-.76 9.93c7.2 5.37 18.85 8.9 29.5 8.42z"
            fill="#f0f0f0"
          />
          <path
            d="M165.72 104.34a29.18 29.18 0 0113.21 17.74c3.16 12.42 3.63 22.22 4.07 38.17s-.21 60.45-.21 60.45 7-3.07 8.54-6.4c0 0 2-56.51-.21-76.54s-9.46-31.32-9.46-31.32-5.18-2.12-15.94-2.1z"
            fill="#ba68c8"
          />
          <path
            d="M183.17 172.69a50.15 50.15 0 009-1.08v-10.47a50.21 50.21 0 01-9.17 1.06c.11 3.02.15 6.61.17 10.49zM192 192.48c.06-3.22.13-6.78.17-10.52a50 50 0 01-8.94 1v10.49a51.38 51.38 0 008.77-.97z"
            fill="#f0f0f0"
          />
        </g>
        <g>
          <path
            d="M127.89 108.29c-6 0-13-.26-16.93 8.4-1.28 2.82-5.23 19.4-9.62 34.69-7.86-10-14.57-20.25-15.44-22.07-2.33-4.87-2.36-8.16-4.74-13.19a41.45 41.45 0 01-3.16-8.64c-.57-2-2.35-.53-3 .37a9.43 9.43 0 00-1.65 5.15c0 1.3 0 2.63-.1 3.91s-.53 1.23-1.57.65c-2.14-1.2-4-3.27-5.74-4.93s-4.12-3.22-6-5-2.83-3.59-4.36-5.39c-1.15-1.35-3.11-3.1-4.62-1.17-.9 1.17-.07 2.53.33 3.7.46 1.33 0 2.31-.25 3.62a6.74 6.74 0 00.12 4.35c.72 1.41 1.36 2.28 1.17 3.94-.15 1.37-.64 2.68-.44 4.09.16 1.16 1 2 1.58 3a21.83 21.83 0 003.77 4.82 44.93 44.93 0 005.63 4.83c4.81 3.74 6.6 4.91 8.21 6.16 2.41 1.86 4.9 6.63 6.3 9.26 2.73 5.16 8.62 19.54 16.48 29.83 4.55 5.93 10.74 7.82 16.2 1.12 2.86-3.52 5.29-9.1 6.87-13.32C123 150.3 127.39 137 127.39 137s4.9-15.11.5-28.71z"
            fill="#ffa8a7"
          />
          <path
            d="M88.81 134c-1.56-2.36-2.6-4.07-2.91-4.71-2.33-4.87-2.36-8.16-4.74-13.19a41.45 41.45 0 01-3.16-8.62c-.57-2-2.35-.53-3 .37a9.43 9.43 0 00-1.65 5.15c0 1.3 0 2.63-.1 3.91s-.53 1.23-1.57.65c-2.14-1.2-4-3.27-5.74-4.93s-4.12-3.22-6-5-2.83-3.59-4.36-5.39c-1.15-1.35-3.11-3.1-4.62-1.17-.9 1.17-.07 2.53.33 3.7.46 1.33 0 2.31-.25 3.62a6.74 6.74 0 00.12 4.35c.72 1.41 1.36 2.28 1.17 3.94-.15 1.37-.64 2.68-.44 4.09.16 1.16 1 2 1.58 3a21.83 21.83 0 003.77 4.82 44.93 44.93 0 005.63 4.83c4.81 3.74 6.6 4.91 8.21 6.16 2.29 1.77 4.65 6.16 6.08 8.84 4.78-3.06 9.11-8.94 11.65-14.42z"
            fill="#455a64"
          />
          <path
            d="M87.29 131.67l-.75-1.12s.3-1.31 1.52.56a30.25 30.25 0 012.83 4.95c.15.71-1.06 6.83-4.86 9.87s-7.14 3.95-8.2 3.82-1.36-1.09-2-2.36-2.12-2.83-1.67-4l.86 1.21a17.12 17.12 0 008.76-5.46c3.37-4.29 3.51-7.47 3.51-7.47z"
            fill="#ba68c8"
          />
          <path
            d="M87.29 131.67l-.75-1.12s.3-1.31 1.52.56a30.25 30.25 0 012.83 4.95c.15.71-1.06 6.83-4.86 9.87s-7.14 3.95-8.2 3.82-1.36-1.09-2-2.36-2.12-2.83-1.67-4l.86 1.21a17.12 17.12 0 008.76-5.46c3.37-4.29 3.51-7.47 3.51-7.47z"
            opacity={0.2}
          />
          <path
            d="M101.34 151.38a45.36 45.36 0 015.11 17.55c1-9.11-1.77-17.44-4.33-20.25z"
            fill="#f28f8f"
          />
          <path
            d="M128.88 107c-5.72.9-6.94 1-10.59 2.26s-6.27 2.51-9.64 13.5c-3.24 10.6-6.26 22.66-6.26 22.66s3.2 8.38 19 10l8.37-23.34s2.58-14.08-.88-25.08z"
            fill="#ebebeb"
          />
          <path
            d="M72.67 119.87a3.2 3.2 0 11-3.57-2.79 3.19 3.19 0 013.57 2.79z"
            fill="#263238"
          />
          <path
            d="M255.72 129.94h-3.54a3.44 3.44 0 00-3.44 3.44v37.42a17 17 0 01-17 17H85.71a17 17 0 01-17-17v-48.72a.75.75 0 011.5 0v48.37a15.81 15.81 0 0015.82 15.81h145.39a15.81 15.81 0 0015.82-15.81v-37.07a4.94 4.94 0 014.94-4.94h6.09a18.13 18.13 0 01-2.55 1.5z"
            fill="#37474f"
          />
          <path
            d="M65.7 120.93c-2.25-1-2.49-2.93-.53-4.4a8.47 8.47 0 017.63-.93c2.25 1 2.49 2.93.53 4.4a8.47 8.47 0 01-7.63.93z"
            fill="#37474f"
          />
          <path
            d="M65.44 119.8c-2.32-1-2.57-3-.55-4.54a8.76 8.76 0 017.87-1c2.32 1 2.57 3 .55 4.54a8.76 8.76 0 01-7.87 1z"
            fill="#263238"
          />
          <path
            d="M65.18 118.63c-2.4-1-2.65-3.12-.57-4.68a9 9 0 018.11-1c2.39 1 2.65 3.12.57 4.68a9 9 0 01-8.11 1z"
            fill="#37474f"
          />
          <path
            d="M64.91 117.42c-2.47-1-2.74-3.21-.59-4.82a9.3 9.3 0 018.35-1c2.47 1 2.74 3.21.59 4.83a9.3 9.3 0 01-8.35.99z"
            fill="#263238"
          />
          <path
            d="M64.67 116.15c-2.52-1.07-2.79-3.27-.6-4.92a9.47 9.47 0 018.52-1c2.52 1.07 2.79 3.28.6 4.92a9.45 9.45 0 01-8.52 1z"
            fill="#37474f"
          />
          <path
            d="M64.41 78.58a15.27 15.27 0 00-13.31 17c1.47 12 11.64 12.82 12.23 17.67a2.65 2.65 0 001.79 2 8.27 8.27 0 007.4-.9 2.63 2.63 0 001.27-2.35c-.59-4.85 9.09-8.06 7.63-20.09a15.27 15.27 0 00-17.01-13.33z"
            fill="#ba68c8"
          />
          <path
            d="M64.41 78.58a15.27 15.27 0 00-13.31 17c1.47 12 11.64 12.82 12.23 17.67a2.65 2.65 0 001.79 2 8.27 8.27 0 007.4-.9 2.63 2.63 0 001.27-2.35c-.59-4.85 9.09-8.06 7.63-20.09a15.27 15.27 0 00-17.01-13.33z"
            fill="#fff"
            opacity={0.6}
          />
          <path
            d="M66 114.43c-1.64-.7-1.82-2.14-.39-3.21a6.2 6.2 0 015.56-.68c1.64.7 1.82 2.14.39 3.21a6.17 6.17 0 01-5.56.68z"
            fill="#ba68c8"
          />
          <path
            d="M70.52 111.86a.51.51 0 01-.57-.44 36.52 36.52 0 010-8.79 19.34 19.34 0 01.46-2.5 7.43 7.43 0 01-1.41-.48 8.82 8.82 0 01-2.21-1.45 9.07 9.07 0 01-1.79 1.94 7 7 0 01-1.24.8 18.81 18.81 0 011.05 2.32 36.93 36.93 0 012.12 8.53.53.53 0 01-.46.57.53.53 0 01-.57-.47 36.27 36.27 0 00-2.06-8.27 17 17 0 00-1.05-2.3 3.26 3.26 0 01-3-.49 1.66 1.66 0 01-.48-2.1 1.37 1.37 0 011.35-.73c.83.07 1.73.8 2.57 2.08a5.53 5.53 0 001.12-.72 7.79 7.79 0 001.72-1.88 3.87 3.87 0 01-1.1-2.8 1.45 1.45 0 012.76-.34 3.78 3.78 0 01-.39 3 7.68 7.68 0 002.12 1.42 6.07 6.07 0 001.26.43c.51-1.44 1.2-2.37 2-2.63a1.39 1.39 0 011.47.41 1.66 1.66 0 010 2.15 3.24 3.24 0 01-2.76 1.19 16.13 16.13 0 00-.48 2.5 37.27 37.27 0 000 8.51.52.52 0 01-.44.59zM60.51 99c-.08 0-.18.06-.27.23-.21.37.13.7.24.8a2.12 2.12 0 001.72.34c-.67-.94-1.26-1.35-1.61-1.37zm11.29.2a2 2 0 001.58-.75c.08-.11.34-.52.05-.83a.36.36 0 00-.4-.14c-.33.12-.8.66-1.23 1.73zm-5.45-4.72c-.26 0-.33.15-.36.32a2.54 2.54 0 00.6 1.64 2.57 2.57 0 00.19-1.74.34.34 0 00-.43-.21z"
            fill="#fff"
          />
          <path
            d="M74.19 73.74a1 1 0 01-.56-1.32l2-4.88a1 1 0 011.3-.54 1 1 0 01.56 1.31l-2 4.89a1 1 0 01-1.32.56zM62.63 72.3a1 1 0 01-.62-.79l-.73-5.22a1 1 0 012-.28l.72 5.22a1 1 0 01-.86 1.14 1 1 0 01-.51-.07zM51.9 76.84a1 1 0 01-.41-.31l-3.24-4.16a1 1 0 011.6-1.24l3.24 4.15a1 1 0 01-.18 1.42 1 1 0 01-1.01.14zM40 84.15a1 1 0 01.77-1.87l4.88 2a1 1 0 11-.76 1.87l-4.88-2zM38.23 98.41a1 1 0 01-.61-.79 1 1 0 01.86-1.14l5.22-.73a1 1 0 01.28 2l-5.22.73a.94.94 0 01-.53-.07zM88 91.48a1 1 0 01.25-1.94l5.22-.73a1 1 0 011.14.87 1 1 0 01-.86 1.14l-5.22.72a.94.94 0 01-.53-.06zM83.48 80.76a1 1 0 01-.42-.32 1 1 0 01.18-1.44l4.16-3.24a1 1 0 111.24 1.6l-4.16 3.24a1 1 0 01-1 .16z"
            fill="#e0e0e0"
          />
        </g>
        <path
          d="M118 196.08l-5.3 2.92a2.56 2.56 0 00-1.2 2c-.13 4.73-.57 23.33.19 32.22a2.8 2.8 0 001.28 2c3 1.69 11.14 6.15 14.87 7.18a4.68 4.68 0 002.53-.27l3.76-1.51a2.14 2.14 0 001.23-1.82c0-9.44 1.72-22.19 3.28-32.47a1.9 1.9 0 00-1-1.89L120.33 196a2.87 2.87 0 00-2.33.08z"
          fill="#455a64"
        />
        <path
          d="M111.69 233.18c-.76-8.89-.32-27.49-.19-32.22a.78.78 0 011.23-.73l16.12 8a2.1 2.1 0 011.07 1.91c-.34 4.19-1.37 19.95-.85 31.08a1 1 0 01-1.23 1.1c-3.73-1-11.86-5.49-14.87-7.18a2.8 2.8 0 01-1.28-1.96z"
          fill="#37474f"
        />
        <path
          d="M128 242.37a4.82 4.82 0 002.33-.32l3.77-1.51a2.18 2.18 0 001.21-1.82c.74-9.69 1.72-22.18 3.29-32.46a1.63 1.63 0 00-.19-1l-8.72 4a1.83 1.83 0 01.19.93c-.35 4.21-1.37 20-.85 31.09a1 1 0 01-1.03 1.09zM113.75 204l14.12 6.59-.68 12-13.82-5.93s.13-8.58.38-12.66z"
          fill="#263238"
        />
        <path
          d="M127.24 221.86L114 216.19s.12-7.84.34-11.89l13.5 6.29z"
          fill="#ba68c8"
        />
        <path
          d="M127.24 221.86L114 216.19s.12-7.84.34-11.89l13.5 6.29z"
          opacity={0.4}
        />
        <path
          fill="#fafafa"
          d="M121.33 208.61l-2.96 4.28 1.86.64-1.45 3.78 3.31-4.46-1.86-.7 1.1-3.54z"
        />
        <path
          d="M126.3 225.34l-.09 1.19a.45.45 0 01-.66.41l-2.14-.88a1 1 0 01-.57-.92l.07-1.21a.44.44 0 01.65-.41l2.17.9a1 1 0 01.57.92zM126.31 229.21l-.06 1.19a.45.45 0 01-.65.42l-2.16-.85a1 1 0 01-.58-.9v-1.22a.44.44 0 01.64-.42l2.18.87a1 1 0 01.63.91zM126.33 233.08v1.19a.45.45 0 01-.65.43l-2.16-.81a1 1 0 01-.61-.9v-1.22a.44.44 0 01.64-.42l2.19.83a1 1 0 01.59.9zM126.35 237v1.19a.45.45 0 01-.64.44l-2.18-.78a1 1 0 01-.62-.88v-1.22a.44.44 0 01.63-.44l2.21.8a1 1 0 01.6.89zM121.5 223.15l-.06 1.24a.44.44 0 01-.65.41l-2.12-.88a1 1 0 01-.59-.92v-1.26a.43.43 0 01.64-.41l2.16.9a1 1 0 01.62.92zM121.49 227l-.06 1.24a.44.44 0 01-.65.41l-2.12-.87a1 1 0 01-.6-.92v-1.26a.43.43 0 01.64-.41l2.16.89a1 1 0 01.63.92zM121.47 230.84v1.24a.44.44 0 01-.64.42l-2.14-.86a1 1 0 01-.59-.92v-1.26a.43.43 0 01.63-.42l2.16.89a1 1 0 01.58.91zM121.45 234.69v1.24a.44.44 0 01-.64.41l-2.14-.85a1 1 0 01-.6-.9v-1.27a.44.44 0 01.64-.42l2.16.88a1 1 0 01.58.91zM116.71 221v1.29a.43.43 0 01-.64.4l-2.11-.88a1 1 0 01-.59-.92v-1.32a.42.42 0 01.62-.4l2.14.91a1 1 0 01.58.92zM116.73 224.71V226a.42.42 0 01-.63.4l-2.11-.88a1 1 0 01-.6-.92v-1.32a.42.42 0 01.63-.4l2.14.91a1 1 0 01.57.92zM116.74 228.45v1.29a.43.43 0 01-.64.4l-2.11-.88a1.06 1.06 0 01-.6-.92v-1.32a.42.42 0 01.63-.4l2.14.91a1 1 0 01.58.92zM116.75 232.19v1.29a.42.42 0 01-.63.4L114 233a1 1 0 01-.59-.92v-1.32a.42.42 0 01.62-.4l2.14.9a1.06 1.06 0 01.58.93z"
          fill="#263238"
        />
      </g>
      <g>
        <path
          d="M358.23 389.5l-9.23-3.14c-.79-8.84.35-30.26 14.72-42.83 14.59-12.76 24.11-4.58 23.83 1-.3 6.09-13.39 11.8-20 20-7.22 8.92-9.47 15.02-9.32 24.97z"
          fill="#ba68c8"
        />
        <path
          d="M358.23 389.5l-9.23-3.14c-.79-8.84.35-30.26 14.72-42.83 14.59-12.76 24.11-4.58 23.83 1-.3 6.09-13.39 11.8-20 20-7.22 8.92-9.47 15.02-9.32 24.97z"
          fill="#fff"
          opacity={0.5}
        />
        <path
          d="M352 387.87a.5.5 0 01-.49-.5c.13-23.78 17.79-44.59 26.59-45.94a.5.5 0 11.15 1c-8.62 1.32-25.62 22.49-25.74 44.95a.5.5 0 01-.51.49z"
          fill="#fff"
        />
        <path
          d="M380.16 368.18c-8-2.63-25.4 6.12-28.77 19l6.84 2.31a37.83 37.83 0 0119.29-8.25c11.27-1.13 10.61-10.43 2.64-13.06z"
          fill="#ba68c8"
        />
        <path
          d="M380.16 368.18c-8-2.63-25.4 6.12-28.77 19l6.84 2.31a37.83 37.83 0 0119.29-8.25c11.27-1.13 10.61-10.43 2.64-13.06z"
          fill="#fff"
          opacity={0.65}
        />
        <path
          d="M354.51 388.92a.48.48 0 01-.23-.05.51.51 0 01-.21-.68c6.85-13.23 21.72-17.14 27.77-15.52a.49.49 0 01.35.61.51.51 0 01-.61.36c-5.78-1.55-20 2.25-26.62 15a.49.49 0 01-.45.28z"
          fill="#fff"
        />
        <g>
          <path
            d="M346.79 241.28v147.88a2.76 2.76 0 001.64 2.28 8.69 8.69 0 007.91 0 2.78 2.78 0 001.64-2.28V241.28z"
            fill="#455a64"
          />
          <path fill="#37474f" d="M346.79 269l11.19-6.46v-21.26h-11.19V269z" />
          <path
            fill="#ba68c8"
            d="M331.16 266.62l77.18-44.56V83.93l-77.18 44.56v138.13zM408.34 83.93l-34.73-20.05-77.18 44.56 34.73 20.05 77.18-44.56z"
          />
          <path
            fill="#fff"
            opacity={0.2}
            d="M408.34 83.93l-34.73-20.05-77.18 44.56 34.73 20.05 77.18-44.56z"
          />
          <path
            fill="#ba68c8"
            d="M296.43 108.44v138.13l34.73 20.05V128.49l-34.73-20.05z"
          />
          <path
            opacity={0.2}
            d="M296.43 108.44v138.13l34.73 20.05V128.49l-34.73-20.05z"
          />
          <path
            fill="#fafafa"
            d="M380.37 119.84l-28.62 74.45 18-14.56-14 52.64 32-80.04-18 14.04 10.62-46.53z"
          />
          <path
            d="M337 129.6c1.06-.61 1.93-.11 1.93 1.12a4.27 4.27 0 01-1.93 3.34c-1.07.62-1.93.12-1.93-1.11a4.28 4.28 0 011.93-3.35zM337 254.38c1.06-.62 1.93-.12 1.93 1.11a4.27 4.27 0 01-1.93 3.34c-1.07.62-1.93.12-1.93-1.11a4.24 4.24 0 011.93-3.34zM402.55 91.73c1.07-.62 1.93-.12 1.93 1.11a4.24 4.24 0 01-1.93 3.34c-1.06.62-1.92.12-1.92-1.11a4.26 4.26 0 011.92-3.34zM402.55 216.5c1.07-.62 1.93-.12 1.93 1.11a4.24 4.24 0 01-1.93 3.34c-1.06.62-1.92.12-1.92-1.11a4.26 4.26 0 011.92-3.34z"
            fill="#37474f"
          />
          <path
            fill="#ba68c8"
            d="M408.34 83.93v98.03l42.45-24.51V19.32l-77.18 44.56 34.73 20.05z"
          />
          <path
            fill="#ba68c8"
            d="M450.79 19.32l-77.18 44.56-3.86-2.23 77.18-44.56 3.86 2.23z"
          />
          <path
            fill="#fff"
            opacity={0.2}
            d="M450.79 19.32l-77.18 44.56-3.86-2.23 77.18-44.56 3.86 2.23z"
          />
          <path fill="#ba68c8" d="M369.75 66.11v-4.46l3.86 2.23-3.86 2.23z" />
          <path opacity={0.2} d="M369.75 66.11v-4.46l3.86 2.23-3.86 2.23z" />
          <path
            opacity={0.2}
            d="M373.61 63.88l49.7 13.64v95.8l-14.97 8.64V83.93l-34.73-20.05z"
          />
        </g>
      </g>
    </svg>
  );
}

export default EleSVG;
