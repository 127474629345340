import React from 'react';

const ElectLogo = (props) => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
      fill="#000"
      {...props}
    >
      <path d="M19.188 0a1.005 1.005 0 00-.594.313L15.687 3.28c-.828-.73-1.593-1.379-2.218-1.812-.399-.278-.746-.508-1.094-.657a1.88 1.88 0 00-.594-.156c-.246-.023-.676.02-1 .344L5.875 6l.031.031c-.168.18-.265.383-.312.563l-.032.125v.062L4.407 13.5c-.05.195.309.754.938 1.531l-.657.656c-.875.876-.968 2.157-.312 3.126l-4.281 4.28 2.812 2.813 4.281-4.312h.032c.965.574 2.187.46 2.968-.407l.563-.562c.867.719 1.543 1.113 1.75 1.063l6.688-1.157c.023.004.039 0 .062 0 .191.012.469-.008.75-.218l5-5c.27-.27.309-.579.313-.75a1.374 1.374 0 00-.032-.376c-.047-.187-.117-.32-.187-.468a8.74 8.74 0 00-.532-.906 13.54 13.54 0 00-.625-.907c-.207-.27-.3-.476-.625-.719l-1.218 1.626a11.145 11.145 0 01.781 1.063c.113.179.2.32.281.468l-4.031 4c-.336-.211-.832-.578-1.438-1.063-1.238-.996-2.906-2.453-4.687-4.187-1.773-1.723-3.293-3.387-4.281-4.625C8.227 7.848 7.875 7.332 7.688 7l4.125-4.188c.144.079.277.126.5.282.507.351 1.21.933 1.968 1.594L12.5 6.5a1.004 1.004 0 00-.348 1.004c.086.371.38.664.75.75.375.086.762-.05 1.004-.348L20 1.688A1 1 0 0019.187 0zm-1.282 5.281L16.5 6.72l1.406 1.375v.031h.032A28.67 28.67 0 0120 10.219l-1.906 1.875a1.004 1.004 0 00-.348 1.004c.086.37.379.664.75.75.375.086.762-.051 1.004-.348l6.188-6.094a1 1 0 00-.813-1.719.99.99 0 00-.563.313l-2.875 2.813a32.99 32.99 0 00-2.125-2.126z" />
    </svg>
    );
}

export default ElectLogo;
