import React from "react";
import "./About.css";

const AboutHome = () => {
  return (
    <div>
      <div className="about-wrapper">
        <div className=" info">
          <h2 className="title">About SGI</h2>
          <p>
            In the world where it is commonplace to see mediocrity as the order
            of the day, SGI always stands as the guiding star of brilliance,
            quality and deliverance beyond expectations. Innovativeness and
            Creativity are the hallmarks of a genius enterprise and SGI stands
            to be a stage where these qualities would be nurtured, encouraged
            and blossomed. 
          </p>
        </div>
        <div className=" solid-sec">
          <img
            src={require(`../../../img/home/about/Dblock.jpg`)}
            alt=""
            className="img-about"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
