import React from "react";
import Main from "../../ReusableComponents/Main";
import "./CSS/management.css";

function managementChairman() {
  return (
    <Main isSlideShow={true}>
      <div>
        <br />
        <br />
        <div className="card mb-3 mar ">
          <div className="row">
            <div className="col-3"></div>
            <div className="col-6">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/sgpoly-86d3b.appspot.com/o/srcimg%2FHome%2Fsanjayghodawat.jpg?alt=media&token=6ec511d4-45d9-4d4a-a3fb-e650c8f7243c"
                className="card-img-top trust-image"
                alt="..."
              />
            </div>
          </div>
          <div className="card-body ">
            <h5 className="card-title text-center">
              <i className="fas fa-user-alt"></i> Profile of Chairman
            </h5>
            <h4 className="card-title text-center">Mr.Sanjay Ghodawat</h4>
            <p>
            Sanjay D. Ghodawat is an industrialist and an educational entrepreneur by profession. He is a 
Mechanical Engineer and also a qualified trained Pilot to fly Helicopter, Fixed Wing Airplane and 
Paraglider. He is also recognized as a Philanthropist. He heads the Sanjay Ghodawat Group as its 
Chairman and the Sanjay Ghodawat University as the President. His business ventures span from the 
India's largest Hi-Tech Agriculture, Energy, Realty, Softech, Tetra Pak, Aviation including Airplanes and 
Helicopters, Education, Textiles Marble Mining, Consumer Products under the brand name 'Star' 
including Namkeen (salted savouries), rice, salt, edible oils, turmeric powder, atta (flour), jaggery, 
Ayurstar hair oil etc. and also beverages and fruit juices under the brand names, `Fizzinga' and 'Frustar also 
`Klemax' that includes household care products & Ghodawat Retail. SG Group helps the nation by 
producing Green Energy through the means of Wind Turbine, Solar Energy with a capacity of 150 
megawatts. The Group has also entered the Retail market with its Star Local Mart that will generate 
employment for 25000 people. Ghodawat consumer is also coming up with its IPO soon. The Group 
turnover is more than Rs. 2500 crore and it further aims for Rs. 8000 crore by 2026. Currently the Sanjay 
Ghodawat Group has more than 10,000 employees under its aegis. Sanjay Ghodawat has also served as 
the Chairman of Jain International Trade Organization Engineering and Management Program. He is the 
Director of the JITO Administrative Training Foundation (JATF) too.

              <br />
              <br />
              In 2009, with the attitude of giving back to the society, he ventured into the Education field 
with the commencement of Sanjay Ghodawat Group of Institutions offering Engineering, Management, 
Polytechnic, International School, Junior College, Olympiad School, IIT & Medical Academy, Sanjay 
Ghodawat Administrative Services providing coaching for UPSC, MPSC and other Civil and 
Administrative Services exams. In the previous years the institute has been counted as Asia's fastest 
emerging private educational institute as surveyed by WCRC and KPMG, India. Currently it has more 
than 18,000 students under its banner from KG to Ph.D. level. The institute has been already accredited 
with the NAAC 'A' grade and also its various programs have received accreditation through NBA. The 
natural further evolution was the formation of Private University and from 2017-18 the Institute has 
evolved as Sanjay Ghodawat University offering Undergraduate, Post Graduate and Doctoral programs. 
The University has already bagged 'Best Emerging Private University in the State by Navbharat Times 
and is the first University in the country to earn ISO 9001: 2015 and ISO 21001: 2018 certification. The 
Times of India Ranking 2020 has ranked the School of Technology as 4th for Top Emerging Engineering 
Institutes of the Nation and the School of Commerce & Management as Top Emerging B School. 
st th Education World Ranked the University 1 in Promising Future Ready Universities and 11 in the State 
rd and 73 in the nation. 

            </p>




            <p>

            <ul>
            <b>He is the recipient of many awards like:</b>
             <br /><ol><a href="https://www.outlookindia.com/business-spotlight/30-glorious-years-of-business-excellence-news-273931?prev">*30 Glorious Years Of Business Excellence</a></ol> 
             <br /><ol>*Maharashtra State Vanashree Puraskar, 2011.</ol>            
             <br /><ol>*Jain Ratna Award through Gujarati Screen Awards, Mumbai, 2018</ol>
             <br /><ol>*Samaj Bhushan Gaurav Award through Jain Minority Federation of India for his philanthropic services to society and notable work in Education & Business sectors, 2018</ol>
             <br /><ol>*Navbharat Business Excellence Award by hands Hon. Chief Minister Devendra Fadanvis, Dec., 2018          </ol>
             <br /><ol>*Friend of the Blind Award through National Association for the Blind Society, Sangli, Jan., 2019.</ol>
             <br /><ol>*Business Philanthropist of the Year award 2021 by Maha Sanskriti for notable social work during the Pandemic Covid 19.</ol>
             <br /><ol>*Philanthropist of the Year Award by Globoil India, Sept 2021 for notable social work during the Pandemic Covid 19.</ol>
             <br /><ol>*Times Most Powerful Leader for 2022 by Times of India.</ol>
             <br /><ol>*Education Stalwart of India 2021-22 Award by Education Today, Bangalore.</ol>
             <br /><ol>*Inclusion among Masters of Wisdom for contribution to education ﬁeld 2022 by Femina, India</ol>
             <br /><ol>*Recipient of Maharashtra Leadership Award 2022 for excellence in the ﬁeld of Education and Industry through the hands of Hon’ble Governor of Maharashtra Shri Bhagat Singh Koshyariji.</ol>
             <br /><ol>*Recipient of The Mahatma Award 2022 through Aditya Birla Group for notable social contribution during the Pandemic.</ol>
             <br /><ol>*Recipient of Ahimsa International Award, 2022 for his impactful contribution in Corporate Social Responsibility, Sustainability and Social Impact. By the hands of Hon’ble Bhagat Singh Koshyari, Governor of Maharashtra.</ol>
             <br /><ol>*50 Oustanding Education entrepreneurs of india through WEC, 2013 </ol>
             <br /><ol>*Game Changers of India by Economic Times, UK 2018</ol>
            


            </ul>

            </p>

            <p>As a true son of the soil he has always held an attitude of giving and through CSR and ISR 
activities extended his helping hand in times of need. He has donated to the families of soldier martyrs 
from time to time; he also has helped many drought affected farmers of the Kolhapur — Sangli region 
through his generous contribution, extended aid in times of calamities like earthquakes, floods, running 
a school for blind for 13 years to aid 'The National Association for Blind Society'. He runs the 
charitable trust under his Mother's name, 'Sou Sushila Danchand Ghodawat Charitable Trust'. He has 
started a blood bank 'Shree Acharya Tulsi Blood Bank' under this Trust. He has wholeheartedly 
supported budding International level sports people from our nation and contributed to NGOs like 
Olympic Gold Quest, which is into grooming sports personnel for Olympics. During the Covid 19 
crises he has helped in the form of donating food grains for migrants, sanitization of villages, 
converting hostel buildings as Covid Care centres where more than 35000 patients were treated, 
converting his helicopter as air ambulance, distribution of food to people stranded, animal care and 
feeding etc. </p>
<p> He is married to Neeta Ghodawat who is the Vice Chairman of the Trust. His elder son, 
Shrenik looks after the Consumer Products Division and Star Aviation and is the Managing Director of the 
Group. His daughter, Shreya has completed her Masters in Sustainable Tourism from Switzerland & 
Business Sustainability Management from Cambridge University. The Chairman is an automobile 
and bike enthusiast and driving them is one of his favourite hobbies. He devotes his spare time to fitness 
and also loves swimming and playing badminton, tennis and cricket. He has also been always on the 
forefront in donating his blood and till date has done so for 80 times.</p>

            <a
              className="btn btn-lg btn-outline-success w-100"
              href="http://www.sgipolytechnic.in/Pdf/Chairman_Awards.pdf"
            >
              <i className="fas fa-award"></i> Chairman Profile
            </a>
          </div>
        </div>
      </div>
    </Main>
  );
}

export default managementChairman;
