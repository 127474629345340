import * as React from "react";

function CharitySVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750 500" {...props}>
      <g fill="#ebebeb">
        <path d="M151.14 152.88l-87.61-.13s4.36-5.67 9.17-6.37c0 0 9.38-27.79 25-28 14.54-.16 20.34 17.14 20.34 17.14s6.75-7.75 13.18-4.19 10.06 13.71 10.06 13.71 7.24 3.96 9.86 7.84zM556.2 221.73l110.8.17s0-7.08-15.25-8.49c0 0-13.89-31-35.17-26.84-13.25 2.57-20.05 20.75-20.05 20.75s-10-14.33-18-11.57-12.79 18-12.79 18-8.28 3.98-9.54 7.98zM177.46 93.43l75.34.12s-7.1-6.63-10.26-6.63-12.35-22.24-24.13-21.31-18.07 17.46-18.07 17.46-14.34-4.51-22.88 10.36zM622.05 144.61l64.42.1s-3.19-4.18-6.72-4.7c0 0-6.84-20.46-18.34-20.62-10.69-.16-15 12.55-15 12.55s-4.95-5.71-9.68-3.11-7.43 10.06-7.43 10.06-5.3 2.86-7.25 5.72zM394.14 132l64.42.1s-3.19-4.18-6.73-4.7c0 0-6.83-20.46-18.33-20.62-10.69-.16-15 12.55-15 12.55s-4.95-5.71-9.68-3.11-7.43 10.06-7.43 10.06-5.31 2.8-7.25 5.72z" />
      </g>
      <path
        fill="#263238"
        d="M45.51 357.18l82.13-.25 82.12-.09 164.24-.16 164.24.16 82.13.09 82.12.25-82.12.24-82.13.09-164.24.16-164.24-.16-82.12-.09-82.13-.24z"
      />
      <g fill="#ebebeb">
        <ellipse cx={327.51} cy={428.79} rx={271.54} ry={24.48} />
        <ellipse cx={517.42} cy={379.12} rx={190.55} ry={21.44} />
      </g>
      <path
        d="M445 373.92c2.31.69 67.65-1.47 70.09-3.13.93-.62 1.17-10.16 1.13-21.69v-.36c0-2.38-7.43-42.17-7.43-42.17l-43.72 1.31 9 41.78v.71s-24.64 12-27.13 14.13-4.2 8.73-1.94 9.42z"
        fill="#f7a9a0"
      />
      <path
        d="M515.12 370.79c-2.42 1.66-67.77 3.83-70.07 3.13s-.51-7.32 1.95-9.42c1.4-1.19 9.81-5.51 16.9-9.06-4.77 2.52-14.57 11.16 15.18 6.11 20.13-3.42 31.7-6.3 37.18-7.85-.04 9.37-.33 16.56-1.14 17.09z"
        fill="#263238"
      />
      <path
        d="M465.25 348.79l55.81-4.65s-16.9-67.32-13.86-71.87c1.33-2 52.5 7.46 71.93-13.58 17.77-19.25 0-58.11 0-58.11h-48s.51 16.88-2.42 23.06c-1.26 2.65-59-2.41-68.55 15-12.7 23.25 5.09 110.15 5.09 110.15z"
        fill="#37474f"
      />
      <path
        d="M514.45 333.39c-6.15.29-12.33.53-18.48 1s-12.32 1-18.46 1.73l-5.2.61c-.83.1-1.65.2-2.48.32a20.8 20.8 0 00-2.49.39c-.09 0-.06.14 0 .15a33.85 33.85 0 004.72-.15l4.46-.25q4.59-.27 9.18-.6c6.13-.44 12.23-1.07 18.34-1.73l10.4-1.15c.22-.03.22-.33.01-.32zM573.74 210.37c-.17-1.61-.3-3.23-.37-4.85a.1.1 0 00-.19 0c0 12.29 4.27 24.7.93 36.89a23.67 23.67 0 01-9.18 13.21 36.28 36.28 0 01-15.83 5.87c-6.43.93-13 .75-19.45.71l-20.19-.1h-5a39.11 39.11 0 00-4.72 0 2.53 2.53 0 00-2.38 2 17.35 17.35 0 00-.06 4.32q.29 9.74 1.38 19.41a237.67 237.67 0 007.48 38c.44 1.54.9 3.08 1.37 4.61a.15.15 0 00.29-.09 267.86 267.86 0 01-7.82-38.27c-.82-6.43-1.38-12.9-1.73-19.38q-.13-2.4-.21-4.8c0-.78-.06-1.57-.07-2.35a3.6 3.6 0 01.35-1.94 2.25 2.25 0 012-.79H507.98l20.19.13c6.47 0 13 .25 19.42-.5a39.93 39.93 0 0016-5 24.54 24.54 0 0010.3-11.57c4.76-11.41 1.11-23.75-.15-35.51z"
        fill="#263238"
      />
      <path
        d="M579.13 258.69c-12 13-36.25 14.36-53.25 14l19.45-72.07h33.8s17.77 38.82 0 58.07z"
        fill="#263238"
      />
      <path
        d="M677.93 371.13c-.26-2.39-27.78-61.7-30.26-63.29-.93-.62-9.81 2.89-20.41 7.43l-.33.15c-2.19.93-42.53 11.77-42.53 11.77L602.28 366l42-12.12.64-.32s20.68 18 23.6 19.45 9.69.51 9.41-1.88z"
        fill="#f7a9a0"
      />
      <path
        d="M632.54 313c8.14-3.39 14.36-5.71 15.13-5.19 2.49 1.59 30 60.89 30.26 63.28s-6.53 3.33-9.43 1.88c-1.77-.89-10.15-7.92-16.43-13.27 4.49 3.52 14.29 7.83-.38-16.89-9.05-15.22-15.41-24.59-19.15-29.81z"
        fill="#263238"
      />
      <path
        d="M531.46 200.58s.74 23.25 4.23 34.7c0 0 3.8 2 4.94 2.71 0 0-28.88 69.15-27.24 95.76.54 8.72 10.42 23.05 20.4 29.26 17.66 11 103.84 1.35 103.84 1.35l-12.5-51.82s-54.49 3-55.87.09c-1.75-3.74 9.1-32.83 17.77-56.46.87-2.39 9.59-11.19 8.57-27.13a82.61 82.61 0 00-7.5-28.46z"
        fill="#37474f"
      />
      <path
        d="M540.53 229.86c-.11-1.65-.23-3.3-.38-4.94-.33-3.75-.72-7.51-1.38-11.22 0-.29-.46-.18-.43.1.25 2.91.58 5.82.86 8.73s.62 5.81.67 8.71c0 .87 0 2.35-1.12 2.59-1.44.33-2.12-1.57-2.46-2.6a47.81 47.81 0 01-1.81-7.94c-.48-3-.73-6.15-1-9.23 0-.11-.2-.09-.2 0 0 6.11.07 12.5 2.35 18.27a3.4 3.4 0 002.53 2.41h.06c.06 0 0 .07 0 .11s.07.34.1.51a3 3 0 00.09.43c.06.3.1.6.17.89a.23.23 0 00.45 0 .18.18 0 00.12-.18 5 5 0 00-.39-1.73 2.15 2.15 0 001.57-1.33 7.6 7.6 0 00.2-3.58z"
        fill="#263238"
      />
      <path
        d="M536.13 235.3a87.86 87.86 0 0113.45 7c.17.1 0 .37-.14.28-4.54-2.21-9.18-4.33-13.59-6.79a.3.3 0 01.28-.49zM574.66 224.67a21.8 21.8 0 01-3.52-.28c-1-.19-2.06-.48-3.06-.77-1.89-.56-4.19-1.22-5.57-2.72a19.86 19.86 0 01-3.16-5.44 22.31 22.31 0 01-1.86-6.5.08.08 0 10-.15 0 33.33 33.33 0 001.15 6.87 23.51 23.51 0 001.29 3.26 11.6 11.6 0 001.83 2.91c1.52 1.65 4 2.45 6.15 2.88a19.27 19.27 0 006.94 0c.15.02.1-.21-.04-.21zM611 318.58c-11.88-.22-17.41.51-29.3.52h-13.45a13.46 13.46 0 01-3.1-.28 3.88 3.88 0 01-2.39-1.42c-.79-1.18-.37-2.68-.09-4 .59-2.79 1.27-5.57 2-8.33 3-11.23 6.9-22.2 10.58-33.22l5.68-17c.47-1.38.93-2.76 1.51-4.1a32.63 32.63 0 011.87-3.56 33.68 33.68 0 003.22-7c3.22-10.57.13-21.51-3.63-31.48-.51-1.34-1-2.67-1.58-4 0-.09-.18 0-.14.05 3.42 10.53 7.51 21.48 5.21 32.71a27.1 27.1 0 01-2.75 7.56c-.66 1.21-1.38 2.38-2 3.59a29.52 29.52 0 00-1.66 3.83c-2 5.58-3.8 11.25-5.68 16.88-3.76 11.18-7.71 22.34-10.84 33.72-.78 2.83-1.5 5.68-2.14 8.55-.47 2.12-1.53 5 .31 6.75 1.35 1.28 3.48 1.44 5.24 1.45h8.89c12.06 0 32.79-.76 34.28-.79a.21.21 0 00-.04-.43zM628.48 362c-1.17-6.05-2.28-12.13-3.62-18.15s-2.8-12.05-4.36-18c-.44-1.69-.9-3.37-1.35-5.06q-.31-1.2-.66-2.4a22.53 22.53 0 00-.74-2.42c0-.08-.15 0-.15.05a35 35 0 00.82 4.64c.3 1.47.58 2.93.88 4.39q.93 4.5 1.91 9c1.31 6 2.81 12 4.33 17.91q1.31 5.07 2.63 10.13c.05.1.35.06.31-.09zM501.25 175.55l8.19.21a25.16 25.16 0 002.63-15c-.14-1.19 2.65 5.61 1.53 15.14 8.65.22 20.41.51 30.69.66.2-4 .57-7.72.57-7.72a45.18 45.18 0 001.66 7.76l4.11.06a155.83 155.83 0 01.77-17.09 54.82 54.82 0 005.07 17.14c6.52 0 10.82-.07 10.54-.38-9.86-11.33-2.63-17.84-5.42-27.81-3.36-11.94-13.65-13.85-13.34-22.82.24-7.3 1.16-20.54-8.06-23.38a17.76 17.76 0 00-19.27-3.71c-14.58 5.56-8.83 28.08-9.83 32.66-1.42 6.53-4.54 8.84-6.87 16.29s1 12.56 1.44 17.68-6.05 10.3-4.41 10.31z"
        fill="#263238"
      />
      <path
        d="M567.3 174.67a25.7 25.7 0 01-3.16-6.39c-.74-2.22-.42-4.5 0-6.85a71.31 71.31 0 001.2-7.21 18.88 18.88 0 00-.71-7.52 16.68 16.68 0 00-3.92-6.48 44.41 44.41 0 00-5.6-4.76 27 27 0 01-5.18-4.81 13.13 13.13 0 01-2.61-6.52 12.26 12.26 0 001.88 7 25.09 25.09 0 005 5.42 54.91 54.91 0 015.21 4.84 15.88 15.88 0 013.4 5.87 18.85 18.85 0 01.68 6.8c-.14 2.33-.58 4.68-.89 7.1a24.06 24.06 0 00-.24 3.72 10.65 10.65 0 00.82 3.71 20.58 20.58 0 004.12 6.08zM512.67 106.93a36.58 36.58 0 00-2 8.71c-.37 3-.6 5.93-.8 8.89l-.32 4.42a22.26 22.26 0 01-.67 4.22 23.87 23.87 0 01-4 7.54 33.65 33.65 0 00-2.38 3.89 20.63 20.63 0 00-1.63 4.36 29.65 29.65 0 00-.64 4.55 18.74 18.74 0 00.24 4.61c.27 1.53.69 3 1.05 4.38a22.31 22.31 0 01.68 4.28 9.13 9.13 0 01-3.59 7.53 9.25 9.25 0 004.51-7.54 22.11 22.11 0 00-.37-4.53c-.26-1.49-.63-2.92-.79-4.34a23.41 23.41 0 01.68-8.49 20.77 20.77 0 011.54-3.94 31.42 31.42 0 012.3-3.67c.83-1.22 1.69-2.47 2.43-3.83a18.66 18.66 0 001.73-4.33 23.46 23.46 0 00.59-4.58c.08-1.49.11-3 .14-4.46.06-3 .09-5.93.23-8.88a43.44 43.44 0 011.07-8.79z"
        fill="#263238"
      />
      <path
        d="M540.6 150.19c.15 16.42-2.6 43.42-9.61 55-5.59 9.28-31.43 24-45.35 27.84-4.91 1.35-7.36-19.09-5-24.1 1.45-3 30.32-14.79 33-18.8 4.07-6.08 9.62-26.1 15.71-40.88 3.26-7.75 11.16-9.25 11.25.94z"
        fill="#f7a9a0"
      />
      <path
        d="M489.28 204.29s-7.71-.93-11.19-.59-16 6.37-14.81 9.14c1.48 3.58 9.38 1.52 13.11 0 0 0 3.43 8.28 9.42 8s3.47-16.55 3.47-16.55z"
        fill="#f7a9a0"
      />
      <path
        d="M490.21 231.32c-2.72 1.22-15.66 5.7-19.55 6.14s-15.14.89-15.78-2 2.65-3.34 2.65-3.34-4.79-.56-5.16-3.58 4.76-3.46 4.76-3.46-4.87-.94-5-4 5.08-2.62 5.08-2.62-4.5-.65-3.57-3.21c1.11-3.07 9.26-.94 13.51-1.75 1-.18 19.29-5.11 19.29-5.11z"
        fill="#f7a9a0"
      />
      <path
        d="M499.66 227.52a88.38 88.38 0 01-14 5.53c-5 1.77-12.24 4.09-15 4.4-3.88.44-15.14.89-15.78-2s2.65-3.34 2.65-3.34-4.79-.56-5.16-3.58c-.29-2.34 2.74-3.13 4.11-3.37-.08-.09-.05-.21.1-.23-1.27-.34-4.35-1.43-4.48-3.83s3-2.64 4.43-2.64a.21.21 0 01.18 0h.45s-4.5-.65-3.57-3.21c.86-2.39 6-1.63 10.23-1.56a2.63 2.63 0 01-.56-.84c-1.15-2.77 11.34-8.8 14.81-9.14a66 66 0 0110.16.48c1-.52 2.05-1.05 3.14-1.6z"
        fill="#dbdbdb"
      />
      <path
        d="M456.73 218.42c6 1.55 8.89 1.19 14.7-.67.11 0 .19.12.08.17-5.52 2.34-9.09 3.32-14.87.79-.28-.13-.2-.36.09-.29zM456.66 224.91c6.37 1.06 9.51.7 15.73-.8.09 0 .14.12.05.15-6 2-9.56 2.88-15.79 1-.28-.11-.29-.4.01-.35zM457.84 232c6.25.59 9.71.34 15.57-1.9a.11.11 0 01.09.21c-5.75 2.87-10.07 3.21-15.65 1.88-.38-.1-.4-.19-.01-.19zM464 213.69c3.71 2.29 8.48 0 12.21-1.22a.37.37 0 01.49.34c1.67 3.38 5 7.65 8.93 8.24a.09.09 0 010 .18c-4.3-.06-7.92-4-9.51-7.81-3.81 1.29-8.7 3.4-12.14.31.02-.02.02-.05.02-.04z"
        fill="#263238"
      />
      <path
        d="M517.11 175.63l23.81 3.29s1.36-18.24.71-27.83-6.24-15-13.39-2.16c-6.07 10.87-11.13 26.7-11.13 26.7z"
        fill="#37474f"
      />
      <path
        d="M526 191.25l.78 33.81s53.9-8.29 67.09-11.49c0 0-21.31-59.84-38.3-73-8.57-6.57-16.87-4.57-21.32 1.78-1.13 1.61-13.72 25.79-14 34.26-.32 7.48 5.75 14.64 5.75 14.64z"
        fill="#202b37"
      />
      <path fill="#fff" d="M542.191 163.747l2.03-.281 1.262 9.113-2.03.28z" />
      <path fill="#fff" d="M548.243 166.521l.281 2.03-9.113 1.262-.28-2.03z" />
      <path
        d="M547.18 139c0 1.1-3.67 8.93-8.52 9.68-1.58.24-3.27-8.33-3.27-8.33l-.27-.6-3.77-8.45 9-8.77 1.33-1.19s.88 2.38 1.94 5.36c.05.14.1.27.15.42s.13.32.18.48c.17.44.33.9.49 1.35s.24.68.35 1.05.24.72.36 1.09a59.7 59.7 0 012.03 7.91z"
        fill="#f7a9a0"
      />
      <path
        d="M543.66 126.7a18.58 18.58 0 01-8.54 13l-3.77-8.45 9-8.77 1.33-1.19s.92 2.43 1.98 5.41z"
        fill="#263238"
      />
      <path
        d="M514.85 123c5.06 15.42 14.87 14.13 18.2 13 3-1 13.17-4.77 9.73-20.64S530.56 97 523.54 98.94s-13.76 8.65-8.69 24.06z"
        fill="#f7a9a0"
      />
      <path
        d="M516.6 118.45c.35-.16.64-.37 1-.56a1.82 1.82 0 00.88-.76.58.58 0 00-.22-.67 1.43 1.43 0 00-1.45.12 2.11 2.11 0 00-1 1.12.62.62 0 00.79.75zM526.8 115.22a10.19 10.19 0 01-1.11.13 1.77 1.77 0 01-1.16-.1.58.58 0 01-.22-.67 1.42 1.42 0 011.24-.75 2.08 2.08 0 011.48.29.61.61 0 01-.23 1.1zM526 119.79s.1 0 .11.07c.29 1.07.8 2.24 1.86 2.31v.06c-1.19.19-1.85-1.38-1.97-2.44z"
        fill="#263238"
      />
      <path
        d="M526.63 118.4c1.67-.64 2.83 2.73 1.29 3.33s-2.68-2.79-1.29-3.33z"
        fill="#263238"
      />
      <path
        d="M527.36 118.35c.36.09.76.33 1.13.24s.61-.56.77-1h.07a1.31 1.31 0 01-.45 1.73c-.6.31-1.25-.11-1.64-.7-.07-.09-.03-.31.12-.27zM519.89 121.63s-.06.08 0 .12c.41 1 .69 2.28-.13 3v.05c1.07-.6.68-2.26.13-3.17z"
        fill="#263238"
      />
      <path
        d="M518.58 120.87c-1.73.47-.67 3.88.93 3.44s.49-3.83-.93-3.44z"
        fill="#263238"
      />
      <path
        d="M517.94 121.32c-.22.26-.38.67-.7.79s-.75-.14-1.1-.42c0 0-.06 0 0 0 .18.66.61 1.27 1.27 1.2s.85-.75.81-1.45c-.05-.08-.22-.23-.28-.12zM528.56 128.29c-.15.34-.26.77-.62 1a2.92 2.92 0 01-1.21.17v.07a1.48 1.48 0 001.52.05 1.16 1.16 0 00.45-1.23c-.03-.12-.12-.11-.14-.06zM522.92 126a24.39 24.39 0 00.55 2.38c0 .07.21 0 .46 0a3.7 3.7 0 003-2.45c0-.09-.09-.13-.14-.07a5.56 5.56 0 01-2.91 2c-.12-.19-.42-2.83-.54-2.8a6.62 6.62 0 00-1.55.94c-.74-3.39-.92-6.88-1.69-10.25a.11.11 0 00-.21 0 55.89 55.89 0 001.35 11.12c.09.37 1.42-.69 1.68-.87z"
        fill="#263238"
      />
      <path
        d="M541.53 116.1c-3.69 1.48-9.7-9.24-10.42-10.56.41.86 2.58 5.41 2.31 5.74a3.64 3.64 0 01-1.24.29 11.27 11.27 0 00-.86-1.59c-.14-.25.15.59.45 1.65l-.76.11a14.68 14.68 0 00-1.89-3.35c-.3-.38.45 1.62.79 3.52a47.7 47.7 0 00-10.27 2.53c-.93.37-1.71.72-2.38 1.06a27.09 27.09 0 00-1.06-2.52 15.36 15.36 0 00.55 2.78l-1 .54c-.94-1.88-1.38-5.35-1.48-4.75a14.82 14.82 0 00.23 5.55c-1.19.84-1.44 1.25-1.63.93a17.55 17.55 0 01.48-12.08c2.66-6.3 11.26-11.39 19.29-7 0 0 5.43.89 8 5s3.03 11.29.89 12.15z"
        fill="#263238"
      />
      <path
        d="M537.71 117.6s1-6.11 3.59-6 3.43 7.91 1.44 10a2.73 2.73 0 01-4 .28z"
        fill="#f7a9a0"
      />
      <path
        d="M541.48 113.86s.07 0 0 .06c-1.37 1.51-1.5 3.53-1.34 5.47a1.51 1.51 0 011.86-1.36c.06 0 .05.1 0 .11a1.68 1.68 0 00-1.41 1.34 7.82 7.82 0 00-.15 1.74c0 .19-.32.25-.35 0-1.09-2.15-.83-6.01 1.39-7.36z"
        fill="#263238"
      />
      <path
        d="M538.68 129.71c2.06-2 4.07-4.24 4.8-7.18a14 14 0 00.27-6.5 10.79 10.79 0 01-.08 2.66 7.48 7.48 0 01-.88 2.6 14.73 14.73 0 01-4.36 8c-.23.24.04.61.25.42zM537.71 119.24c.24-.72.54-4.06 1.37-5.58q.18-.39.39-.75a3 3 0 01.91-1 2 2 0 00-1.12.31c-1.75 1.06-1.61 5.31-1.77 7 0 .07.18.15.22.02z"
        fill="#ebebeb"
      />
      <path
        d="M537.89 118.44c.05-.82-4.12 3.53-9.83 4.42-1.17.18-4.64.1-6.33.73s-3.14 2.73-5.37 3.85c-.76.39 2.5 7 5.66 9.26 1 .74 4.65 1.7 6.45 1.23 4.25-1.11 10.15-6.65 10.71-8.41.44-1.41-1.81-3.27-1.29-11.08z"
        fill="#ebebeb"
      />
      <path
        d="M517.48 128.74a17.58 17.58 0 014.4-3.38 19.9 19.9 0 014.51-.65 18.52 18.52 0 009.76-3.74s.08 0 0 .07a15.08 15.08 0 01-8.62 3.89c-1.73.23-4 .13-5.56.85a23.22 23.22 0 00-4.43 3.11c-.09.11-.18-.03-.06-.15zM523 135.73a30 30 0 004 .43c1.54-.06 3-1.12 4.22-1.94a83.41 83.41 0 006.58-5.34 35.52 35.52 0 01-7.51 6.27 7.49 7.49 0 01-3 1.3 15 15 0 01-4.21-.68l-.08-.04zM519.22 130.73a19.08 19.08 0 017.59-3.1 32.91 32.91 0 009-3.54 27.88 27.88 0 01-7.88 3.42c-1.35.36-2.73.59-4.07 1a16.3 16.3 0 00-4.52 2.33c-.1.1-.2-.05-.12-.11zM520.82 133.14a13.78 13.78 0 008.25-1.19 37 37 0 007.08-5.11s.07 0 0 0c-4.36 4.13-9.06 7.58-15.38 6.43-.08.03-.07-.15.05-.13z"
        fill="#dbdbdb"
      />
      <path fill="#202b37" d="M458.95 180.56h65.53v72.56h-65.53z" />
      <path opacity={0.1} d="M458.95 180.56h65.53v72.56h-65.53z" />
      <path fill="#202b37" d="M458.95 253.13h-33.83v-72.56h33.83z" />
      <path
        fill="#fff"
        d="M486.15 196.24H488.58l.6-1.21.83 1.21 1.13-2.48 1.07 2.48h4.47l.51-1.66.47 1.66h.91v-15.68h-12.42v15.68z"
      />
      <g opacity={0.1}>
        <path d="M427.45 248.13q3.75-7.74 7.45-15.53l7.34-15.6 7.24-15.67q3.61-7.86 7.14-15.76-3.76 7.74-7.45 15.53l-7.34 15.61-7.25 15.67q-3.58 7.85-7.13 15.75z" />
        <path d="M427.45 185.56q3.53 7.91 7.13 15.75l7.25 15.69 7.34 15.61q3.69 7.78 7.45 15.53-3.54-7.9-7.14-15.76l-7.24-15.67-7.34-15.61q-3.69-7.79-7.45-15.54z" />
      </g>
      <path
        fill="#202b37"
        d="M535.35 196.25h-65.52l-10.87-15.69h65.52l10.87 15.69z"
      />
      <path
        d="M535.35 196.25h-65.52l-10.87-15.69h65.52l10.87 15.69z"
        opacity={0.2}
      />
      <path
        fill="#202b37"
        d="M448.08 196.26h-33.73l10.87-15.7h33.74l-10.88 15.7z"
      />
      <path
        opacity={0.1}
        d="M448.08 196.26h-33.73l10.87-15.7h33.74l-10.88 15.7z"
      />
      <path
        d="M479 230.28v15h26.7v-15c0-4.53-.91-15.46-9-18.3a12.94 12.94 0 00-4.31-.69c-12.16 0-13.39 13.71-13.39 18.99z"
        fill="#fff"
      />
      <path
        d="M479 242.13v3.14h26.7v-15c0-4.53-.91-15.46-9-18.3 4.67 8.18 5.47 18.31-2.08 25.36-4.68 4.37-10.1 5.4-15.62 4.8z"
        style={{
          mixBlendMode: "multiply",
        }}
        fill="#ebebeb"
      />
      <path
        d="M478.25 247.2h28.22a1.37 1.37 0 001.37-1.37 1.36 1.36 0 00-1.37-1.36h-28.22a1.36 1.36 0 00-1.36 1.36 1.36 1.36 0 001.36 1.37zM496.58 214.68h-8.42v-6.45h8.42z"
        fill="#fff"
      />
      <path fill="#455a64" d="M497.56 209.29h-10.39v-4.17h10.39z" />
      <path fill="#455a64" d="M494.26 205.83h-3.79v-4.8h3.79z" />
      <path
        d="M496.36 199.32a1.8 1.8 0 01-.11 3.15c-1.29.76-8.12-.91-8.84-.83s-2.43.83-2.65.83-1-.38-.95-.72 2.35-2.05 4.13-2.46 6.97-1.09 8.42.03z"
        fill="#455a64"
      />
      <path
        d="M561 150.57c6.7 14.93 18.28 46.46 14.48 58-5 15.11-27.93 30.79-34.7 35.46-3 2-17.63-16.78-16.73-20.51s24.92-18.91 26.4-21.36-1.25-33.86-2.31-47.71c-1.38-17.45 7.14-16.51 12.86-3.88z"
        fill="#f7a9a0"
      />
      <path
        d="M517.22 233.58c.8-1.65-.06-2.08-3-.66s-13.28 9.53-15.8 5.67 12.26-15.07 17.3-17.06 13.91-3.45 13.91-3.45c-2.42 2.76-7.7 10.02-12.41 15.5z"
        fill="#f7a9a0"
      />
      <path
        d="M547.88 238.15c-7.15 7.53-15.6 17.33-26.86 20.55a5.81 5.81 0 01-3.79-.11c-5 1.53-7.26-.38-7.26-.38-5.11 0-7.12-2.27-7.12-2.27s-5.92 1.74-7.56-1.66c-1.86-3.81 6.81-5.5 11-9.35 9.62-8.81 20.83-24 23.35-26.85z"
        fill="#f7a9a0"
      />
      <path
        d="M550.17 237.21c-1.65 1.26-3.21 2.4-4.61 3.41-6.65 7.06-14.47 15.2-24.54 18.08a5.93 5.93 0 01-3.74-.09c-5 1.53-7.25-.38-7.25-.38-4.6 0-6.69-1.84-7.06-2.21a.09.09 0 01-.06-.06s-5.92 1.74-7.56-1.66c-1.86-3.81 6.81-5.5 11-9.35a137.79 137.79 0 0011.1-11.8c.43-1.27-.53-1.49-3.16-.21-3 1.43-13.28 9.53-15.8 5.67s12.26-15.07 17.3-17.06c4.4-1.76 11.73-3.08 13.51-3.38.73-.59 1.51-1.2 2.33-1.82z"
        fill="#dbdbdb"
      />
      <path
        d="M522.87 242.78A83.24 83.24 0 01514 250a84.26 84.26 0 01-11.11 5.79.1.1 0 00.07.19 44.42 44.42 0 0020-13.09c.15-.12.04-.24-.09-.11zM529 247.81c-2.94 2.31-8.14 6.35-19.11 10.29v.07A39.91 39.91 0 00529.15 248c.17-.21.08-.34-.15-.19zM532.3 250.58c-5.15 3.74-8.88 5.45-15.06 8v.06c7.33-2.13 9.75-3.74 15.19-7.88.21-.17.07-.33-.13-.18z"
        fill="#263238"
      />
      <path
        d="M571.74 173.94l-23.8 3.38s-1.43-18.23-.81-27.82 6.19-15 13.38-2.22c6.11 10.86 11.23 26.66 11.23 26.66z"
        fill="#37474f"
      />
      <g>
        <path
          fill="#202b37"
          d="M328.57 333.6h82.57v78.13h-82.57zM411.14 333.6h37.47v78.14h-37.47z"
        />
        <path opacity={0.1} d="M411.14 333.6h37.47v78.14h-37.47z" />
        <path
          d="M446 406.36q-4.17-8.34-8.25-16.72l-8.13-16.81-8-16.88q-4-8.44-7.91-17 4.17 8.33 8.25 16.72l8.13 16.8 8 16.88q4.03 8.5 7.91 17.01z"
          fill="#202b37"
        />
        <path
          d="M446 339q-3.91 8.5-7.9 17l-8 16.88-8.1 16.76q-4.08 8.39-8.25 16.72 3.91-8.52 7.91-17l8-16.88 8.13-16.8q4.07-8.37 8.21-16.68z"
          fill="#202b37"
        />
        <path
          d="M356.51 387.75v15h26.69v-15c0-4.53-.9-15.46-9-18.3a13 13 0 00-4.32-.69c-12.16 0-13.37 13.75-13.37 18.99z"
          fill="#fff"
        />
        <path
          d="M356.51 399.6v3.14h26.69v-15c0-4.53-.9-15.46-9-18.3 4.66 8.18 5.46 18.31-2.08 25.36-4.68 4.37-10.12 5.4-15.61 4.8z"
          style={{
            mixBlendMode: "multiply",
          }}
          fill="#ebebeb"
        />
        <path
          d="M355.75 404.67H384a1.36 1.36 0 001.36-1.37 1.36 1.36 0 00-1.36-1.36h-28.25a1.36 1.36 0 00-1.37 1.36 1.37 1.37 0 001.37 1.37zM374.07 372.16h-8.42v-6.45h8.42z"
          fill="#fff"
        />
        <path fill="#455a64" d="M375.06 366.77h-10.39v-4.17h10.39z" />
        <path fill="#455a64" d="M371.76 363.3h-3.79v-4.8h3.79z" />
        <path
          d="M373.86 356.79a1.8 1.8 0 01-.12 3.15c-1.29.76-8.11-.91-8.83-.84s-2.43.84-2.66.84-1-.38-.95-.72 2.35-2.05 4.14-2.47 6.96-1.08 8.42.04z"
          fill="#455a64"
        />
        <path
          fill="#fff"
          d="M376.55 350.49h-2.62l-.64-1.3-.9 1.3-1.22-2.68-1.15 2.68H365.21l-.55-1.79-.51 1.79h-.98V333.6h13.38v16.89z"
        />
        <path
          fill="#202b37"
          d="M437.75 333.6h70.55v78.13h-70.55zM508.3 333.6h36.43v78.13H508.3z"
        />
        <path opacity={0.1} d="M508.3 333.6h36.43v78.13H508.3z" />
        <path
          fill="#fff"
          d="M479.01 350.49h-2.62l-.64-1.3-.89 1.3-1.22-2.68-1.16 2.68h-4.81l-.54-1.79-.51 1.79h-.99V333.6h13.38v16.89z"
        />
        <path
          d="M542.22 406.35q-4.05-8.33-8-16.72l-7.9-16.8-7.83-16.83q-3.87-8.46-7.68-17 4.05 8.33 8 16.72l7.9 16.8 7.81 16.88q3.89 8.45 7.7 16.95z"
          fill="#202b37"
        />
        <path
          d="M542.22 339q-3.81 8.5-7.68 17l-7.81 16.88-7.9 16.8q-4 8.4-8 16.72 3.81-8.5 7.68-17l7.81-16.88 7.9-16.8q3.95-8.41 8-16.72z"
          fill="#202b37"
        />
        <path
          d="M479.09 368.92c.33.71 5.07 7.44 6.31 9.81s2.09 7 2.2 7.18 5.82 6 7 7.09l.19.16h.27c.42.11 1.13.36 1 .91a11.91 11.91 0 01-4.75 5.85c-3.3 2.27-6.5 2.81-7.14 2.64s-.68-.69-.81-1.11-6.65-8.68-7.3-8.42c-.38.15-3-.74-4.3-1.3s-8.46-5-9.13-5.2-7.67-.14-7.71-1.42 2.64-2.55 5.14-2.88c1.66-.22 5.19.66 6 .14s.23-2.66-.19-3.79-13.67-15.8-11.58-17.5a.66.66 0 01.4-.15h.19c2.64.39 9.48 10.67 11.18 11.15a.3.3 0 00.25 0 .16.16 0 00.06-.08c.64-1.34-11-13.87-8.64-15.62a1.13 1.13 0 011.2.08c3.06 1.57 9.16 11.08 11.14 12.86.26.24.45.34.56.27s.09-.19 0-.39c-.45-2.2-7.81-12.4-6-13.56a.5.5 0 01.27-.08 1.23 1.23 0 01.61.22c2.25 1.39 6.81 9.2 8.69 11.9.43.61.72 1 .81.9.44-.51-5.2-10.37-2.92-11.14a.8.8 0 01.31 0 1.46 1.46 0 01.71.33c2.44 1.84 5.7 10.54 5.98 11.15z"
          fill="#455a64"
        />
        <path
          fill="#202b37"
          d="M394.55 255.47h70.55v78.13h-70.55zM465.1 255.47h36.43v78.13H465.1z"
        />
        <path opacity={0.1} d="M465.1 255.47h36.43v78.13H465.1z" />
        <path
          fill="#fff"
          d="M435.81 272.36h-2.62l-.64-1.3-.89 1.3-1.22-2.68-1.16 2.68h-4.81l-.54-1.79-.51 1.79h-.98v-16.89h13.37v16.89z"
        />
        <path
          d="M499 328.22q-4-8.33-8-16.72l-7.9-16.8-7.81-16.88q-3.87-8.46-7.68-17 4 8.33 8 16.72l7.9 16.8 7.81 16.88q3.89 8.5 7.68 17z"
          fill="#202b37"
        />
        <path
          d="M499 260.86q-3.81 8.5-7.68 17l-7.81 16.88-7.9 16.8q-4 8.4-8 16.72 3.81-8.5 7.68-17l7.81-16.88 7.9-16.8q4-8.4 8-16.72zM382.83 272.37h70.55l11.71-16.9h-70.54l-11.72 16.9z"
          fill="#202b37"
        />
        <path
          opacity={0.1}
          d="M382.83 272.37h70.55l11.71-16.9h-70.54l-11.72 16.9z"
        />
        <path
          fill="#202b37"
          d="M476.8 272.37h36.33l-11.71-16.9h-36.33l11.71 16.9z"
        />
        <path
          d="M422.44 297.78c-2.64-.24-5.25-1.43-7.83-2-2-.44-5.23-1.26-6.91.33a6.34 6.34 0 00-1.42 3.63 10.77 10.77 0 00.48 4.94c1.18 3.45 4 5.26 7.25 6.63 2.83 1.21 5.82 1.87 8.68 2.95.56.22.81-.69.25-.9-2.05-.78-4.22-1.26-6.28-2-3-1.09-6.5-2.4-8.23-5.26a9.55 9.55 0 01-1.31-4.84 7.2 7.2 0 011.06-4.28c1.38-1.7 5.15-.54 6.87-.13 2.43.58 4.89 1.65 7.39 1.87.6.06.6-.88 0-.93zM435.65 295.38a8.45 8.45 0 01-2.74-3.48c-.35-.94-.4-2.3.51-2.95a3.65 3.65 0 012.41-.36 22.79 22.79 0 016.69 2 52.08 52.08 0 016.37 3.22.47.47 0 00.48-.81 51.87 51.87 0 00-7.79-3.79c-2.21-.85-4.86-1.86-7.27-1.51a3 3 0 00-2.46 4A8.63 8.63 0 00435 296c.47.37 1.14-.29.67-.66z"
          fill="#fff"
        />
        <path
          d="M421.38 298c-.28.45 1.83 4.66 1.25 8s-1.13 7.52-.6 8.08 4.16 1.5 6 2.13 7.69 3.37 14.38 3.37 8.44-6.06 8.81-6.56a13.32 13.32 0 001.75-2.81c.38-1-.68-1.75-.6-3.13s1.17-.83 1.12-3.44-3.14-8.08-3.33-8.35-.1-1.71-.23-2-5.56-2.46-9.31-1.8-11.25 3.92-13.46 4.71-5.45 1.23-5.78 1.8z"
          fill="#fff"
        />
        <path
          d="M423.53 299.29a17.39 17.39 0 011.22 6.65 20.5 20.5 0 01-1.4 6.61c0 .05.06.08.08 0 2.25-3.86 2.03-9.26.1-13.26zM449.67 314.94a21.81 21.81 0 01-13.06 1.28c-2.1-.43-4.14-1.12-6.19-1.73a34.33 34.33 0 00-6.18-1.31v.05c4.47.64 8.61 2.72 13.05 3.48a21.31 21.31 0 0012.42-1.7c.05-.01.01-.09-.04-.07zM449.84 295.21a15.55 15.55 0 00-5.75-1.22 19.6 19.6 0 00-6.21 1c-4.45 1.3-8.88 2.72-13.32 4.06 0 0 0 .05 0 0 4.27-1.21 8.56-2.36 12.82-3.59a24.64 24.64 0 016.25-1.17 19.32 19.32 0 016.17 1c.06.03.1-.06.04-.08zM452.38 310.38a28.73 28.73 0 01-12.45 2.62 37.41 37.41 0 01-6.42-.6 48.33 48.33 0 00-6.32-1.12c2.34.23 4.59 1 6.9 1.44a32.9 32.9 0 006.27.52 27.6 27.6 0 0012.06-2.83c.05.02.01-.05-.04-.03zM451.66 307.79a38.14 38.14 0 01-11.66 2.49c-4.09.34-8.06-.76-12.13-.83a55.21 55.21 0 016.52.89 24.59 24.59 0 006.12.21c3.8-.37 7.79-1 11.2-2.68.09-.02.02-.11-.05-.08zM452.51 306.11c-2-.14-4-.64-6-.87a28.07 28.07 0 00-6.28.17 91.4 91.4 0 00-12.23 2.18v.06a102 102 0 0112.52-1.89 33.88 33.88 0 016-.16c2 .18 4 .75 6 .66a.08.08 0 10-.01-.15zM452 302.42a40.19 40.19 0 00-12.7-.9 31.75 31.75 0 00-11.41 3.3c7.47-3.68 16.07-3.72 24.07-2.29.04.02.04-.1.04-.11zM450.55 299.16c-2 0-3.89-.62-5.86-.91a20.37 20.37 0 00-5.57 0 37.25 37.25 0 00-11.06 3.59c3.62-1.33 7.15-2.83 11-3.37a20.59 20.59 0 016 0 29.74 29.74 0 005.46.74c.1.05.1-.05.03-.05z"
          fill="#ebebeb"
        />
        <path fill="#dbdbdb" d="M185.26 333.61h70.55v78.13h-70.55z" />
        <path fill="#c7c7c7" d="M255.81 333.61h36.43v78.13h-36.43z" />
        <path
          fill="#fff"
          d="M226.52 350.49H223.91l-.64-1.3-.9 1.3-1.22-2.68-1.15 2.68H215.19l-.55-1.79-.51 1.79h-.98v-16.88h13.37v16.88z"
        />
        <path
          d="M289.73 406.36q-4-8.34-8-16.72l-7.9-16.81L266 356q-3.88-8.44-7.69-17 4.05 8.33 8 16.72l7.91 16.8 7.8 16.88q3.91 8.45 7.71 16.96z"
          fill="#dbdbdb"
        />
        <path
          d="M289.73 339q-3.8 8.5-7.68 17l-7.8 16.88-7.91 16.81q-4 8.39-8 16.72 3.81-8.52 7.69-17l7.8-16.88 7.9-16.8q3.96-8.4 8-16.73z"
          fill="#dbdbdb"
        />
        <path
          d="M213.15 373.32c-2.64-.24-5.25-1.43-7.83-2-2-.44-5.23-1.26-6.91.33a6.34 6.34 0 00-1.42 3.63 10.9 10.9 0 00.48 4.94c1.19 3.45 4.05 5.26 7.25 6.63 2.83 1.21 5.82 1.87 8.68 2.95.57.22.81-.69.25-.9-2.05-.78-4.21-1.26-6.28-2-3-1.09-6.5-2.4-8.22-5.26a9.46 9.46 0 01-1.32-4.84 7.2 7.2 0 011.06-4.28c1.38-1.7 5.15-.54 6.87-.13 2.43.59 4.89 1.65 7.39 1.88.6.05.6-.89 0-.94zM226.36 370.92a8.55 8.55 0 01-2.74-3.48c-.35-.94-.4-2.3.51-2.95a3.67 3.67 0 012.42-.36 22.73 22.73 0 016.68 2 52.93 52.93 0 016.38 3.22.47.47 0 00.47-.81 51.4 51.4 0 00-7.79-3.79c-2.2-.85-4.86-1.86-7.27-1.51a3 3 0 00-2.46 4 8.59 8.59 0 003.14 4.41c.46.37 1.13-.28.66-.66z"
          fill="#fff"
        />
        <path
          d="M212.09 373.52c-.28.45 1.83 4.66 1.25 8s-1.12 7.52-.59 8.08 4.15 1.5 6 2.13 7.69 3.37 14.38 3.37 8.44-6.06 8.82-6.56a14.19 14.19 0 001.75-2.81c.37-1-.69-1.75-.61-3.13s1.17-.83 1.13-3.44-3.15-8.08-3.34-8.35-.1-1.71-.23-2-5.56-2.46-9.31-1.8-11.25 3.92-13.46 4.71-5.46 1.28-5.79 1.8z"
          fill="#fff"
        />
        <path
          d="M214.24 374.83a17.57 17.57 0 011.21 6.65 20.24 20.24 0 01-1.4 6.61c0 .05.06.08.09 0 2.25-3.86 2.03-9.26.1-13.26zM240.39 390.48a21.82 21.82 0 01-13.06 1.28c-2.11-.43-4.14-1.11-6.2-1.73a33.54 33.54 0 00-6.17-1.3c4.47.64 8.61 2.72 13.06 3.48a21.34 21.34 0 0012.42-1.7s-.01-.04-.05-.03zM240.56 370.75a15.6 15.6 0 00-5.76-1.22 19.93 19.93 0 00-6.21 1c-4.45 1.31-8.87 2.72-13.32 4.06 0 0 0 .05 0 0 4.26-1.21 8.55-2.36 12.81-3.59a24.73 24.73 0 016.25-1.17 19.32 19.32 0 016.17 1s.11-.06.06-.08zM243.1 385.92a28.8 28.8 0 01-12.46 2.63 37.2 37.2 0 01-6.41-.6 48.93 48.93 0 00-6.32-1.12c2.34.23 4.58 1 6.89 1.45a33.8 33.8 0 006.27.51 27.61 27.61 0 0012.07-2.79s0-.1-.04-.08zM242.37 383.33a38.08 38.08 0 01-11.65 2.49c-4.09.34-8.05-.76-12.13-.83a54.93 54.93 0 016.52.89 24.12 24.12 0 006.12.21c3.8-.37 7.79-1 11.21-2.68.07-.01 0-.11-.07-.08zM243.23 381.65c-2-.14-4-.64-6-.87a28.09 28.09 0 00-6.23.22 91.4 91.4 0 00-12.29 2.18v.06a102 102 0 0112.52-1.89 33.88 33.88 0 016-.16c2 .18 4 .75 6 .66.09-.05.09-.19 0-.2zM242.67 378a40.19 40.19 0 00-12.7-.9 31.75 31.75 0 00-11.41 3.3c7.47-3.68 16.07-3.72 24.07-2.29.09-.02.12-.11.04-.11zM241.27 374.7c-2 .05-3.9-.62-5.87-.9a19.61 19.61 0 00-5.57 0 36.94 36.94 0 00-11.06 3.58c3.62-1.33 7.15-2.83 11-3.37a20.65 20.65 0 016 0 29.51 29.51 0 005.46.74c.1.06.1-.05.04-.05z"
          fill="#ebebeb"
        />
        <path fill="#dbdbdb" d="M172.17 255.46h70.55v78.13h-70.55z" />
        <path fill="#c7c7c7" d="M242.72 255.47h36.43v78.13h-36.43z" />
        <path
          fill="#fff"
          d="M213.43 272.35H210.82l-.64-1.3-.9 1.3-1.22-2.67-1.15 2.67H202.1l-.55-1.78-.51 1.78h-.98v-16.88h13.37v16.88z"
        />
        <path
          d="M276.65 328.22q-4-8.34-8-16.72l-7.9-16.81-7.8-16.88q-3.88-8.44-7.69-17 4.05 8.34 8 16.72l7.9 16.81 7.8 16.88q3.89 8.49 7.69 17z"
          fill="#dbdbdb"
        />
        <path
          d="M276.65 260.85q-3.81 8.5-7.69 17l-7.8 16.88-7.9 16.81q-4 8.38-8 16.72 3.81-8.5 7.69-17l7.8-16.88 7.9-16.81q3.94-8.38 8-16.72z"
          fill="#dbdbdb"
        />
        <path
          fill="#c7c7c7"
          d="M160.46 272.36H231l11.72-16.9h-70.55l-11.71 16.9z"
        />
        <path
          fill="#dbdbdb"
          d="M254.43 272.37h36.32l-11.71-16.9h-36.32l11.71 16.9z"
        />
        <path
          d="M210.76 292.89c.33.71 5.07 7.44 6.31 9.81s2.09 7 2.2 7.18 5.82 6 7 7.09a.85.85 0 00.2.16l.27.06c.42.1 1.13.35 1 .9a11.74 11.74 0 01-4.75 5.85c-3.3 2.27-6.5 2.82-7.14 2.64s-.68-.69-.8-1.11-6.66-8.68-7.31-8.42c-.37.15-3-.74-4.29-1.3s-8.47-5-9.13-5.2-7.67-.14-7.72-1.42 2.65-2.54 5.14-2.88c1.66-.22 5.19.67 6 .14s.23-2.66-.2-3.79-13.66-15.8-11.54-17.5a.67.67 0 01.39-.15h.2c2.64.39 9.48 10.67 11.18 11.15a.28.28 0 00.24 0 .13.13 0 00.06-.07c.64-1.34-11-13.88-8.63-15.62a1.13 1.13 0 011.2.08c3.06 1.57 9.16 11.08 11.15 12.87.26.23.45.33.55.26s.1-.19.06-.39c-.46-2.2-7.82-12.4-6-13.56a.61.61 0 01.27-.08 1.23 1.23 0 01.6.22c2.26 1.39 6.81 9.21 8.69 11.9.43.62.72 1 .81.91.43-.51-5.21-10.37-2.93-11.14a.8.8 0 01.31 0 1.39 1.39 0 01.72.34c2.35 1.76 5.61 10.46 5.89 11.07z"
          fill="#a6a6a6"
        />
      </g>
      <g>
        <path
          d="M340.19 392v8.85c0 8.23-.07 15.45-.48 19.18V420a11.67 11.67 0 01-.6 2.55l-.05.05c-2.44 1.67-68.24 3.93-70.56 3.23s-.53-7.36 1.95-9.49 27.31-14.25 27.31-14.25l-.13-9z"
          fill="#c7c7c7"
        />
        <path
          d="M268.49 425.87c2.33.7 68.13-1.55 70.58-3.23.94-.62 1.17-10.23 1.11-21.84v-.37l-42.47 1v.72s-24.8 12.13-27.3 14.26-4.24 8.78-1.92 9.46z"
          fill="#37474f"
        />
        <path
          d="M269.15 423.3c11.08-.66 55.84-2.24 66.85-2 .09 0 .09.06 0 .07-11 .88-55.74 2.11-66.84 2.15-.24-.04-.24-.21-.01-.22zM294.19 402.38c4.34-1 9.7-.48 13.14 2.52.13.12 0 .3-.18.26a61.43 61.43 0 00-12.92-2.23.28.28 0 01-.04-.55zM291.11 404.39c4.33-1 9.69-.48 13.14 2.52.13.11 0 .3-.18.26a61.41 61.41 0 00-12.93-2.23.28.28 0 01-.03-.55zM288 406.39c4.34-1 9.7-.47 13.14 2.52.13.12 0 .31-.18.27a61.44 61.44 0 00-12.92-2.24.28.28 0 01-.04-.55z"
          fill="#263238"
        />
        <path
          fill="#455a64"
          d="M343.97 198.03l-.04 2.85-1.61 125.86-.01.48-.95 75.39-46.06-.92 8.89-203.51.12-2.72.45-23.61h38.9l.31 26.18z"
        />
        <path
          d="M297.22 394c7.19-.07 5.13-.15 12.32-.13 3.52 0 26.65.09 29.93.67.07 0 .07.14 0 .15-3.3.5-26.42 0-29.94-.06-7.19-.15-5.12-.19-12.31-.42-.13 0-.13-.21 0-.21zM317.86 211.27a.08.08 0 00-.16 0c0 12-.49 18.8-.78 30.84s-.65 24.08-1 36.11c-.65 24.07-1.62 48.12-2.51 72.18q-.75 20.24-1.87 40.46a.07.07 0 00.14 0c1.75-24 3.42-48.08 4.37-72.15s1.65-48.13 1.76-72.2c.09-13.51.02-21.73.05-35.24zM343.96 198.03l-1.64 128.71-30.87-154.89h32.2l.31 26.18z"
          fill="#263238"
        />
        <path
          d="M396.47 389.31s1.19 8.73 1.19 8.76c1.15 8.16 2 15.33 2.12 19.08v.06a10.68 10.68 0 01-.25 2.6v.06c-2.19 2-67.09 13.1-69.48 12.72s-1.52-7.22.66-9.67 25.13-17.8 25.13-17.8l-1.33-8.88z"
          fill="#c7c7c7"
        />
        <path
          d="M330 432.59c2.39.38 67.29-10.73 69.49-12.72.85-.75-.22-10.3-1.84-21.8l-.06-.36-41.94 6.7.14.71s-22.94 15.36-25.13 17.81-3.05 9.3-.66 9.66z"
          fill="#37474f"
        />
        <path
          d="M330.31 430c10.89-2.14 55-9.75 66-11 .09 0 .1 0 0 .07-10.75 2.36-54.94 9.61-65.94 11.15-.26-.06-.29-.22-.06-.22zM352.3 405.85c4.17-1.54 9.54-1.78 13.36.72.15.1 0 .31-.14.29a60.51 60.51 0 00-13.11-.47.28.28 0 01-.11-.54zM349.51 408.25c4.17-1.54 9.55-1.78 13.36.73.15.09 0 .3-.14.28a61.78 61.78 0 00-13.11-.47.28.28 0 01-.11-.54zM346.73 410.66c4.16-1.54 9.54-1.78 13.36.72.14.1 0 .31-.14.29a60.51 60.51 0 00-13.11-.47.28.28 0 01-.11-.54z"
          fill="#263238"
        />
        <path
          d="M308.71 171.81c.17.8 2.89 13.42 5.71 24.26 2.05 7.92 4.16 14.88 5.37 15.6 1.37.81 8.05 3.77 10.29 4.7l10.22 97.75 13.84 90.58 45.22-7-32.18-198.16-5.24-27.73z"
          fill="#455a64"
        />
        <path
          d="M354.42 395.69c7.1-1.14 5-.92 12.16-2 3.48-.52 26.35-3.91 29.69-3.82.07 0 .09.12 0 .14-3.19 1-26.12 4-29.61 4.44-7.13.92-5.09.58-12.23 1.42a.1.1 0 11-.01-.18zM380.59 368.64c-1.12-6.28-2.24-12.56-3.4-18.84q-3.46-18.84-7-37.67-7.08-37.69-14-75.44c-2.58-14.2-2.51-12.87-4.77-27.14 0-.09-.17-.08-.15 0 2.16 12.68 1.14 9.9 3.18 22.6s4.18 25.13 6.43 37.66q6.78 37.79 14.05 75.48 2 10.57 4.09 21.13c1.4 7.18 3 14.32 4.48 21.48 0 .16.28.15.26 0-1.08-6.41-2.02-12.85-3.17-19.26zM368.14 210.49c-1.2-.31-2.49-.41-3.71-.6-1-.16-2-.34-3-.55a12.87 12.87 0 01-5.22-2.2c-1.7-1.32-2.57-3.33-3.39-5.26q-.74-1.71-1.5-3.42l-.75-1.65a18.59 18.59 0 01-.93-2c0-.1-.19-.05-.15 0a13.82 13.82 0 01.42 1.43c.16.55.32 1.1.49 1.65.31 1 .61 2 .93 2.95a23.32 23.32 0 002.09 5.18c1.82 2.88 5.06 4 8.3 4.46.93.11 1.87.18 2.8.23a20 20 0 003.55 0 .16.16 0 00.07-.22zM316.25 209.94a86.57 86.57 0 0113.75 6.42.15.15 0 01-.13.28c-4.64-2-9.37-3.9-13.89-6.14a.31.31 0 01.27-.56zM314.85 178.17c0-.16.3-.2.3 0 0 5.5.31 10.35.77 15.5.22 2.39 2.14 15.59 6.28 14.63.7-.16 1.05-.85 1.16-1.89a16.52 16.52 0 00-.55-4c-.46-2.77-.83-5.54-1.06-8.33a75.5 75.5 0 01.16-15.62.14.14 0 01.27 0 142.68 142.68 0 001.26 21.54c.52 3.43 2.25 8.6-1.34 9.13s-6-8.12-6.47-10.49a79 79 0 01-.78-20.47z"
          fill="#263238"
        />
        <path
          d="M322.09 208.63a22.14 22.14 0 01.94 2.74 6 6 0 01.38 2.25c0 .15-.21.16-.28.06a8.13 8.13 0 01-1-2.19c-.31-.84-.64-1.69-.94-2.54a.46.46 0 01.9-.32z"
          fill="#263238"
        />
        <path
          d="M322.48 125.84c-6 20.92-16.54 42.64-19.65 46.94-8.34 11.58-34.09 23.52-42 25.2-3.42.73-12.37-21.25-10.32-24.38s34.09-16.51 35.63-18.21c1.07-1.18 16.19-18.71 24.76-29.9 11.1-14.49 13.15-5.15 11.58.35z"
          fill="#eb9481"
        />
        <path
          d="M263.74 197.16c-3.07 1.83-18 9.13-22.58 10.19s-17.92 3.14-19.09-.27 2.7-4.34 2.7-4.34-5.78 0-6.64-3.55 5.2-4.78 5.2-4.78-5.94-.45-6.54-4 5.69-3.83 5.69-3.83-5.45-.15-4.7-3.32c.9-3.81 10.91-2.4 15.86-3.94 1.13-.35 22.29-8.74 22.29-8.74z"
          fill="#eb9481"
        />
        <path
          d="M221.94 186.57c7.36 1 10.76.2 17.42-2.81.13-.06.25.12.12.19-6.26 3.55-10.37 5.2-17.61 3-.35-.13-.28-.42.07-.38zM222.74 194.31c7.74.4 11.43-.46 18.65-3.11a.1.1 0 01.07.18c-6.86 3.19-11 4.74-18.68 3.33-.34-.07-.39-.41-.04-.4zM225.13 202.6c7.52-.16 11.61-.93 18.28-4.4a.14.14 0 01.15.24c-6.46 4.2-11.56 5.2-18.4 4.39-.47-.06-.5-.22-.03-.23z"
          fill="#263238"
        />
        <path
          d="M260.15 168.54s-8.83-3-13-3.41-20.3 3.59-19.63 7.13c.87 4.56 10.65 4.06 15.41 3.19 0 0 2 10.56 9.1 11.72s8.12-18.63 8.12-18.63z"
          fill="#eb9481"
        />
        <path
          d="M228.18 173.43c3.8 3.59 10 2 14.65 1.55a.42.42 0 01.48.51c1.14 4.37 4 10.2 8.49 11.85a.11.11 0 01-.06.21c-5-1.12-8.33-6.62-9.27-11.48-4.79.58-11 1.86-14.32-2.6-.02-.03.01-.06.03-.04z"
          fill="#263238"
        />
        <path
          d="M260.29 167.25l12.89 27s25.76-11.45 29.82-19.81c1.26-2.55 15-28.92 18.6-44.26s-.38-16.65-10.77-5.91-24.26 28.8-25.66 30.06-24.88 12.92-24.88 12.92z"
          fill="#ebebeb"
        />
        <path
          d="M367.46 202.37L303 202c-.27-.31-6-4.14-4.7-13.54 2.94-21.43 11.9-60 17-67.88a14 14 0 0119.44-4.71c3.7 2.62 7.06 6 11.13 12.86 10 16.93 20.07 46.4 23.07 62.45 1.26 6.67-1.32 11.01-1.48 11.19z"
          fill="#202b37"
        />
        <g opacity={0.1}>
          <path d="M300.66 174.29v25.24c-.22-.31-.45-.65-.67-1a12.4 12.4 0 01-1-2.19V184c.09-.57.18-1.16.28-1.75 0-.14.05-.29.08-.44.12-.79.26-1.58.4-2.4.09-.55.19-1.11.29-1.68.14-.79.29-1.59.44-2.4 0-.14 0-.29.08-.44s.06-.39.1-.6zM303.51 160.26V202h-.45v-39.65l.18-.85c0-.15.07-.3.1-.44s.12-.53.17-.8zM307.58 143.26V202h-1.67v-52.12c.18-.74.36-1.49.55-2.22 0-.15.07-.3.11-.45.2-.81.4-1.61.61-2.4.13-.52.26-1.04.4-1.55zM310.43 133.12v68.94H310v-67.45l.24-.79c0-.15.09-.3.13-.45s.03-.17.06-.25zM314.5 122.07v80h-1.67V126c.26-.69.51-1.34.77-1.94s.5-1.17.75-1.67a1.77 1.77 0 01.15-.32zM317.35 118v84.1h-.45v-83.59zM321.42 115.07v87.05h-1.67V116c.31-.2.62-.39.93-.56s.49-.25.74-.37zM324.27 114.11v88h-.45v-87.9zM328.34 113.83v88.33h-1.67v-88.35a13.23 13.23 0 011.67.02zM331.19 114.31v87.86h-.45v-88zM335.26 116.25v85.95h-1.67v-87l.46.24a6.67 6.67 0 01.71.44zM338.11 118.6v83.61h-.45v-84zM342.19 123.16v79.07h-1.68v-81.14c.36.4.72.84 1.08 1.29q.3.38.6.78zM344.77 126.9c.08.14.17.28.26.42v74.93h-.44v-75.63zM349.11 134.62v67.65h-1.68v-70.8c.35.62.69 1.25 1 1.9l.24.45zM352 140.29v62h-.44v-62.8c.12.26.24.53.37.8zM356 149.82v52.49h-1.68v-56.49c.2.46.4.92.59 1.39.07.15.13.3.19.45q.48 1.07.9 2.16zM358.87 157v45.34h-.44v-46.4l.39 1.06zM363 168.76v33.59h-1.68v-38.59c0 .05 0 .1.05.14.19.56.39 1.13.57 1.68.28.81.54 1.61.8 2.4l.15.44c-.01.12.02.23.11.34zM365.69 177.74h.1v24.62h-.44v-25.81c.12.45.23.8.34 1.19zM369.23 193.26a19.62 19.62 0 010 2.4v.44a17.32 17.32 0 01-.34 2.4 17.12 17.12 0 01-.46 1.68c-.09.26-.17.49-.26.7v-13.41c.1.43.19.85.28 1.27l.09.44c.14.69.28 1.37.4 2 0 .13.05.25.06.38q.17.89.23 1.7z" />
          <path d="M300.66 174.29v25.24c-.22-.31-.45-.65-.67-1a12.4 12.4 0 01-1-2.19V184c.09-.57.18-1.16.28-1.75 0-.14.05-.29.08-.44.12-.79.26-1.58.4-2.4.09-.55.19-1.11.29-1.68.14-.79.29-1.59.44-2.4 0-.14 0-.29.08-.44s.06-.39.1-.6zM303.51 160.26V202h-.45v-39.65l.18-.85c0-.15.07-.3.1-.44s.12-.53.17-.8zM307.58 143.26V202h-1.67v-52.12c.18-.74.36-1.49.55-2.22 0-.15.07-.3.11-.45.2-.81.4-1.61.61-2.4.13-.52.26-1.04.4-1.55zM310.43 133.12v68.94H310v-67.45l.24-.79c0-.15.09-.3.13-.45s.03-.17.06-.25zM314.5 122.07v80h-1.67V126c.26-.69.51-1.34.77-1.94s.5-1.17.75-1.67a1.77 1.77 0 01.15-.32zM317.35 118v84.1h-.45v-83.59zM321.42 115.07v87.05h-1.67V116c.31-.2.62-.39.93-.56s.49-.25.74-.37zM324.27 114.11v88h-.45v-87.9zM328.34 113.83v88.33h-1.67v-88.35a13.23 13.23 0 011.67.02zM331.19 114.31v87.86h-.45v-88zM335.26 116.25v85.95h-1.67v-87l.46.24a6.67 6.67 0 01.71.44zM338.11 118.6v83.61h-.45v-84zM342.19 123.16v79.07h-1.68v-81.14c.36.4.72.84 1.08 1.29q.3.38.6.78zM344.77 126.9c.08.14.17.28.26.42v74.93h-.44v-75.63zM349.11 134.62v67.65h-1.68v-70.8c.35.62.69 1.25 1 1.9l.24.45zM352 140.29v62h-.44v-62.8c.12.26.24.53.37.8zM356 149.82v52.49h-1.68v-56.49c.2.46.4.92.59 1.39.07.15.13.3.19.45q.48 1.07.9 2.16zM358.87 157v45.34h-.44v-46.4l.39 1.06zM363 168.76v33.59h-1.68v-38.59c0 .05 0 .1.05.14.19.56.39 1.13.57 1.68.28.81.54 1.61.8 2.4l.15.44c-.01.12.02.23.11.34zM365.69 177.74h.1v24.62h-.44v-25.81c.12.45.23.8.34 1.19zM369.23 193.26a19.62 19.62 0 010 2.4v.44a17.32 17.32 0 01-.34 2.4 17.12 17.12 0 01-.46 1.68c-.09.26-.17.49-.26.7v-13.41c.1.43.19.85.28 1.27l.09.44c.14.69.28 1.37.4 2 0 .13.05.25.06.38q.17.89.23 1.7z" />
          <path d="M336.4 117.13h-18.12a15 15 0 011.47-1.12c.31-.2.62-.39.93-.56h13.37a6.67 6.67 0 01.71.44l.5.36c.38.28.74.57 1.14.88zM339.51 120h-23.77q.17-.24.33-.45h23zM342.85 124.05H313.6c.25-.61.5-1.17.75-1.67h27.24q.3.38.6.78c.22.29.44.58.66.89zM344.77 126.9h-32.28c.05-.15.11-.3.17-.45h31.82l.11.17zM347.16 131h-36.05c.18-.57.36-1.13.54-1.67h34.56c.32.51.64 1.07.95 1.67zM348.7 133.82h-38.48c0-.15.09-.3.13-.45h38.11zM350.74 137.89H309c.15-.57.31-1.12.47-1.67h40.41c.32.55.59 1.11.86 1.67zM352.09 140.74h-43.84l.12-.45H352v.15zM353.91 144.81h-46.73c.13-.52.26-1 .4-1.55v-.12h45.59c.25.55.5 1.11.74 1.67zM355.13 147.66h-48.67c0-.15.07-.3.11-.45h48.37c.06.15.13.3.19.45zM356.79 151.73h-51.33c.14-.56.27-1.11.41-1.67h50.25c.23.55.46 1.11.67 1.67zM357.91 154.58H304.8c0-.15.06-.3.1-.45h52.84c.06.15.12.3.17.45zM359.45 158.66h-55.59c.13-.56.26-1.12.38-1.68h54.63v.12c.2.52.39 1.04.58 1.56zM360.48 161.5h-57.24c0-.15.07-.3.1-.44h57c.04.15.09.3.14.44zM361.89 165.58h-59.51c.12-.56.23-1.12.35-1.68h58.59c.19.56.39 1.1.57 1.68zM362.84 168.42h-61l.09-.44h60.8zM364.13 172.5H301c.11-.56.21-1.12.33-1.68h62.28c.18.56.39 1.12.52 1.68zM365 175.34h-64.53c0-.14 0-.29.08-.44h64.31c.05.15.14.3.14.44zM366.16 179.42h-66.42c.09-.55.19-1.11.29-1.68h65.76v.35c.13.45.21.91.37 1.33zM366.92 182.26h-67.66c0-.14.05-.29.08-.44h67.46a4.16 4.16 0 01.12.44zM367.94 186.34h-69.32c.09-.55.17-1.11.26-1.68h68.65c.14.57.27 1.13.41 1.68zM368.56 189.18h-70.32c0-.14 0-.29.05-.44h70.18zM369.23 193.26h-71a12.72 12.72 0 01-.13-1.68H369q.17.87.23 1.68zM369.28 195.66v.44h-70.37c-.05-.14-.1-.29-.14-.44zM368.91 198.5a17.12 17.12 0 01-.46 1.68h-67.29c-.16-.2-.33-.41-.5-.65s-.45-.65-.67-1z" />
        </g>
        <path
          d="M323.21 128.37c1.11-.27 7.35-7.59 8.71-12.57.09-.35-1.13-3.42-2.44-7.23-.79-2.31-1.61-4.91-2.19-7.33-.11-.5-1.59.82-1.59.82l-2.95 1.5-9.77 5a39.52 39.52 0 013.69 9 5.28 5.28 0 01.13 1v.17c.2 1.92 4.6 10.08 6.41 9.64z"
          fill="#eb9481"
        />
        <path
          d="M316.8 118.52v.17a9 9 0 001.58-.29c10.18-2.67 8.35-16 8.35-16l-4 1.14-9.77 5a39.52 39.52 0 013.69 9 5.28 5.28 0 01.15.98z"
          fill="#263238"
        />
        <path d="M323.35 113.79" fill="none" />
        <path
          d="M325.84 83.66c2.63 5.16 2.52 21.42-.74 25.62-4.72 6.08-13.84 8.19-19.36 2.2-5.35-5.82-4.84-27-1.07-31 5.55-5.79 17.33-4.48 21.17 3.18z"
          fill="#eb9481"
        />
        <path
          d="M312.14 95.8s.08 0 .08.1c0 1.09.11 2.35 1.1 2.72v.06c-1.22-.17-1.39-1.85-1.18-2.88z"
          fill="#263238"
        />
        <path
          d="M313.11 94.66c1.77-.13 1.9 3.4.27 3.52s-1.74-3.41-.27-3.52zM305.81 96.22s-.09 0-.09.1c.08 1.09 0 2.36-1 2.76v.06c1.22-.22 1.33-1.9 1.09-2.92z"
          fill="#263238"
        />
        <path
          d="M304.79 95.11c-1.77-.07-1.78 3.47-.14 3.53s1.62-3.47.14-3.53z"
          fill="#263238"
        />
        <path
          d="M314.45 93a13.16 13.16 0 01-1.39-.43 2.32 2.32 0 01-1.33-.71.76.76 0 01.09-.91 1.85 1.85 0 011.86-.26 2.76 2.76 0 011.61 1.12.8.8 0 01-.84 1.19zM303.35 93.38a13.07 13.07 0 001.39-.41 2.39 2.39 0 001.34-.7.74.74 0 00-.08-.91 1.88 1.88 0 00-1.86-.28 2.78 2.78 0 00-1.62 1.1.8.8 0 00.83 1.2z"
          fill="#c7c7c7"
        />
        <path
          d="M313.53 105.92c-.25.27-.5.63-.9.68a2.82 2.82 0 01-1.18-.23s-.07 0-.05.06a1.47 1.47 0 001.41.54 1.19 1.19 0 00.83-1 .06.06 0 00-.11-.05zM312.26 102.69a3.76 3.76 0 01-2.43 1.75 4.62 4.62 0 01-1.34.11 1.1 1.1 0 01-.26 0h-.24a.27.27 0 01-.23-.23.41.41 0 010-.11V104c-.06-.84 0-2.12 0-2.12-.3.16-1.85.93-1.84.49a56.23 56.23 0 011-11.34.1.1 0 01.2 0c.06 3.52-.49 7-.49 10.55a6.68 6.68 0 011.74-.6c.12 0-.11 2.48-.05 2.86a5.18 5.18 0 003.81-1.35c.1.04.19.11.13.2z"
          fill="#263238"
        />
        <path
          d="M309 104.11a4.43 4.43 0 001.82 1.3 2 2 0 001.22 0c.86-.26.85-1.06.67-1.75a4.86 4.86 0 00-.43-1 5.81 5.81 0 01-3.28 1.45z"
          fill="#263238"
        />
        <path
          d="M310.79 105.41a2 2 0 001.22 0c.86-.26.85-1.06.67-1.75a2.08 2.08 0 00-1.89 1.75z"
          fill="#ff99ba"
        />
        <path
          d="M302.54 86c.55-1.41.95-4.15 2.36-4.67 1-.37 1.56 1.65 3.47 1.71 2.58.07 4.07-2.27 7.65-2.66 5.14-.56 6.78 5.17 7.19 12.48.36 6.54 4.34 7 4.34 7s1.83-14.51-2.42-19.12-13.65-6.36-19.73-1.56c-2.22 1.72-2.86 6.82-2.86 6.82z"
          fill="#c7c7c7"
        />
        <path
          d="M326.8 74.78a1.51 1.51 0 11-1.51-1.51 1.51 1.51 0 011.51 1.51z"
          fill="#37474f"
        />
        <path
          d="M327.51 88.89c-.66-.61-9.88-6.88-12.18-7.29a71.63 71.63 0 00-11.08-.25c-1.46.16-4-2.1-3.29-3.61.83-1.76 5.56-2 8.58-2.63s11.68-2.11 16.46.58c3.65 2 8 6.33 7.18 9.6-.79 3.04-5.67 3.6-5.67 3.6z"
          fill="#455a64"
        />
        <path
          d="M324.83 97.18s2.76-5.67 5.28-4.77 1 8.74-1.55 10.19a2.81 2.81 0 01-3.95-.9z"
          fill="#eb9481"
        />
        <path
          d="M329.62 94.65v.07c-1.79 1.07-2.51 3-2.92 5a1.54 1.54 0 012.22-.78c.05 0 0 .11 0 .11a1.73 1.73 0 00-1.78.89 8.57 8.57 0 00-.66 1.66c-.06.18-.38.14-.35-.07-.43-2.53.92-6.22 3.49-6.88z"
          fill="#263238"
        />
        <path
          d="M322.6 109.05c2.53-1.38 5.07-3 6.58-5.58a14.08 14.08 0 002.06-6.17 11.36 11.36 0 01-.81 2.54 9.35 9.35 0 01-1.57 2.55s-3.6 4.78-6.41 6.22c-.26.14-.11.57.15.44zM324.55 98.72c.42-.62 1.64-3.75 2.85-5a6.76 6.76 0 01.57-.62 3.13 3.13 0 011.16-.74 2.07 2.07 0 00-1.16 0c-2 .54-3 4.67-3.63 6.25-.02.1.13.22.21.11z"
          fill="#ebebeb"
        />
        <path
          d="M324.93 98c.28-.77-4.92 2.26-10.66 1.55-1.17-.15-4.49-1.19-6.28-1s-3.77 1.76-6.23 2.23c-.84.16.48 7.42 2.9 10.45.79 1 4 2.92 5.86 3 4.38.1 11.59-3.59 12.6-5.13.82-1.33-.83-3.73 1.81-11.1z"
          fill="#ebebeb"
        />
        <path
          d="M302.48 102.28a17.37 17.37 0 015.16-2 20.06 20.06 0 014.52.63 18.51 18.51 0 0010.4-.91c.05 0 .07.05 0 .07a15.08 15.08 0 01-9.36 1.37c-1.73-.26-3.84-1-5.58-.72a24.81 24.81 0 00-5.12 1.77c-.12.02-.17-.13-.02-.21zM305.9 110.54a31.36 31.36 0 003.69 1.5c1.5.36 3.18-.25 4.59-.71A81.76 81.76 0 00322 108a35.56 35.56 0 01-9 4 7.26 7.26 0 01-3.29.4 14.89 14.89 0 01-3.86-1.81s.01-.07.05-.05zM303.61 104.68a18.91 18.91 0 018.15-.89 32.52 32.52 0 009.57-1s.05 0 0 .06a27.92 27.92 0 01-8.52 1.12c-1.39 0-2.79-.18-4.19-.17a16.22 16.22 0 00-5 1c-.05.08-.11-.09-.01-.12zM304.48 107.43a13.77 13.77 0 008.26 1.13 36.47 36.47 0 008.22-3s.05 0 0 .07c-5.34 2.77-10.8 4.79-16.55 1.94-.1-.02-.04-.19.07-.14z"
          fill="#dbdbdb"
        />
        <g opacity={0.2}>
          <path d="M309.91 87.71a13.27 13.27 0 01-1.78.19c-.61 0-1.21-.12-1.82-.1a9.65 9.65 0 00-3.33.83c-.09 0-.07.1 0 .07a8.3 8.3 0 013.54-.61 10.55 10.55 0 003 0 10.71 10.71 0 016.62.46 10.73 10.73 0 00-6.23-.84zM309.89 86.11a13.64 13.64 0 01-1.69.18c-.58 0-1.15-.12-1.73-.11a9.38 9.38 0 00-3.15.8c-.09 0-.07.09 0 .06a8.06 8.06 0 013.36-.58 10.17 10.17 0 002.81 0 10.11 10.11 0 016.27.44 10.15 10.15 0 00-5.87-.79z" />
        </g>
        <path
          d="M316.83 118.9l5.54 8.32 8.88-13.74 3.93 3s-7.24 20.43-7.48 19.62l-5.33-7-4.25 6.53-2.37-13.7z"
          fill="#ebebeb"
        />
        <path
          d="M316.56 128.91c-.31-2.27-.69-4.55-.9-6.84 0-.08.11-.14.15 0 .89 2.07 2.05 10.78 2.48 12.83.18-.28 4-5.79 4.06-5.73 1.07 1.07 5 6.18 5.26 6.66 1.14-3.25 5.64-15.1 7.13-18.26.05-.09.18 0 .15.05-.9 3.37-6.8 19.3-7 19.29a91.15 91.15 0 01-5.59-7.43c-.6 1.15-4.35 7.26-4.43 6.93a71.76 71.76 0 01-1.31-7.5z"
          fill="#263238"
        />
        <path
          d="M318.08 424.72h-7V224.59c0-3.76-1.06-6.86-3.06-8.94a8.57 8.57 0 00-6.25-2.69c-4.16 0-8.35 3-8.35 9.88v5.76h-7v-5.76c0-11.08 7.72-16.88 15.35-16.88a15.73 15.73 0 0111.3 4.84c2.29 2.38 5 6.71 5 13.79z"
          fill="#202b37"
        />
        <path
          d="M293.42 228.6h-7v-5.76c0-11.08 7.72-16.88 15.35-16.88a15.73 15.73 0 0111.3 4.84c2.29 2.38 5 6.71 5 13.79h-7c0-3.76-1.06-6.86-3.06-8.94a8.57 8.57 0 00-6.25-2.69c-4.16 0-8.35 3-8.35 9.88z"
          opacity={0.1}
        />
        <path
          d="M327.49 179.45s-12.25 4.59-20.05 6.44c-4.35 1-11.64 3.47-14.49 5.69-4.26 3.32-19.95 19.93-16 23.82 3.57 3.53 15.24-12.46 15.24-12.46S279 218 284.06 221.48s15.86-16.36 15.86-16.36-13.21 15.81-7.41 18.54c4.38 2.06 15.16-15.33 15.16-15.33s-11.26 13.66-6.85 16c4.88 2.56 15.84-12.87 15.84-12.87 12.22 4 21.53-1.92 26.57-4.76 1.22-.7-15.74-27.25-15.74-27.25z"
          fill="#eb9481"
        />
        <path
          d="M295.63 198.32c-6.09 6.33-10.71 14.11-12 17.56-.16.42-.29.8-.27.77 2.58-4 10.92-16.43 12.37-18.28.01-.05-.06-.09-.1-.05zM303.2 202.57c-5.29 4.13-9.28 11.6-11 14.08-.13.19 0 .19.15 0 1.79-2.41 9-11.73 10.88-14 .06-.07.01-.11-.03-.08zM309.34 206.52a31.16 31.16 0 00-7.69 10.56c-.1.21.06.18.2 0 2.65-3.75 4.22-6.13 7.61-10.43.05-.08-.04-.19-.12-.13z"
          fill="#263238"
        />
        <path
          d="M335.58 129.79c2 8.27 17.45 36.85 17.44 38.48s-29.12 12-34.87 15.44c-3.91 2.3 10.71 30.21 18.33 26.49 11.8-5.76 45.46-21.26 47.22-33.23s-32.7-47.55-39.4-53.85-11.98-6.93-8.72 6.67z"
          fill="#eb9481"
        />
        <path
          d="M318.1 181.22l24.77 30.2s38.2-20.21 41.8-33c3.32-11.78-35.12-51.85-41.16-56.9-5.87-4.9-12-6-7.55 10.73s16.85 34.47 16 35.7-33.86 13.27-33.86 13.27z"
          fill="#ebebeb"
        />
        <path fill="#202b37" d="M319.99 432.6h-12.9l1-15.83h10.9l1 15.83z" />
        <path opacity={0.2} d="M319.99 432.6h-12.9l1-15.83h10.9l1 15.83z" />
      </g>
      <g>
        <path
          d="M196.65 431.65c-2.3.75-67.67-.1-70.13-1.72-1-.59-1.38-10.13-1.57-21.66v-.36c0-2.38-.07-4.83-.08-7.31v-1l3.13-33.93 43.73.44-4.31 33.59-.05 1.33-.27 7v.72s24.87 11.52 27.4 13.58 4.44 8.59 2.15 9.32z"
          fill="#ad6359"
        />
        <path
          d="M196.65 431.65c-2.3.75-67.67-.1-70.13-1.72-1-.59-1.38-10.13-1.57-21.66v-.36c0-2.38-.07-4.83-.08-7.31v-1l42.59.12-.05 1.33-.27 7v.72s24.87 11.52 27.4 13.58 4.4 8.57 2.11 9.3z"
          fill="#ebebeb"
        />
        <path
          d="M196.65 431.65c-1.18.38-18.94.34-36.3 0-3.49-.07-7-.15-10.27-.24-12.38-.34-22.61-.85-23.56-1.47s-1.37-9.86-1.56-21.18v-.48-.37l42.13.08v.72s24.88 11.51 27.4 13.57 4.45 8.64 2.16 9.37z"
          fill="#202b37"
        />
        <path
          d="M195.94 429.11c-11-.41-55.49-1-66.41-.61-.09 0-.09.06 0 .07 10.91.65 55.38.91 66.41.72.23 0 .23-.17 0-.18zM171.82 408.17c-4.33-.87-9.64-.27-13 2.77-.13.12 0 .3.19.26a61.61 61.61 0 0112.78-2.49.27.27 0 00.03-.54zM174.92 410.09c-4.32-.86-9.64-.27-13 2.78-.13.12 0 .3.18.26a61.06 61.06 0 0112.79-2.49.28.28 0 00.03-.55zM178 412c-4.33-.87-9.64-.27-13 2.78-.13.12 0 .3.18.26a60.33 60.33 0 0112.82-2.47.28.28 0 000-.57z"
          fill="#263238"
        />
        <path
          d="M160.35 431.64c-3.49-.07-7-.15-10.27-.24-12.38-.34-22.61-.85-23.56-1.47s-1.37-9.86-1.56-21.18v-.48-.37c5.8 4.1 26.14 17.59 35.39 23.74z"
          fill="#263238"
        />
        <path
          d="M170 408.13h-47.09s9.48-110 9.11-111.88c-.47-2.46-3.25-13.59-7-28.13-1.16-4.55-2.41-9.43-3.71-14.49a6916.3 6916.3 0 00-10.7-41.63c-5.06-19.49-9-34.63-9-34.63h39.9s4.65 14.74 10.51 33.92c9.55 31.17 22.31 74.07 23.57 84.5 2.03 16.83-5.59 112.34-5.59 112.34z"
          fill="#37474f"
        />
        <path
          d="M167.91 400.1c-7.19-.15-5.13-.21-12.32-.28-3.52 0-26.64-.24-29.93.31-.07 0-.07.13 0 .15 3.29.54 26.41.34 29.93.3 7.19-.06 5.13-.12 12.32-.27.09-.01.09-.21 0-.21zM140.88 289.53c-1.54-6.81-3.85-13.48-5.86-20.16q-6.12-20.37-12.31-40.69-3.09-10.17-6.21-20.34c-1.06-3.42-2.11-6.84-3.18-10.25s-2.24-6.66-3.18-10c-.39-1.37-.6-.62-.11 1.13 1 3.51 2 7 3 10.52q3 10.41 6.12 20.8 6.12 20.7 12.35 41.35l6.24 20.72a84.48 84.48 0 012.54 10 46.35 46.35 0 01.42 10.72c-.46 7.21-1.29 14.4-1.95 21.59s-1.34 14.4-2 21.6q-2 21.5-3.95 43c-.16 1.81-.32 3.63-.46 5.45-.05.61.87.56.94 0 1.55-14.17 2.74-28.39 4.07-42.59s2.65-28.24 4-42.36c.33-3.55.7-7.1.71-10.67a44.17 44.17 0 00-1.18-9.82z"
          fill="#263238"
        />
        <path
          d="M137 177.36c-2.77 21.43-8.05 61.61-11.9 90.76-9-35.32-23.47-90.76-23.47-90.76z"
          fill="#263238"
        />
        <path
          d="M146.77 432.28c-2.29.75-67.66.14-70.13-1.46-1-.6-1.42-10.13-1.65-21.66v-.36c0-2.38-.09-4.82-.1-7.3v-1c-.15-16.64 1-33.91 1-33.91l42.73.28-1.19 33.6v1.33l-.24 7v.72s24.92 11.42 27.45 13.48 4.42 8.55 2.13 9.28z"
          fill="#ad6359"
        />
        <path
          d="M146.77 432.28c-2.29.75-67.66.14-70.13-1.46-1-.6-1.42-10.13-1.65-21.66v-.36c0-2.38-.09-4.82-.1-7.3v-1h42.59v1.33l-.24 7v.72s24.92 11.42 27.45 13.48 4.37 8.52 2.08 9.25z"
          fill="#ebebeb"
        />
        <path
          d="M146.77 432.28c-2.29.75-67.66.14-70.13-1.46-1-.6-1.42-10.13-1.65-21.66v-.36l42.18-.08v.72s24.92 11.42 27.45 13.48 4.44 8.63 2.15 9.36z"
          fill="#202b37"
        />
        <path
          d="M146.06 429.74c-11-.37-55.49-.84-66.41-.37-.09 0-.09.07 0 .07 10.91.61 55.38.72 66.4.48.24 0 .24-.17.01-.18zM121.86 408.89c-4.33-.85-9.64-.24-13 2.82-.13.12 0 .3.18.26a61 61 0 0112.77-2.54.27.27 0 00.05-.54zM125 410.8c-4.33-.85-9.64-.23-13 2.83-.13.12 0 .3.18.26a60.93 60.93 0 0112.82-2.54.28.28 0 000-.55zM128.08 412.72c-4.33-.85-9.64-.24-13 2.82-.13.12 0 .3.18.26a60.4 60.4 0 0112.78-2.53.28.28 0 00.04-.55z"
          fill="#263238"
        />
        <path
          d="M140.21 177.36s-.92 19.09-5.29 34.21c-.35 1.25-.73 2.48-1.14 3.66a71 71 0 00-11.5 5.91s-.46 15.36-.92 32.49c-.51 19.15-1 40.51-.93 45 .4 16.95-2.12 110.15-2.12 110.15l-47.07.22s8.51-104.08 8.45-110.29c-.09-10 2.28-54.09 4.11-86.26 1.12-19.82 2-35.1 2-35.1z"
          fill="#37474f"
        />
        <path
          d="M116.7 400.45c-7.19-.12-5.13-.19-12.32-.23-3.52 0-26.64-.12-29.93.44-.07 0-.07.13 0 .15 3.29.53 26.42.23 29.94.18 7.19-.1 5.12-.15 12.31-.33.13-.01.13-.21 0-.21zM89.8 184.18c-.87 26.26-1.79 52.53-3 78.78s-2.77 52.66-5 78.94c-1.23 14.68-2.39 35.33-4 50 0 .1.14.14.15 0 3.43-26.05 5.34-58.26 7-84.48s2.95-52.42 3.84-78.7c.5-14.85.89-29.71 1.33-44.56.01-.16-.32-.16-.32.02zM135.74 214.09a87.5 87.5 0 00-13.46 7c-.17.1 0 .37.14.28 4.54-2.21 9.18-4.33 13.59-6.79a.3.3 0 00-.27-.49z"
          fill="#263238"
        />
        <path
          d="M121.11 117.23c1.57 23.58 10.17 48.5 13.75 52.43 5.54 6.09 32.56 13.75 43.17 15 3.47.41 7.82-23.32 5.19-26s-31.92-7.77-33.79-9.09c-1-.7-10.58-19.2-17.39-31.17-6.35-11.12-11.41-8.32-10.93-1.17z"
          fill="#dd6a57"
        />
        <path
          d="M173.73 155.9s7.76-5.14 11.67-6.65 20.54-1.77 20.8 1.82c.33 4.63-9.24 6.68-14.06 7.06 0 0 .79 10.72-5.76 13.68s-12.65-15.91-12.65-15.91z"
          fill="#dd6a57"
        />
        <path
          d="M177.83 184.64c3.44 1 19.59 4 24.27 3.85s18.13-1.59 18.37-5.19-3.73-3.49-3.73-3.49 5.58-1.51 5.5-5.15-6.26-3.28-6.26-3.28 5.62-2 5.28-5.59-6.49-2.22-6.49-2.22 5.22-1.56 3.68-4.43c-1.86-3.45-11.16.5-16.34.29-1.18-.05-23.79-2.68-23.79-2.68z"
          fill="#dd6a57"
        />
        <path
          d="M216.74 179.81s4-.11 3.73 3.49-13.69 5-18.37 5.19-20.69-2.86-24.22-3.84a101 101 0 01-11.17-2.21l3.35-27.86c1.43.34 2.83.67 4.15 1 1.75-1.14 7.86-5.06 11.19-6.34 3.9-1.51 20.54-1.77 20.8 1.82a3.36 3.36 0 01-.78 2.36c-2.28 3.94-7.55 4.5-11.86 5.07 4.37.5 8.09.91 8.55.93 5.18.21 14.48-3.74 16.34-.29 1.45 2.7-3.09 4.24-3.63 4.42l.45-.09a.28.28 0 01.25 0c1.67-.25 5.44-.47 5.71 2.35s-3.15 4.67-4.62 5.33c.16 0 .2.13.12.26 1.66 0 5.41.44 5.48 3.28.11 3.62-5.47 5.13-5.47 5.13z"
          fill="#dbdbdb"
        />
        <path
          d="M215.3 163.46c-6.85 2.89-10.34 3-17.56 1.78-.14 0-.21.18-.07.21 7 1.81 11.37 2.35 17.78-1.67.32-.2.17-.46-.15-.32zM216.52 171.14c-7.37 2.38-11.16 2.5-18.81 1.81-.11 0-.14.17 0 .19 7.45 1.31 11.85 1.74 18.9-1.61.29-.15.25-.53-.09-.39zM216.35 179.76c-7.3 1.79-11.45 2.1-18.79.48a.14.14 0 00-.08.26c7.33 2.39 12.51 2 18.9-.51.44-.18.43-.34-.03-.23zM205.87 152.37c-2.74 4.45-9.1 4.55-13.75 5.28a.42.42 0 00-.33.62c0 4.51-1.25 10.89-5.14 13.64a.11.11 0 00.11.19c4.58-2.38 6.34-8.55 6-13.49 4.78-.67 11.15-1.05 13.17-6.21l-.06-.03z"
          fill="#263238"
        />
        <path
          d="M123 149.62l22.28-9s-7.91-16.49-13.25-24.48-12.87-9.88-12.69 4.79c.14 12.44 3.66 28.69 3.66 28.69z"
          fill="#37474f"
        />
        <path
          d="M82 196.34s67.95-.33 67.95-.32c0 1.33-14.74-76-21.84-85.21-1.52-2-11.9-4.49-16.59-.12C91 129.81 82 197.2 82 196.34z"
          fill="#202b37"
        />
        <path fill="#fff" d="M129.48 129.562l2.03-.281 1.261 9.113-2.03.28z" />
        <path fill="#fff" d="M135.538 132.33l.281 2.03-9.113 1.261-.28-2.03z" />
        <path
          d="M125.51 117.74c-3.24.78-10-3-12.33-7.62-.15-.32.43-3.57 1-7.57.3-2.42.59-5.13.67-7.62 0-.51 15.49 4.32 15.49 4.32a39.64 39.64 0 00-1.83 9.5 5.69 5.69 0 00.07 1 .79.79 0 010 .17c.23 1.98.42 6.98-3.07 7.82z"
          fill="#dd6a57"
        />
        <path
          d="M128.55 109.78v.17a10.45 10.45 0 01-1.6 0c-9.18-.51-11.22-12.09-11.59-15 2.76.49 14.93 4.29 14.93 4.29a39.78 39.78 0 00-1.83 9.51 5.55 5.55 0 00.09 1.03z"
          fill="#263238"
        />
        <path d="M120.2 104.72" fill="none" />
        <path
          d="M112.57 75.46c-1.7 5.54 1.19 21.54 5.13 25.11 5.7 5.18 15 5.69 19.45-1.16 4.27-6.65.12-27.44-4.27-30.68-6.47-4.79-17.8-1.44-20.31 6.73z"
          fill="#dd6a57"
        />
        <path
          d="M128.15 85.07s-.07.06-.06.11c.22 1.07.3 2.33-.61 2.86v.06c1.18-.37 1.06-2.05.67-3.03z"
          fill="#263238"
        />
        <path
          d="M127 84.11c-1.75.17-1.28 3.68.35 3.52s1.12-3.63-.35-3.52zM134.46 84.39s.09 0 .1.08c.11 1.09.42 2.32 1.46 2.55v.06c-1.23-.01-1.63-1.64-1.56-2.69z"
          fill="#263238"
        />
        <path
          d="M135.28 83.12c1.72-.37 2.34 3.12.74 3.46s-2.19-3.15-.74-3.46zM125.39 82.69a12 12 0 001.29-.66c.5-.26.92-.41 1.19-.92a.76.76 0 00-.24-.89 1.86 1.86 0 00-1.88.07 2.71 2.71 0 00-1.39 1.38.79.79 0 001.03 1.02zM136.39 81.17A11.77 11.77 0 01135 81a2.37 2.37 0 01-1.44-.45.75.75 0 01-.08-.91 1.85 1.85 0 011.78-.6 2.73 2.73 0 011.79.81.79.79 0 01-.66 1.32zM128.52 95.28c.3.21.6.53 1 .51a2.94 2.94 0 001.13-.43h.06a1.49 1.49 0 01-1.3.77 1.17 1.17 0 01-1-.84.06.06 0 01.11-.01z"
          fill="#263238"
        />
        <path
          d="M129.22 91.87a3.75 3.75 0 002.69 1.31 4.68 4.68 0 001.34-.12 1.83 1.83 0 00.25-.06l.23-.07a.28.28 0 00.19-.28.36.36 0 000-.11v-.1c-.09-.83-.34-2.09-.34-2.09.32.11 2 .6 1.89.16a56.5 56.5 0 00-2.95-11 .11.11 0 00-.2.06c.55 3.47 1.7 6.82 2.3 10.3a6.49 6.49 0 00-1.82-.29c-.11 0 .54 2.43.55 2.81a5.17 5.17 0 01-4-.67c-.13-.01-.21.07-.13.15z"
          fill="#263238"
        />
        <path
          d="M132.7 92.7a4.49 4.49 0 01-1.57 1.6 2 2 0 01-1.19.24c-.9-.11-1-.9-1-1.6a4.56 4.56 0 01.25-1.1 5.72 5.72 0 003.51.86z"
          fill="#263238"
        />
        <path
          d="M131.13 94.3a2 2 0 01-1.19.24c-.9-.11-1-.9-1-1.6a2 2 0 012.19 1.36z"
          fill="#ff99ba"
        />
        <path
          d="M135.92 73.73c-.78-1.29-1.66-2.59-2.52-2.75s-1.14 1.93-3 2.31c-2.52.52-4.07-1.66-7.67-1.43-5.16.34-6.84 4.91-6 12.19.76 6.49-3.08 7.63-3.08 7.63s-4.29-14-.9-19.25 12.36-8.61 19.17-4.93c2.5 1.35 4 6.23 4 6.23z"
          fill="#263238"
        />
        <path
          d="M116.59 88.69s-3.71-5.11-6-3.77.54 8.78 3.31 9.75a2.8 2.8 0 003.73-1.57z"
          fill="#dd6a57"
        />
        <path
          d="M111.43 87v.07c2 .75 3 2.52 3.75 4.37a1.55 1.55 0 00-2.33-.38s0 .11.06.1a1.73 1.73 0 011.91.57 8.63 8.63 0 01.93 1.51c.1.18.41.08.34-.12v-.05c-.04-2.43-2.01-5.84-4.66-6.07z"
          fill="#263238"
        />
        <path
          d="M120.86 100c-2.74-.91-5.51-2-7.46-4.34a13.94 13.94 0 01-3.1-5.72 11.1 11.1 0 001.24 2.36 9 9 0 001.93 2.21s4.44 4.11 7.46 5c.28.1.2.56-.07.49zM117.14 90.15c-.53-.53-2.27-3.41-3.69-4.41-.22-.18-.45-.35-.68-.51a3 3 0 00-1.27-.52 2 2 0 011.14-.22c2.05.19 3.78 4.07 4.67 5.52.05.09-.08.24-.17.14z"
          fill="#ebebeb"
        />
        <path
          d="M116.63 89.52c-.41-.72 5.25 1.36 10.77-.35 1.13-.34 4.21-2 6-2.12s4 1.08 6.52 1.1c.85 0 .83 7.4-1 10.81-.61 1.12-3.43 3.56-5.26 3.93-4.3.87-12-1.52-13.31-2.86-1.04-1.03.17-3.73-3.72-10.51z"
          fill="#ebebeb"
        />
        <path
          d="M139.48 89.81a17.24 17.24 0 00-5.43-1.11 20.5 20.5 0 00-4.34 1.41 18.56 18.56 0 01-10.41.89v.09a15.08 15.08 0 009.46-.3c1.65-.55 3.61-1.61 5.37-1.67a24 24 0 015.34.85c.15.03.18-.11.01-.16zM137.55 98.54a31.47 31.47 0 01-3.36 2.12c-1.41.62-3.18.3-4.65.1a82.62 82.62 0 01-8.25-1.9 35.59 35.59 0 009.51 2.34 7.25 7.25 0 003.31-.18 15 15 0 003.49-2.45s-.01-.06-.05-.03zM138.79 92.36a19 19 0 00-8.18.55 32.84 32.84 0 01-9.59.74v.06a28.08 28.08 0 008.58-.38c1.37-.27 2.71-.67 4.09-.9a16.35 16.35 0 015.08.11c.1.02.12-.16.02-.18z"
          fill="#dbdbdb"
        />
        <path
          d="M138.41 95.23a13.73 13.73 0 01-7.93 2.55 36.47 36.47 0 01-8.61-1.48v.06c5.74 1.8 11.47 2.83 16.64-1 .09-.05 0-.2-.1-.13z"
          fill="#dbdbdb"
        />
        <path
          fill="#202b37"
          d="M147.85 121.62h70.55v78.13h-70.55zM218.41 121.62h36.43v78.13h-36.43z"
        />
        <path opacity={0.1} d="M218.41 121.62h36.43v78.13h-36.43z" />
        <path
          fill="#fff"
          d="M189.12 138.5h-2.62l-.64-1.3-.9 1.3-1.21-2.67-1.16 2.67h-4.81l-.54-1.78-.52 1.78h-.98v-16.88h13.38v16.88z"
        />
        <path
          d="M252.33 194.37q-4.05-8.34-8-16.72l-7.91-16.81L228.6 144q-3.89-8.46-7.68-17 4.05 8.34 8 16.72l7.9 16.81 7.8 16.88q3.9 8.45 7.71 16.96z"
          fill="#202b37"
        />
        <path
          d="M252.33 127q-3.81 8.5-7.69 17l-7.8 16.87-7.9 16.81q-4 8.39-8 16.72 3.79-8.51 7.68-17l7.8-16.88 7.91-16.81q3.94-8.38 8-16.71z"
          fill="#202b37"
        />
        <path
          d="M189.2 156.94c.33.7 5.07 7.43 6.31 9.8s2.09 7 2.2 7.19 5.82 6 7 7.09c.11.1.17.15.19.15l.27.06c.42.1 1.13.36 1 .9a11.74 11.74 0 01-4.75 5.86c-3.29 2.26-6.49 2.81-7.14 2.64s-.67-.69-.8-1.11-6.65-8.69-7.3-8.43c-.38.15-3-.73-4.3-1.29s-8.47-5-9.13-5.2-7.67-.15-7.71-1.42 2.64-2.55 5.13-2.88c1.67-.23 5.2.66 6 .14s.23-2.67-.2-3.79-13.66-15.8-11.57-17.51a.64.64 0 01.39-.14h.2c2.64.39 9.48 10.66 11.18 11.14a.27.27 0 00.25 0l.06-.07c.64-1.34-11-13.88-8.64-15.62a1.13 1.13 0 011.19.09c3.07 1.57 9.17 11.08 11.15 12.86.26.24.45.34.55.27s.1-.2.06-.39c-.46-2.2-7.81-12.4-6-13.56a.5.5 0 01.27-.08 1.17 1.17 0 01.6.21c2.26 1.39 6.81 9.21 8.7 11.9.43.61.71 1 .81.9.44-.52-5.2-10.38-2.92-11.14a.75.75 0 01.3 0 1.37 1.37 0 01.72.33c2.39 1.78 5.64 10.48 5.93 11.1z"
          fill="#455a64"
        />
        <path
          d="M106.12 126.45c-.55 16.35.66 49.92 8.54 59.13 10.34 12.09 37.5 17.94 45.54 19.7 3.5.76 10-22.21 7.72-25.32s-30.22-8.07-32.52-9.78-11.65-31.82-15.91-45c-5.38-16.71-12.89-12.57-13.37 1.27z"
          fill="#dd6a57"
        />
        <path
          d="M178 186.73c-1.36-1.22-.73-1.94 2.51-1.73s15.9 3.79 16.77-.73-17-9.31-22.47-9.25-14.18 2.07-14.18 2.07c3.31 1.63 10.95 6.36 17.37 9.64z"
          fill="#dd6a57"
        />
        <path
          d="M151.36 202.57c9.47 4.26 21 10.14 32.64 8.86a5.8 5.8 0 003.47-1.54c5.23-.48 6.57-3.09 6.57-3.09 4.73-1.94 5.74-4.8 5.74-4.8s6.13-.63 6.37-4.4c.27-4.23-8.39-2.51-13.74-4.49-12.23-4.52-28.37-14.36-31.77-16z"
          fill="#dd6a57"
        />
        <path
          d="M206.15 197.6c-.24 3.77-6.37 4.4-6.37 4.4-.13.32-1.3 2.95-5.73 4.77 0 0-1.34 2.61-6.57 3.09v.05a5.88 5.88 0 01-3.42 1.49c-10.41 1.14-20.72-3.43-29.56-7.45-1.65-.4-3.5-.86-5.48-1.39l8.92-26.47c1.1.3 2.16.59 3.15.88 1.75-.38 9-1.94 13.79-2 5.43-.06 23.36 4.72 22.47 9.25s-13.49.94-16.77.73c-2.92-.19-3.72.38-2.86 1.38a137.69 137.69 0 0014.75 6.73c5.29 2.03 13.95.31 13.68 4.54z"
          fill="#dbdbdb"
        />
        <path
          d="M176.26 197.39a83.69 83.69 0 0010.94 3.35 85.68 85.68 0 0012.48 1.16.1.1 0 010 .2 44.32 44.32 0 01-23.48-4.55c-.2-.08-.12-.22.06-.16zM172.46 204.38c3.6 1 9.93 2.8 21.58 2.29 0 0 .05.07 0 .07a39.75 39.75 0 01-21.64-2.19c-.21-.08-.18-.24.06-.17zM170.49 208.18c6.18 1.51 10.27 1.68 16.95 1.69v.07c-7.59.79-10.44.22-17-1.56-.26-.06-.2-.26.05-.2z"
          fill="#263238"
        />
        <path
          d="M105 152.16l23.31-5.88s-5.58-17.41-9.78-26-11.4-11.55-13.22 3c-1.57 12.31-.31 28.88-.31 28.88z"
          fill="#37474f"
        />
      </g>
    </svg>
  );
}

export default CharitySVG;
