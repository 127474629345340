const NavLinks = [
  { title: "Department Information", path: "/department/Degree_FY" },
  { title: "HOD Desk", path: "/department/Degree_FY/hoddesk" },
  { title: "Faculty Profile", path: "/department/Degree_FY/teaching-staff" },
  { title: "Technical Staff", path: "/department/Degree_FY/technical-staff" },
  { title: "Activities", path: "/department/Degree_FY/activities" },
  { title: "Laboratories", path: "/department/Degree_FY/labs" },
];

export default NavLinks;
