import React from "react";

function Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" {...props}>
      <defs>
        <clipPath id="freepik--clip-path--inject-31">
          <path
            fill="#92E3A9"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M241.19 292.45s-3.66-7-4.32-11.31-3.66-17-6.33-23-15.64-13.64-18-13.31-7.32 13-7 18.31 6.33 12.31 8 14.64 5.66 15 8.32 19 3 5.66 3 5.66z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-2--inject-31">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M240.53 288.13l-19.64 11.64s7.32 13.32 11.32 24.63 12 32.62 25.29 32.28 25.63-17.3 25.63-17.3l-15.31-44.6-13.65 18.64z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-3--inject-31">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M303.71 254.8s15.55 2.78 24.54 7.1 21.44 12.58 26.76 29.22 2.66 95.29 2.66 95.29h-82.53s4-34.39.33-50-7.68-15.75-9.86-27.2c-6.5-34.03 11.81-58.07 38.1-54.41z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-4--inject-31">
          <path
            fill="#92E3A9"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M259.5 332.72l-10.65-10.31s-.67-4-2-5a42.67 42.67 0 01-3.33-3s-8-16-11.31-20.64a12.9 12.9 0 00-11.32-5.65c-3 .33-16 1.33-17.3 4.32-.41.92 1.33 3.66 1.33 3.66s-3.33 1.67-2.66 4.66 2 3.33 2 3.33-4 3-2.66 5.33a5.21 5.21 0 003.33 2.66s-2.66 3 .33 4a61.81 61.81 0 006.32 1.67s3.66 9.31 7 9.65a60.54 60.54 0 017.32 1.33s6.66 13.64 12 20 15 7 15 7z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-5--inject-31">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M321.73 275.48s-5.32 11.65-6.32 21-13.64 45.09-13.64 45.09-25.63-7.15-32.95-9.15a64.88 64.88 0 01-14.22-6.15l-14.89 26.89s41.43 24.21 60.06 27.2 21.68 3.64 30.67-4.36 22.91-61.25 22.91-61.25"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-6--inject-31">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M247.71 223.25s-1.77 14.18 1.06 26.94 8.16 29.07 12.06 29.42 24.1-13.11 25.52-19.85 1.41-9.57 1.41-18.08a166 166 0 00-1.06-20.91c-.71-5.32-3.9-11-13.47-9.57s-21.98 5.32-25.52 12.05z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-7--inject-31">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M390 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69h0z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-8--inject-31">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M376 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69h0z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-9--inject-31">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M362 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69h0z"
          ></path>
        </clipPath>
      </defs>
      <g>
        <path
          fill="#92E3A9"
          d="M61.16 317.86s6.94 59.24 57.59 84.64 100.17.89 145.66-4.95c23.83-3.06 45.95 8.64 69.32 11.12 74.72 7.95 133.18-79.5 122.53-143.94-9.21-55.79-45.12-95.22-110.46-123.44S235.52 90.64 159.74 116.9 55.6 241.16 61.16 317.86z"
        ></path>
        <path
          fill="#fff"
          d="M61.16 317.86s6.94 59.24 57.59 84.64 100.17.89 145.66-4.95c23.83-3.06 45.95 8.64 69.32 11.12 74.72 7.95 133.18-79.5 122.53-143.94-9.21-55.79-45.12-95.22-110.46-123.44S235.52 90.64 159.74 116.9 55.6 241.16 61.16 317.86z"
          opacity="0.7"
        ></path>
      </g>
      <g fill="#c9c9c9">
        <path d="M134 207.57a3.87 3.87 0 10-3.87 3.88 3.87 3.87 0 003.87-3.88zM163.35 207.57a3.88 3.88 0 10-3.87 3.88 3.87 3.87 0 003.87-3.88zM148.08 224.8a3.88 3.88 0 10-3.87 3.88 3.87 3.87 0 003.87-3.88zM175.1 224.8a3.88 3.88 0 10-3.88 3.88 3.88 3.88 0 003.88-3.88zM175.1 251.43a3.88 3.88 0 10-3.88 3.87 3.88 3.88 0 003.88-3.87zM120.22 251.43a3.88 3.88 0 10-3.87 3.87 3.88 3.88 0 003.87-3.87zM199.77 224.8a3.88 3.88 0 10-3.88 3.88 3.88 3.88 0 003.88-3.88zM199.77 250.25a3.88 3.88 0 10-3.88 3.88 3.88 3.88 0 003.88-3.88zM199.77 291.08a3.88 3.88 0 10-3.88 3.87 3.88 3.88 0 003.88-3.87z"></path>
        <path d="M196.54 250.4L194.59 249.97 194.89 248.58 194.89 225.8 143.73 225.8 128 206.57 159.48 206.57 159.48 208.57 132.22 208.57 144.68 223.8 196.89 223.8 196.87 248.9 196.54 250.4z"></path>
        <path d="M197.68 292.37L93.73 292.37 93.73 290.37 195.68 290.37 195.68 252.43 170.22 252.43 170.22 224.8 172.22 224.8 172.22 250.43 197.68 250.43 197.68 292.37z"></path>
        <path d="M117 291.37L115 291.37 115 250.43 171.22 250.43 171.22 252.43 117 252.43 117 291.37z"></path>
        <path d="M303 294.33H458.33000000000004V303.65999999999997H303z"></path>
        <path d="M409.28 299.45H416.26V389.29999999999995H409.28z"></path>
        <path d="M83.89 294.33H239.22000000000003V303.65999999999997H83.89z"></path>
        <path d="M190.17 299.45H197.14999999999998V389.29999999999995H190.17z"></path>
        <path d="M98.32 299.45H105.3V389.29999999999995H98.32z"></path>
        <path d="M383.17 288.87L373 270.77v-12.44a1.72 1.72 0 00-.62-3.32h-10.64a1.72 1.72 0 00-.62 3.32v12L350.91 289a4.65 4.65 0 004.14 6.78h24a4.66 4.66 0 004.12-6.91zM332.42 288.87l-10.18-18.1v-12.44a1.72 1.72 0 00-.62-3.32H311a1.72 1.72 0 00-.63 3.32v12L300.15 289a4.65 4.65 0 004.14 6.78h24a4.66 4.66 0 004.13-6.91z"></path>
        <path d="M367.59 260.3h-.18a1 1 0 01-.84-1.2c1-5.86-2-13-7.62-17.85-5.29-4.59-11.66-6.12-17.48-4.22a11.38 11.38 0 00-6.14 4.81h.35c6.49-.82 9.3 1.5 10.51 3.59 1.68 2.91 1.16 7.2-1.28 10.42-2 2.66-4.8 3.84-7.49 3.17-3.72-.94-6.13-4.5-6.3-9.28a15.93 15.93 0 01.67-5c-11.68 3.19-13.06 12.23-13.12 12.65a1 1 0 01-2.05-.27c0-.12 1.68-11.55 16.08-14.74a13.72 13.72 0 018.13-7.26c6.52-2.13 13.62-.45 19.48 4.63 6.2 5.36 9.46 13.12 8.3 19.77a1 1 0 01-1.02.78zm-33.42-16.18a13.91 13.91 0 00-1 5.5c.1 3 1.4 6.49 4.73 7.33 2.66.67 4.61-1.45 5.32-2.4 1.65-2.18 2.57-5.67 1.14-8.13-1.28-2.22-4.21-3.11-8.45-2.57q-.91.15-1.74.27zM213.05 387.28a1.5 1.5 0 01-1.5-1.5V136.52a1.5 1.5 0 013 0v249.26a1.5 1.5 0 01-1.5 1.5z"></path>
        <path d="M226.43 194.89a6.43 6.43 0 01-6.42-6.41v-25.89h-6.11a1.5 1.5 0 010-3h7.61a1.5 1.5 0 011.5 1.5v27.39a3.42 3.42 0 006.83 0v-27.36a1.5 1.5 0 011.5-1.5h9.13a1.5 1.5 0 110 3h-7.63v25.86a6.42 6.42 0 01-6.41 6.41z"></path>
        <path d="M247.32 194.89a6.42 6.42 0 01-6.41-6.41v-25.89h-6.11a1.5 1.5 0 010-3h7.61a1.5 1.5 0 011.5 1.5v27.39a3.42 3.42 0 006.83 0v-27.36a1.5 1.5 0 011.5-1.5h9.13a1.5 1.5 0 010 3h-7.63v25.86a6.43 6.43 0 01-6.42 6.41z"></path>
        <path d="M268.22 194.89a6.42 6.42 0 01-6.41-6.41v-25.89h-6.11a1.5 1.5 0 010-3h7.61a1.5 1.5 0 011.5 1.5v27.39a3.42 3.42 0 006.83 0v-27.36a1.5 1.5 0 011.5-1.5h9.13a1.5 1.5 0 010 3h-7.63v25.86a6.43 6.43 0 01-6.42 6.41z"></path>
        <path d="M289.12 194.89a6.42 6.42 0 01-6.41-6.41v-25.89h-6.11a1.5 1.5 0 010-3h7.61a1.5 1.5 0 011.5 1.5v27.39a3.42 3.42 0 006.83 0v-27.36a1.5 1.5 0 011.5-1.5h39.71a1.5 1.5 0 010 3h-38.21v25.86a6.43 6.43 0 01-6.42 6.41z"></path>
        <path d="M330.17 175.34V156.9h.06a1.91 1.91 0 000-3.81h-12.09a1.91 1.91 0 000 3.81h.06v18.44a20.74 20.74 0 1012 0z"></path>
      </g>
      <g stroke="#263238" strokeLinecap="round" strokeLinejoin="round">
        <path
          fill="#fff"
          d="M124.31 362.38l-11.19 18a3.45 3.45 0 002.93 5.27h22.86a3.45 3.45 0 01-2.94-5.26l10.83-17.64z"
        ></path>
        <rect
          width="95.61"
          height="80.75"
          x="90.56"
          y="285.65"
          fill="#fff"
          rx="2.91"
        ></rect>
        <rect
          width="95.61"
          height="80.75"
          x="94.18"
          y="285.65"
          fill="#fff"
          rx="2.91"
        ></rect>
        <path
          fill="#263238"
          d="M186.58 285.65H97.39a3.21 3.21 0 00-3.21 3.21v63.07h95.61v-63.07a3.21 3.21 0 00-3.21-3.21z"
        ></path>
        <path fill="#fff" d="M98.2 288.86H185.78V347.51H98.2z"></path>
        <path
          fill="#92E3A9"
          d="M175.75 311.15L175.75 298.36 164.68 291.97 153.6 298.36 153.6 311.15 164.68 317.55 175.75 311.15z"
        ></path>
        <path
          fill="#92E3A9"
          d="M144.16 323.04L144.16 312.58 135.11 307.36 126.06 312.58 126.06 323.04 135.11 328.26 144.16 323.04z"
        ></path>
        <path
          fill="#92E3A9"
          d="M133.42 306.66L133.42 296.21 124.37 290.98 115.32 296.21 115.32 306.66 124.37 311.88 133.42 306.66z"
        ></path>
        <path
          fill="#92E3A9"
          d="M133.42 339.14L133.42 328.69 124.37 323.47 115.32 328.69 115.32 339.14 124.37 344.37 133.42 339.14z"
        ></path>
        <path
          fill="#92E3A9"
          d="M122.42 323.04L122.42 312.58 113.37 307.36 104.32 312.58 104.32 323.04 113.37 328.26 122.42 323.04z"
        ></path>
        <path fill="none" d="M155 324.19L174.39 324.19"></path>
        <path fill="none" d="M155 328.14L174.39 328.14"></path>
        <path fill="none" d="M155 332.08L174.39 332.08"></path>
      </g>
      <g>
        <path
          fill="#263238"
          d="M427.58 218.55l-49.5.41s-3.28-.41-4.09 2c0 0-8.6 21.68-9 29.46s1.64 6.54-1.63 16.77-12.69 34.37-18 54c-5.73 21.14-8.06 43.31-9 65.3h62.29c.07-21.08 1.45-42 8.9-62.85 5.39-15.12 11.59-31.08 16.17-46.9s5.38-32.25 8.37-48.43c2.04-10.98-4.51-9.76-4.51-9.76z"
        ></path>
        <path
          fill="#6e6e6e"
          d="M432.43 223.45c-.49-5.71-4.85-4.9-4.85-4.9l-49.5.41s-3.28-.41-4.09 2c0 0-8.6 21.68-9 29.46a37.89 37.89 0 00.18 6.16l1 1.61s1.64 14.32 21.28 15.14 30.68-8.19 34.77-16.78 5.73-22.5 9-30.68a24.94 24.94 0 011.21-2.42z"
        ></path>
        <path
          fill="#6e6e6e"
          d="M429.64 218.79l-.05.06a88.62 88.62 0 00-8.59 20.59c-3.32 11.95-1.33 15.27-4.65 35.85s-15.35 43.83-19.96 65.08c-2 9-2.74 27.5-2.94 46.18h5.19c.07-21.08 1.45-42 8.9-62.85 5.39-15.12 11.59-31.08 16.17-46.9s5.38-32.25 8.37-48.43c1.26-6.79-.72-8.93-2.44-9.58z"
        ></path>
        <path
          fill="#92E3A9"
          d="M241.19 292.45s-3.66-7-4.32-11.31-3.66-17-6.33-23-15.64-13.64-18-13.31-7.32 13-7 18.31 6.33 12.31 8 14.64 5.66 15 8.32 19 3 5.66 3 5.66z"
        ></path>
        <g clipPath="url(#freepik--clip-path--inject-31)">
          <path
            d="M228.11 285c.66-3-3.67-1-5.67-2.67s.33-2.33 1-4.66-7 2.33-8.33 2a3.89 3.89 0 01-.71-.32c1.93 4.17 5.2 14 7.49 17.43.93 1.4 1.57 2.5 2 3.35l7.2-1.8S227.44 288 228.11 285z"
            opacity="0.3"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M241.19 292.45s-3.66-7-4.32-11.31-3.66-17-6.33-23-15.64-13.64-18-13.31-7.32 13-7 18.31 6.33 12.31 8 14.64 5.66 15 8.32 19 3 5.66 3 5.66z"
        ></path>
        <path
          fill="#92E3A9"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M210.24 259.84s-10-2.66-12.64-2-1.34 4 2.33 5a16.09 16.09 0 015.51 2.5l3.33 5"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M212.57 240.54h8v32.73a3.22 3.22 0 01-3.22 3.22h-1.55a3.22 3.22 0 01-3.22-3.22v-32.73h-.01z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M211.07 239.2H222.04999999999998V242.2H211.07z"
        ></path>
        <path
          fill="#92E3A9"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M211.07 239.2s-4.49-1-4.49 3.33 3.33 5.33 6 2.33.99-5.99-1.51-5.66zM234.2 268.82s-2.66-12.31-4.65-17.3-7.66-9.65-9-11-2.66 4-1.66 5.65 3 5.66 3 5.66-3.33 14.31 1 17.64M211.24 253.18s-6.66-.66-7.32 1.67.66 3.66 2.66 3.66 7 1 7.32-1.33-.33-4.33-2.66-4z"
        ></path>
        <path
          fill="#92E3A9"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M211.91 247.52s-6.66-.66-7.33 1.67.67 3.66 2.67 3.66 7 1 7.32-1.33-.33-4.33-2.66-4z"
        ></path>
        <path
          fill="#fff"
          d="M240.53 288.13l-19.64 11.64s7.32 13.32 11.32 24.63 12 32.62 25.29 32.28 25.63-17.3 25.63-17.3l-15.31-44.6-13.65 18.64z"
        ></path>
        <g clipPath="url(#freepik--clip-path-2--inject-31)">
          <path
            fill="#92E3A9"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M240.53 288.13l-19.64 11.64s7.32 13.32 11.32 24.63 12 32.62 25.29 32.28 25.63-17.3 25.63-17.3l-15.31-44.6-13.65 18.64z"
          ></path>
          <path
            fill="#fff"
            d="M240.53 288.13l-19.64 11.64s7.32 13.32 11.32 24.63 12 32.62 25.29 32.28 25.63-17.3 25.63-17.3l-15.31-44.6-13.65 18.64z"
            opacity="0.5"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M240.53 288.13l-19.64 11.64s7.32 13.32 11.32 24.63 12 32.62 25.29 32.28 25.63-17.3 25.63-17.3l-15.31-44.6-13.65 18.64z"
        ></path>
        <path
          fill="#fff"
          d="M303.71 254.8s15.55 2.78 24.54 7.1 21.44 12.58 26.76 29.22 2.66 95.29 2.66 95.29h-82.53s4-34.39.33-50-7.68-15.75-9.86-27.2c-6.5-34.03 11.81-58.07 38.1-54.41z"
        ></path>
        <g clipPath="url(#freepik--clip-path-3--inject-31)">
          <path
            fill="#92E3A9"
            d="M329.28 387c4.43-11.67 11.83-39.3 11.83-39.3s-25.67-6.34-29.34-18 4.34-36.7 4.34-36.7-19.34 30-22 34.67S276.77 281 271.77 269a1 1 0 00-.07-.15c-6.56 9.78-9.24 23.88-6.09 40.33 2.18 11.45 6.2 11.56 9.86 27.2s-.33 50.59-.33 50.59z"
          ></path>
          <path
            fill="#fff"
            d="M329.28 387c4.43-11.67 11.83-39.3 11.83-39.3s-25.67-6.34-29.34-18 4.34-36.7 4.34-36.7-19.34 30-22 34.67S276.77 281 271.77 269a1 1 0 00-.07-.15c-6.56 9.78-9.24 23.88-6.09 40.33 2.18 11.45 6.2 11.56 9.86 27.2s-.33 50.59-.33 50.59z"
            opacity="0.5"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M303.71 254.8s15.55 2.78 24.54 7.1 21.44 12.58 26.76 29.22 2.66 95.29 2.66 95.29h-82.53s4-34.39.33-50-7.68-15.75-9.86-27.2c-6.5-34.03 11.81-58.07 38.1-54.41z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M269.33 296.62l3.33 2.21-2.59 5.55s3 10.35 6.28 16.63 6.65 11.83 6.65 11.83 10.72-11.83 15.52-19.22 9.25-16.26 9.25-16.26l-4.44-3.7 9.24-5.92s3.5-19.26 2-30.52c-5.91-1.54-10.85-2.42-10.85-2.42-15.41-2.15-26.22 3.62-32.6 11.09L269.7 270a57.81 57.81 0 00-1.48 14.79 114.43 114.43 0 001.11 11.83z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M273.59 272.52s-.36 17.72 1.41 26.94 8.51 23.39 8.51 23.39 11-21.62 15.6-33.67 4.6-34.38 4.6-34.38l-24.81-2.13z"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M276.94 293.55L281.91 301.41 279.43 312.58 283.51 322.86 287.7 312.58 286.05 301.83 291.01 291.48 283.15 282.8 276.94 293.55z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M283.09 291.64l12 8s8-18.25 9-28.17a58.56 58.56 0 00-.36-16.66L287.76 253l-14.17 19.49 1.41 26.97z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M267.21 273.23l15.88 18.41 10-34-5.32-15.95s-3.54 15.6-9.92 20.92a82.83 82.83 0 00-10.64 10.62zM235.06 342.83l-13.48-24v-16.47a2.27 2.27 0 00-.83-4.39h-14.08a2.27 2.27 0 00-.83 4.39v15.9h0l-13.51 24.67a6.16 6.16 0 005.49 9h31.82a6.17 6.17 0 005.42-9.1z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M227.49 334.28c-5.21 1.63-11.64 2.55-16.89-.07a27.19 27.19 0 00-9.1-3l-5.65 10.31a5.15 5.15 0 004.58 7.49H227a5.15 5.15 0 004.54-7.57z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M235.06 342.83l-13.48-24v-16.47a2.27 2.27 0 00-.83-4.39h-14.08a2.27 2.27 0 00-.83 4.39v15.9h0l-13.51 24.67a6.16 6.16 0 005.49 9h31.82a6.17 6.17 0 005.42-9.1z"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M227.49 334.28c-5.21 1.63-11.64 2.55-16.89-.07a27.19 27.19 0 00-9.1-3l-5.65 10.31a5.15 5.15 0 004.58 7.49H227a5.15 5.15 0 004.54-7.57z"
        ></path>
        <path
          fill="#92E3A9"
          d="M259.5 332.72l-10.65-10.31s-.67-4-2-5a42.67 42.67 0 01-3.33-3s-8-16-11.31-20.64a12.9 12.9 0 00-11.32-5.65c-3 .33-16 1.33-17.3 4.32-.41.92 1.33 3.66 1.33 3.66s-3.33 1.67-2.66 4.66 2 3.33 2 3.33-4 3-2.66 5.33a5.21 5.21 0 003.33 2.66s-2.66 3 .33 4a61.81 61.81 0 006.32 1.67s3.66 9.31 7 9.65a60.54 60.54 0 017.32 1.33s6.66 13.64 12 20 15 7 15 7z"
        ></path>
        <g clipPath="url(#freepik--clip-path-4--inject-31)">
          <path
            d="M234.77 335.33c-1 0-.66-3 .34-5.33s-2.34 0-3.34-.67 0 0 0-3.66-5.33-1-9.33-1.67-7-8-7-8l-10.19.08a61.81 61.81 0 006.32 1.67s3.66 9.31 7 9.65a60.54 60.54 0 017.32 1.33s6.66 13.64 12 20a19.93 19.93 0 009.29 5.8c1.18-2.45 2-4.5 2-4.5s-13.41-14.7-14.41-14.7z"
            opacity="0.3"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M259.5 332.72l-10.65-10.31s-.67-4-2-5a42.67 42.67 0 01-3.33-3s-8-16-11.31-20.64a12.9 12.9 0 00-11.32-5.65c-3 .33-16 1.33-17.3 4.32-.41.92 1.33 3.66 1.33 3.66s-3.33 1.67-2.66 4.66 2 3.33 2 3.33-4 3-2.66 5.33a5.21 5.21 0 003.33 2.66s-2.66 3 .33 4a61.81 61.81 0 006.32 1.67s3.66 9.31 7 9.65a60.54 60.54 0 017.32 1.33s6.66 13.64 12 20 15 7 15 7z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M204.92 296.11L216.9 295.45"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M204.25 304.1L213.9 303.44"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M204.92 312.09L212.24 311.42"
        ></path>
        <path
          fill="#fff"
          d="M321.73 275.48s-5.32 11.65-6.32 21-13.64 45.09-13.64 45.09-25.63-7.15-32.95-9.15a64.88 64.88 0 01-14.22-6.15l-14.89 26.89s41.43 24.21 60.06 27.2 21.68 3.64 30.67-4.36 22.91-61.25 22.91-61.25"
        ></path>
        <g clipPath="url(#freepik--clip-path-5--inject-31)">
          <path
            fill="#92E3A9"
            d="M323.77 368.67c-6.33 2-5-11-10-13s3.67 14-1.33 16.33-15.33-2.67-26-6.33c-8.2-2.82-32.32-14.09-43.06-19.17l-3.67 6.63s41.43 24.21 60.06 27.2 21.68 3.67 30.67-4.33c5.61-5 13.14-27.62 18-43.92-4.05 1.97-19.06 34.82-24.67 36.59z"
          ></path>
          <path
            fill="#fff"
            d="M323.77 368.67c-6.33 2-5-11-10-13s3.67 14-1.33 16.33-15.33-2.67-26-6.33c-8.2-2.82-32.32-14.09-43.06-19.17l-3.67 6.63s41.43 24.21 60.06 27.2 21.68 3.67 30.67-4.33c5.61-5 13.14-27.62 18-43.92-4.05 1.97-19.06 34.82-24.67 36.59z"
            opacity="0.5"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M321.73 275.48s-5.32 11.65-6.32 21-13.64 45.09-13.64 45.09-25.63-7.15-32.95-9.15a64.88 64.88 0 01-14.22-6.15l-14.89 26.89s41.43 24.21 60.06 27.2 21.68 3.64 30.67-4.36 22.91-61.25 22.91-61.25"
        ></path>
        <path
          fill="#fff"
          d="M247.71 223.25s-1.77 14.18 1.06 26.94 8.16 29.07 12.06 29.42 24.1-13.11 25.52-19.85 1.41-9.57 1.41-18.08a166 166 0 00-1.06-20.91c-.71-5.32-3.9-11-13.47-9.57s-21.98 5.32-25.52 12.05z"
        ></path>
        <g clipPath="url(#freepik--clip-path-6--inject-31)">
          <path
            fill="#92E3A9"
            d="M273.23 211.2c-9.57 1.42-22 5.32-25.52 12.05 0 0-1.77 14.18 1.06 26.94 2.56 11.49 7.12 25.84 10.85 28.85-1.49-7.62-4.6-22.71-5.85-22.71-1.66 0-3-2-3.93-2.95s4.6-8 4.6-8l-.67-18.38 24.78-8.71-.65-7.19a16.87 16.87 0 00-4.67.1z"
          ></path>
          <path
            fill="#fff"
            d="M273.23 211.2c-9.57 1.42-22 5.32-25.52 12.05 0 0-1.77 14.18 1.06 26.94 2.56 11.49 7.12 25.84 10.85 28.85-1.49-7.62-4.6-22.71-5.85-22.71-1.66 0-3-2-3.93-2.95s4.6-8 4.6-8l-.67-18.38 24.78-8.71-.65-7.19a16.87 16.87 0 00-4.67.1z"
            opacity="0.5"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M247.71 223.25s-1.77 14.18 1.06 26.94 8.16 29.07 12.06 29.42 24.1-13.11 25.52-19.85 1.41-9.57 1.41-18.08a166 166 0 00-1.06-20.91c-.71-5.32-3.9-11-13.47-9.57s-21.98 5.32-25.52 12.05z"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M267.91 201.28c-11.69 1.41-26.23 10.28-32.61 16.3s11.35 2.13 11.35 2.13l-5 8.86s7.45-2.84 13.83-5.67 23-4.61 23-4.61l-7.09 7.8 10.63 2.13s-1.64 6.59-.1 8.4c-.16 7.48-.58 17.5-1.67 19.24-1.77 2.84-5 2.84-17 1.77-10-.88-11.7 1.64-12 2.54 2.87 10 6.59 19.18 9.51 19.44 3.89.36 24.1-13.11 25.52-19.85s1.41-9.57 1.41-18.08c0-2.81 0-5.44-.12-7.92.48-.54.88-.94 1.19-.94 1.06 0 3.19 1.42 3.19 1.42s1.77-12.76 1.77-20.2-14.11-14.18-25.81-12.76z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M255.86 264.37L262.95 263.31"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M253.38 246.65s-4.61 7.44-2.83 8.86 7.79 1.06 7.79 1.06"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M245.94 239.2s-2.84 3.9-2.84 6.38 6.74 7.8 6.74 7.8 2.12-8.15 5-6.38 3.54 5 6.73 5 13.47-2.48 15.25-5.67 2.48-9.21 2.48-9.21l10.63-2.48-.35-3.19z"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M289.54 231.41l-43.25 6s-2.48 1.77-.35 1.77 43.6-7.77 43.6-7.77z"
        ></path>
        <ellipse
          cx="261.88"
          cy="243.44"
          fill="#263238"
          rx="0.95"
          ry="2.18"
        ></ellipse>
        <ellipse
          cx="251.54"
          cy="244.16"
          fill="#263238"
          rx="0.95"
          ry="2.18"
        ></ellipse>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M259 233.39s1.74-3.64 6.84-2.91M252.92 234.41a4.69 4.69 0 00-4.8.15"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M284.93 239.91s0-12.76 5.31-11 2.49 13.83 1.42 16.31-5.67 2.13-5.67 2.13"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M291.73 233.43a1.93 1.93 0 00-2.9-.61c-1.9 1.25-.64 5.68-.64 5.68s2.7-.51 2.36 1.52-1.55 1.68-1.55 1.68"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M440.65 386.58L70.69 386.58"
      ></path>
      <g>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M355.27 358.57H416.25V396.51H355.27z"
        ></path>
        <path
          fill="#fff"
          d="M390 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69z"
        ></path>
        <g clipPath="url(#freepik--clip-path-7--inject-31)">
          <path
            fill="#92E3A9"
            d="M393.52 388.89h1.7a3.51 3.51 0 003.5-3.51v-19.77H390v19.77a3.51 3.51 0 003.52 3.51z"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M390 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69h0z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M388.38 348.23H400.36V351.5H388.38z"
        ></path>
        <path
          fill="#fff"
          d="M376 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69z"
        ></path>
        <g clipPath="url(#freepik--clip-path-8--inject-31)">
          <path
            fill="#92E3A9"
            d="M384.72 385.38v-19.77H376v19.77a3.51 3.51 0 003.51 3.51h1.69a3.51 3.51 0 003.52-3.51z"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M376 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69h0z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M374.38 348.23H386.36V351.5H374.38z"
        ></path>
        <path
          fill="#fff"
          d="M362 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69z"
        ></path>
        <g clipPath="url(#freepik--clip-path-9--inject-31)">
          <path
            fill="#92E3A9"
            d="M362 365.61v19.77a3.51 3.51 0 003.51 3.51h1.69a3.51 3.51 0 003.51-3.51v-19.77z"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M362 349.69h8.71v35.69a3.51 3.51 0 01-3.51 3.51h-1.69a3.51 3.51 0 01-3.51-3.51v-35.69h0z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M360.37 348.23H372.35V351.5H360.37z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M355.27 357.22H416.25V359.93H355.27z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M355.27 389.29H416.25V396.52000000000004H355.27z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M353.01 344.12H356.62V397.42H353.01z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M413.09 344.12H416.7V397.42H413.09z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M206 387.16L191.31 361v-18a2.48 2.48 0 00-.91-4.79h-15.35a2.48 2.48 0 00-.91 4.79v17.34h0l-14.73 26.9a6.73 6.73 0 006 9.79h34.69a6.73 6.73 0 005.9-9.87z"
        ></path>
        <path
          fill="#92E3A9"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M195.36 374h-25.44l-6.61 12a5.59 5.59 0 005 8.14h28.87a5.59 5.59 0 004.93-8.23z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M77.37 382.59a2 2 0 01-2-2v-148a2 2 0 014 0v148a2 2 0 01-2 2z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M142 242.48H73.4a2 2 0 010-4H142a2 2 0 010 4z"
        ></path>
        <rect
          width="70.85"
          height="15.3"
          x="67.16"
          y="380.02"
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          rx="2.75"
        ></rect>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M74.74 237.61H79.8V242.95000000000002H74.74z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M153.16 250.47v-21h-11v21a20.24 20.24 0 1011 0z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M153.73 231.15h-12.09a1.41 1.41 0 01-1.4-1.41h0a1.4 1.4 0 011.4-1.4h12.09a1.4 1.4 0 011.4 1.4h0a1.41 1.41 0 01-1.4 1.41z"
        ></path>
        <path
          fill="#92E3A9"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M132.38 261.22a17.43 17.43 0 1030.3 0z"
        ></path>
        <circle
          cx="146.25"
          cy="269.75"
          r="2.75"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></circle>
        <circle
          cx="153.25"
          cy="268.25"
          r="1.25"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></circle>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M142.67 275.59a1.59 1.59 0 11-1.58-1.59 1.58 1.58 0 011.58 1.59z"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M139.1 235.64H156.24V245.76H139.1z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
